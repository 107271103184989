export default {
  SET_LOADING: "CUSTOMERS_SET_LOADING",
  CLEAR_CUSTOMER_STORE: "CLEAR_CUSTOMER_STORE",
  GET_CUSTOMER_REQUEST: "GET_CUSTOMER_REQUEST",
  GET_CUSTOMER_SUCCESS: "GET_CUSTOMER_SUCCESS",
  GET_CUSTOMER_FAILURE: "GET_CUSTOMER_FAILURE",
  ADD_CUSTOMER_REQUEST: "ADD_CUSTOMER_REQUEST",
  ADD_CUSTOMER_SUCCESS: "ADD_CUSTOMER_SUCCESS",
  ADD_CUSTOMER_FAILURE: "ADD_CUSTOMER_FAILURE",
  UPDATE_CUSTOMER_REQUEST: "UPDATE_CUSTOMER_REQUEST",
  UPDATE_CUSTOMER_SUCCESS: "UPDATE_CUSTOMER_SUCCESS",
  UPDATE_CUSTOMER_FAILURE: "UPDATE_CUSTOMER_FAILURE",
  GET_CUSTOMER_USERS_REQUEST: "GET_CUSTOMER_USERS_REQUEST",
  GET_CUSTOMER_USERS_SUCCESS: "GET_CUSTOMER_USERS_SUCCESS",
  GET_CUSTOMER_USERS_FAILURE: "GET_CUSTOMER_USERS_FAILURE",
  GET_CUSTOMERS_REQUEST: "GET_CUSTOMERS_REQUEST",
  GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_SUCCESS",
  GET_CUSTOMERS_FAILURE: "GET_CUSTOMERS_FAILURE",
  INVITE_CUSTOMER_USER_REQUEST: "INVITE_CUSTOMER_USER_REQUEST",
  INVITE_CUSTOMER_USER_SUCCESS: "INVITE_CUSTOMER_USER_SUCCESS",
  INVITE_CUSTOMER_USER_FAILURE: "INVITE_CUSTOMER_USER_FAILURE",
  REMOVE_CUSTOMER_USER_REQUEST: "REMOVE_CUSTOMER_USER_REQUEST",
  REMOVE_CUSTOMER_USER_SUCCESS: "REMOVE_CUSTOMER_USER_SUCCESS",
  REMOVE_CUSTOMER_USER_FAILURE: "REMOVE_CUSTOMER_USER_FAILURE",
  REMOVE_CUSTOMER_REQUEST: "REMOVE_CUSTOMER_REQUEST",
  REMOVE_CUSTOMER_SUCCESS: "REMOVE_CUSTOMER_SUCCESS",
  REMOVE_CUSTOMER_FAILURE: "REMOVE_CUSTOMER_FAILURE",
  GET_CREDIT_CARD_REQUEST: "GET_CREDIT_CARD_REQUEST",
  GET_CREDIT_CARD_SUCCESS: "GET_CREDIT_CARD_SUCCESS",
  GET_CREDIT_CARD_FAILURE: "GET_CREDIT_CARD_FAILURE",
  GET_CREDIT_CARDS_REQUEST: "GET_CREDIT_CARDS_REQUEST",
  GET_CREDIT_CARDS_SUCCESS: "GET_CREDIT_CARDS_SUCCESS",
  GET_CREDIT_CARDS_FAILURE: "GET_CREDIT_CARDS_FAILURE",
  ADD_CREDIT_CARD_REQUEST: "ADD_CREDIT_CARD_REQUEST",
  ADD_CREDIT_CARD_SUCCESS: "ADD_CREDIT_CARD_SUCCESS",
  ADD_CREDIT_CARD_FAILURE: "ADD_CREDIT_CARD_FAILURE",
  UPDATE_CREDIT_CARD_REQUEST: "UPDATE_CREDIT_CARD_REQUEST",
  UPDATE_CREDIT_CARD_SUCCESS: "UPDATE_CREDIT_CARD_SUCCESS",
  UPDATE_CREDIT_CARD_FAILURE: "UPDATE_CREDIT_CARD_FAILURE",
  REMOVE_CREDIT_CARD_REQUEST: "REMOVE_CREDIT_CARD_REQUEST",
  REMOVE_CREDIT_CARD_SUCCESS: "REMOVE_CREDIT_CARD_SUCCESS",
  REMOVE_CREDIT_CARD_FAILURE: "REMOVE_CREDIT_CARD_FAILURE",
};
