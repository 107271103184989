import Joi from "@hapi/joi";

const BillingEntitySchema = Joi.object({
  name: Joi.any()
    .when("$edit", {
      is: Joi.boolean().valid(true).required(),
      then: Joi.string(),
      otherwise: Joi.string().required(),
    })
    .messages({
      "string.base": `Billing Entity Name should be a text string.`,
      "string.empty": `Billing Entity Name cannot be an empty field`,
      "any.required": `Billing Entity Name is a required field`,
    }),

  email: Joi.any()
    .when("$edit", {
      is: Joi.boolean().valid(true).required(),
      then: Joi.string(),
      otherwise: Joi.string().required(),
    })
    .messages({
      "string.base": `Email should be a text string.`,
      "string.empty": `Email cannot be an empty field`,
      "any.required": `Email is a required field`,
    }),

  phone: Joi.string()
    .replace(/\D/g, "")
    .min(10)
    .max(11)
    .when("$edit", {
      is: Joi.boolean().valid(true).required(),
      then: Joi.any(),
      otherwise: Joi.required(),
    })
    .messages({
      "string.base": `Phone Number should be a text string.`,
      "string.empty": `Phone Number cannot be an empty field`,
      "string.min": `Please enter a valid Phone Number.`,
      "string.max": `Please enter a valid Phone Number.`,
      "any.required": `Phone Number is a required field`,
    }),
  address: Joi.string().allow("").messages({
    "string.base": `Address should be a text string.`,
    "string.empty": `Address cannot be an empty field`,
    "any.required": `Address is a required field`,
  }),
  city: Joi.string().allow("", null).messages({
    "string.base": `City should be a text string.`,
    "string.empty": `City cannot be an empty field`,
    "any.required": `City is a required field`,
  }),
  region: Joi.string().allow("", null).messages({
    "string.base": `Postal Code should be a text string.`,
    "string.empty": `Postal Code cannot be an empty field`,
    "any.required": `Postal Code is a required field`,
  }),
  country: Joi.string().allow("", null).messages({
    "string.base": `Country should be a text string.`,
    "string.empty": `Country cannot be an empty field`,
    "any.required": `Country is a required field`,
  }),
  postal_code: Joi.string().allow("", null).messages({
    "string.base": `Postal Code should be a text string.`,
    "string.empty": `Postal Code cannot be an empty field`,
    "object.regex": `Please enter a valid Postal Code.`,
    "any.required": `Postal Code is a required field`,
  }),
});

export default BillingEntitySchema;
