import Joi from "@hapi/joi";

const SiteSchema = Joi.object({
  id: Joi.string().messages({
    "string.base": `Site Id should be a text string.`,
  }),
  billing_entity_id: Joi.any().when("$edit", {
    is: Joi.boolean().valid(true).required(),
    then: Joi.string(),
    otherwise: Joi.string().required().messages({
      "string.base": `Billing Entity Id should be a text string.`,
      "string.empty": `Billing Entity Id cannot be an empty field`,
      "boolean.required": "$edit flag must be set in validation.",
      "any.required": `Billing Entity Id is a required field`,
      "any.unknown": "Billing Entity Id cannot be edited.",
    }),
  }),
  plan_id: Joi.any().when("$edit", {
    is: Joi.boolean().valid(true).required(),
    then: Joi.string(),
    otherwise: Joi.string().required().messages({
      "string.base": `Plan Id should be a text string.`,
      "string.empty": `You must select a plan to continue.`,
      "boolean.required": "$edit flag must be set in validation.",
      "any.required": `You must select a plan to continue.`,
    }),
  }),
  name: Joi.any()
    .when("$edit", {
      is: Joi.boolean().valid(true).required(),
      then: Joi.string(),
      otherwise: Joi.string().required(),
    })
    .messages({
      "string.base": `Site Name should be a text string.`,
      "string.empty": `Site Name cannot be an empty field`,
      "boolean.required": "$edit flag must be set in validation.",
      "any.required": `Site Name is a required field`,
    }),
  description: Joi.any()
    .when("$edit", {
      is: Joi.boolean().valid(true).required(),
      then: Joi.string().allow(""),
      otherwise: Joi.string().allow("").required(),
    })
    .messages({
      "string.base": `Site Description should be a text string.`,
      "boolean.required": "$edit flag must be set in validation.",
      "any.required": `Site Description is a required field`,
    }),
  domain: Joi.string().pattern(/^https?:\/\//).allow("").messages({

  }),
  subdomain: Joi.string().min(3).allow("").messages({

  }),
  address: Joi.string().allow("").messages({
    "string.base": `Address should be a text string.`,
    "string.empty": `Address cannot be an empty field`,
    "any.required": `Address is a required field`,
  }),
  city: Joi.string().allow("").messages({
    "string.base": `City should be a text string.`,
    "string.empty": `City cannot be an empty field`,
    "any.required": `City is a required field`,
  }),
  region: Joi.string().allow("").messages({
    "string.base": `Postal Code should be a text string.`,
    "string.empty": `Postal Code cannot be an empty field`,
    "any.required": `Postal Code is a required field`,
  }),
  country: Joi.string().allow("").messages({
    "string.base": `Country should be a text string.`,
    "string.empty": `Country cannot be an empty field`,
    "any.required": `Country is a required field`,
  }),
  postal_code: Joi.string().allow("").messages({
    "string.base": `Postal Code should be a text string.`,
    "string.empty": `Postal Code cannot be an empty field`,
    "object.regex": `Please enter a valid Postal Code.`,
    "any.required": `Postal Code is a required field`,
  }),
  code: Joi.string().allow("").messages({
    "string.base": `Code should be a text string.`,
  }),
  install_preference: Joi.object({
    unit: Joi.string().allow(""),
    operation_type: Joi.string().allow(""),
  }),
  // "install_preference.unit": Joi.string().allow(""),
  // "install_preference.operation_type": Joi.string().allow(""),
});

export default SiteSchema;
