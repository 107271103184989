import { Button, Card, Col, Row, Steps } from "antd";

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { ProfileForm, LoadingCard } from "..";
import { Form, message } from 'antd';
import { loadUser, updateUser } from "../../stores/users";
import history from "../../utils/history";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import HerdRXLogo from '../../assets/herdrx-logo-transparent.svg';

import "../../components/PlanCard/pricing-plan.css";
import { isClinic, isSite, getAppName, getTitle } from '../../config/app'

//import { faClipboardPer }

const { Step } = Steps;

const WelcomeWizard = ({
  loadUser,
  updateUser,
  profile,
  loading
}) => {
  let queryStep = Number(useParams().step) || 1;

  const [step, setStep] = useState(1);

  const [hovered, setHovered] = useState([false, false, false]);

  if (step !== queryStep) {
    setStep(queryStep);
  }

  const [form] = Form.useForm();
  const [isValidForm, setValidForm] = useState(true);

  useEffect(() => {
    if (profile === null) loadUser();

    if (profile) {
      form.setFieldsValue({
        first_name: profile.first_name,
        last_name: profile.last_name,
        email: profile.email,
        country: profile.country,
        region: profile.region,
        city: profile.city
      });
    }
  }, [profile, form, loadUser]);

  const next = () => {
    let nextStep = Number(step) + 1;
    history.push(`/welcome/${nextStep}`);
    //setStep(nextStep);   
  };
  const back = () => {
    let prevStep = Number(step) - 1;
    history.push(`/welcome/${prevStep}`);
    //setStep(nextStep);   
  };



  const step1Display = () => {
    return (<>
      <Row className="mb-10 shadow-ant  rounded-lg mb-10 z-10 ">
        <Col span={16} className="flex content-center">
          <div className="flex-1 p-10">
            <h2 className="text-2xl">What is HerdRX?</h2>
            <p className="text-lg">HerdRX is a full featured veterinary application for medical record keeping, and dispensing. It provides a client and vet database, with individual site records. Medical records are emailed to HerdRX and captured as records, with attachments or created within the software. Prescriptions and signing requests are recorded for compliance.</p>
          </div>
        </Col>
        <Col span={8} className="p-5 flex">
          <img alt="What is HerdRX?" className="mx-auto" src={HerdRXLogo} />
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={24} className="relative">
          {/* <i className="icon icon-arrow_back_ios absolute left-0 top-1/2 ml-5 z-10 cursor-pointer" onClick={() =>{carousel.next()}}></i>
                    <i className="icon icon-arrow_forward_ios absolute right-0 top-1/2 !mr-5 z-10 cursor-pointer" onClick={()=>{carousel.prev()}}></i> */}
          {/* <Carousel ref={node=>(carousel = node)} effect="fade" autoplay="true" className="absolute border border-gray-100 rounded-lg"> */}

          {/* <div>
                            <Row>
                                <Col span={16} className="p-10 text-center flex content-center">
                                    <span className="flex-1">
                                        <b className="text-lg">Animal Health Protocols</b>
                                        <p>Create animal health events and associated treatment protocols. Build client specific protocols based on protocol templates. Calculate anticipate drug requirements based on client animal inventory and event incidence rates. Generate client Animal Health Protocol booklets.</p>
                                    </span>
                                </Col>
                                <Col span={8}></Col>
                            </Row>
                        </div> */}
          <div>
            <Row className="shadow-ant  rounded-lg mb-10 transform z-10 ">
              <Col span={8} className="p-10 flex">
                <FontAwesomeIcon icon="fa-solid fa-clipboard-prescription" className="text-9xl mx-auto" />
              </Col>
              <Col span={16} className="p-10 flex content-center">
                <span className="flex-1">
                  <b className="!text-2xl">Prescription</b>
                  <p className="!text-lg">Create, digitally sign prescriptions, and attach them to medical records. Veterinary Technicians and administrative staff can handle data entry for prescriptions, leaving the final approval for the veterinarian. Send a notification to a veterinarian to sign and approve by phone. Instant signatures keep you compliant and keep clients moving.</p>
                </span>
              </Col>

            </Row>
          </div>
          <div>
            <Row className="shadow-ant  rounded-lg  mb-10  ">
              <Col span={16} className="p-10 flex">
                <span className="flex-1">
                  <b className="text-2xl">Medical Records</b>
                  <p className="text-lg">Note based medical record keeping system that allows for file attachments like lab reports and digital images. Compliant medical record process - strikethrough of text to show original record if changes are made. Records can be emailed to HerdRx and saved into the client file.</p>
                </span>
              </Col>
              <Col span={8} className="p-10 flex">
                <FontAwesomeIcon icon="fa-solid fa-book-medical" className="text-9xl mx-auto" />
              </Col>
            </Row>
          </div>
          <div>
            <Row className="shadow-ant  rounded-lg  mb-10 ">
              <Col span={8} className="p-10 flex">
                <FontAwesomeIcon icon="fa-sitemap fa-solid" className="text-9xl mx-auto" />
                {/* <img src="https://www.vetonfarm.com/images/screenshots/crm.jpg" /> */}
              </Col>
              <Col span={16} className="p-10 flex">
                <span className="flex-1">
                  <b className="text-2xl">Customer CRM</b>
                  <p className="text-lg">Track your clients correspondence, look up medical records, prescriptions, prescription drug balances, and dispensing events. Create organized notes in your CRM database.</p>
                </span>
              </Col>

            </Row>
          </div>
          <div>
            <Row className="shadow-ant  rounded-lg  mb-10 ">
              <Col span={16} className="p-10 flex">
                <span className="flex-1">
                  <b className="text-2xl">Dispensing</b>
                  <p className="text-lg">Every time a prescription is filled, simply record the dispensing event in HerdRX, alongside your current point of sale. You don’t need to change anything in your accounting or business workflow. Easily look up prescription balances and handle unit conversions. Integration with DYMO printers allows for easy label generation at time of dispensing event.</p>
                </span>
              </Col>
              <Col span={8} className="p-10 flex">
                <FontAwesomeIcon icon="fa-solid fa-capsules" className="text-9xl mx-auto" />
              </Col>
            </Row>
          </div>
          {/* </Carousel> */}
        </Col>
      </Row>
      <Row className="mt-10">
        <Col span={24}>
          <button className="bg-primary text-white !text-2xl p-5 h-auto rounded w-full mx-auto mb-10" onClick={() => next()}>Get Started!</button>
        </Col>
      </Row>
    </>
    );
  };

  const step2Display = () => {
    const onFormValuesChange = () => {
      const formHasErrors = !!form.getFieldsError().filter(({ errors }) => errors.length).length;
      if (!formHasErrors) {
        return setValidForm(true);
      }
      setValidForm(false);
    }

    const onSubmit = (values) => {
      updateUser(values);
      next();
      message.success('User update successfully')
    };

    if (!profile)
      return <LoadingCard />;
    return (
      <div>
        <p className="text-base">Lets make sure we have all your information up to date. Please fill out the form below.</p>
        <Form
          onFinish={onSubmit}
          onFieldsChange={onFormValuesChange}
          layout='vertical'
          form={form}
          className='profileForm'
        >
          <ProfileForm form={form} profile={profile} setValidForm={setValidForm} updatePassword={false} />

          <Button
            className='col-sm'
            type='primary'
            disabled={!isValidForm}
            loading={loading}
            htmlType="submit"
          >
            Continue & Update Account
          </Button>
          <Button type="primary" className="ml-3" onClick={back}>Back</Button>
        </Form>
      </div>
    );
  };

  const step3Display = () => {
    const showSite = isSite()
    const showClinic = isClinic()
    return <div>
      <p className="text-base">
        This is a brand-new account so let’s get you started on the right path. Are you a clinic manager?
        Or, perhaps you've been invited to an account already.
        This will provide you with the information to make the most out of Vet on Farm:
      </p>
      <div className="pricing-plan mt-20">
        <Row gutter={24}>
          {showSite ? <>
            <Col span={8} className="text-center">
              <Card className={`plan-card transform ${hovered[1] ? 'scale-110' : ''}`}
                onMouseEnter={() => { setHovered(vals => { let temp = [...vals]; temp[1] = true; return temp; }) }}
                onMouseLeave={() => { setHovered(vals => { let temp = [...vals]; temp[1] = false; return temp; }) }}
              >
                <div className="card-body-h">
                  <h2 className="card-title">Operation Site Owner or Manager</h2>
                  <p className="text-gray-400">  I would like my own instance of VetOnFarm: Chuteside to manage animal records at my operation site.
                    <span className="!text-sm mt-2 block"><a href="http://vetonfarm.com">Tell me more about VetOnFarm: Chuteside</a></span>
                  </p>
                  <div className="flex-container bottom-button">
                    <Button
                      onClick={() => { history.push(`/site/new`) }}
                      type="primary"
                      size="large"
                      style={{ width: '75%' }}
                      block
                    >
                      Create Site
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
          </> : null}

          {showClinic ? <>
            <Col span={8} className="text-center">

              <Card className={`plan-card z-10 transform  ${hovered[0] ? 'scale-110' : ''}`}
                onMouseEnter={() => { setHovered(vals => { let temp = [...vals]; temp[0] = true; return temp; }) }}
                onMouseLeave={() => { setHovered(vals => { let temp = [...vals]; temp[0] = false; return temp; }) }}
              >
                <div className="card-body-h">
                  <h2 className="card-title">Clinic Owner/Manager</h2>
                  <p className="text-gray-400"> I would like my own instance of VetOnFarm: HerdRx to manage dispensing at my clinic.
                    <span className="!text-sm mt-2 block"><a href="http://vetonfarm.com">Tell me more about VetOnFarm: HerdRx</a></span>

                  </p>
                  <div className="flex-container bottom-button">
                    <Button
                      onClick={() => {
                        updateUser({ onboarded: 1 });
                        history.push(`/clinic/new`);
                      }}
                      type="primary"
                      size="large"
                      style={{ width: '75%' }}
                      block
                    >
                      Start Clinic
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
          </> : null}


          <Col span={8} className="text-center">
            <Card className={`plan-card transform ${hovered[2] ? 'scale-110' : ''}`}
              onMouseEnter={() => { setHovered(vals => { let temp = [...vals]; temp[2] = true; return temp; }) }}
              onMouseLeave={() => { setHovered(vals => { let temp = [...vals]; temp[2] = false; return temp; }) }}
            >
              <div className="card-body-h">
                <h2 className="card-title">Invited App User<br />&nbsp;</h2>
                <p className="text-gray-400">  I have been invited to, and would like to log into, an instance of  VetOnFarm: {getAppName()}.</p>
                <div className="flex-container bottom-button">
                  <Button
                    onClick={() => { updateUser({ onboarded: 1 }); history.push(`/invites`) }}
                    type="primary"
                    size="large"
                    style={{ width: '75%' }}
                    block
                  >
                    Check for Invites
                  </Button>
                </div>
              </div>
            </Card>

          </Col>
        </Row>
      </div>
      <Button type="primary" onClick={back}>Back</Button>
    </div>
  };

  const route = () => {
    switch (step) {
      default:
      case 1: return step1Display();
      case 2: return step2Display();
      case 3: return step3Display();
    }
  }

  let retRoute = route();

  const onChange = current => {
    history.push(`/welcome/${current + 1}`);
  };

  return (
    <div>
      <div className="mb-5">
        <Steps type="navigation" current={step - 1} onChange={onChange}>
          <Step title={`Welcome to ${getTitle()}`} description="" />
          <Step title="Update your account" description="" />
          <Step title="Get started with Vet on Farm" description="" />
        </Steps>
      </div>
      {retRoute}
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.users.profile,
  loading: state.users.loading
});

const mapDispatchToProps = {
  loadUser,
  updateUser
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeWizard);