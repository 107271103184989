export default {
  SET_PLAN: "SET_PLAN",
  CLEAR_SUBSCRIPTION_STORE: "CLEAR_SUBSCRIPTION_STORE",
  ADD_SUBSCRIPTION_ITEM_REQUEST: "ADD_SUBSCRIPTION_ITEM_REQUEST",
  ADD_SUBSCRIPTION_ITEM_SUCCESS: "ADD_SUBSCRIPTION_ITEM_SUCCESS",
  ADD_SUBSCRIPTION_ITEM_FAILURE: "ADD_SUBSCRIPTION_ITEM_FAILURE",
  GET_PLANS_REQUEST: "GET_PLANS_REQUEST",
  GET_PLANS_SUCCESS: "GET_PLANS_SUCCESS",
  GET_PLANS_FAILURE: "GET_PLANS_FAILURE",
  GET_PLAN_REQUEST: "GET_PLAN_REQUEST",
  GET_PLAN_SUCCESS: "GET_PLAN_SUCCESS",
  GET_PLAN_FAILURE: "GET_PLAN_FAILURE",
  GET_SUBSCRIPTIONS_REQUEST: "GET_SUBSCRIPTIONS_REQUEST",
  GET_SUBSCRIPTIONS_SUCCESS: "GET_SUBSCRIPTIONS_SUCCESS",
  GET_SUBSCRIPTIONS_FAILURE: "GET_SUBSCRIPTIONS_FAILURE",
  GET_SUBSCRIPTION_ITEMS_REQUEST: "GET_SUBSCRIPTION_ITEMS_REQUEST",
  GET_SUBSCRIPTION_ITEMS_SUCCESS: "GET_SUBSCRIPTION_ITEMS_SUCCESS",
  GET_SUBSCRIPTION_ITEMS_FAILURE: "GET_SUBSCRIPTION_ITEMS_FAILURE",
  GET_SUBSCRIPTION_ITEM_REQUEST: "GET_SUBSCRIPTION_ITEM_REQUEST",
  GET_SUBSCRIPTION_ITEM_SUCCESS: "GET_SUBSCRIPTION_ITEM_SUCCESS",
  GET_SUBSCRIPTION_ITEM_FAILURE: "GET_SUBSCRIPTION_ITEM_FAILURE",
  GET_SUBSCRIPTION_REQUEST: "GET_SUBSCRIPTION_REQUEST",
  GET_SUBSCRIPTION_SUCCESS: "GET_SUBSCRIPTION_SUCCESS",
  GET_SUBSCRIPTION_FAILURE: "GET_SUBSCRIPTION_FAILURE",
  REMOVE_SUBSCRIPTION_ITEM_REQUEST: "REMOVE_SUBSCRIPTION_ITEM_REQUEST",
  REMOVE_SUBSCRIPTION_ITEM_SUCCESS: "REMOVE_SUBSCRIPTION_ITEM_SUCCESS",
  REMOVE_SUBSCRIPTION_ITEM_FAILURE: "REMOVE_SUBSCRIPTION_ITEM_FAILURE",
  UPDATE_SUBSCRIPTION_ITEM_REQUEST: "UPDATE_SUBSCRIPTION_ITEM_REQUEST",
  UPDATE_SUBSCRIPTION_ITEM_SUCCESS: "UPDATE_SUBSCRIPTION_ITEM_SUCCESS",
  UPDATE_SUBSCRIPTION_ITEM_FAILURE: "UPDATE_SUBSCRIPTION_ITEM_FAILURE",
};
