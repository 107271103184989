import callApi from "../../utils/callApi";

export function* getPlans() {
  yield callApi(
    "get",
    `${process.env.REACT_APP_CASHIER_ENDPOINT}/api/v1/plans`,
    "GET_PLANS",
    "plans",
    "There was an error while loading plans."
  );
}

export function* getPlan(action) {
  yield callApi(
    "get",
    `${process.env.REACT_APP_CASHIER_ENDPOINT}/api/v1/plans/${action.payload}`,
    "GET_PLAN",
    "plan",
    "There was an error while loading a plan."
  );
}

export function* getSubscription(action) {
  yield callApi(
    "get",
    `${process.env.REACT_APP_CASHIER_ENDPOINT}/api/v1/subscriptions/${action.payload}`,
    "GET_SUBSCRIPTION",
    "subscription",
    "There was an error while loading a subscription."
  );
}

export function* getSubscriptions() {
  yield callApi(
    "get",
    `${process.env.REACT_APP_CASHIER_ENDPOINT}/api/v1/subscriptions`,
    "GET_SUBSCRIPTIONS",
    "subscriptions",
    "There was an error while loading subscriptions."
  );
}

export function* getSubscriptionItem(action) {
  yield callApi(
    "get",
    `${process.env.REACT_APP_CASHIER_ENDPOINT}/api/v1/subscription-items/${action.payload}`,
    "GET_SUBSCRIPTION_ITEM",
    "subscription_item",
    "There was an error while loading a subscription item."
  );
}

export function* getSubscriptionItems() {
  yield callApi(
    "get",
    `${process.env.REACT_APP_CASHIER_ENDPOINT}/api/v1/subscription-items`,
    "GET_SUBSCRIPTION_ITEMS",
    "subscription_items",
    "There was an error while loading subscription items."
  );
}

export function* addSubscriptionItem(action) {
  yield callApi(
    "post",
    `${process.env.REACT_APP_CASHIER_ENDPOINT}/api/v1/subscription-items`,
    "ADD_SUBSCRIPTION_ITEM",
    null,
    "There was an error while creating a subscription item.",
    action.payload
  );
}

export function* updateSubscriptionItem(action) {
  const { id, ...info } = action.payload;
  yield callApi(
    "post",
    `${process.env.REACT_APP_CASHIER_ENDPOINT}/api/v1/subscription-items/${id}`,
    "UPDATE_SUBSCRIPTION_ITEM",
    null,
    "There was an error while updating a subscription item.",
    info
  );
}

export function* deleteSubscriptionItem(action) {
  let id = action.payload;
  yield callApi(
    "post",
    `${process.env.REACT_APP_CASHIER_ENDPOINT}/api/v1/subscription-items/${id}`,
    "REMOVE_SUBSCRIPTION_ITEM",
    null,
    "There was an error while deleting a subscription item."
  );
}
