const name = `${process.env.REACT_APP_NAME}`;

const CLINIC_KEY = 'herdrx', SITE_KEY = 'chuteside';

export const isClinic = () => name === CLINIC_KEY;

export const isSite = () => name === SITE_KEY;

export const getAppName = () => name.charAt(0).toUpperCase() + name.slice(1); 

export const getTitle = () => isClinic() ? 'HerdRx' : 'Chuteside'