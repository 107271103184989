import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./newBillingEntity.css";
import { LoadingCard, TitleBar } from "../../components";
import { addBillingEntity } from "../../stores/billingEntities";
import { clearAlert, setAlert } from "../../stores/alerts/";
import { BillingEntitySchema } from "../../utils/schemas";
import { FaFileInvoice } from "react-icons/fa";
import { Form, Input, Row, Col, Select, Button, message, Modal } from "antd";
import { countries, provinces, states } from '../../consts';
import history from "../../utils/history";

const { Option } = Select;

const NewBillingEntityScreen = (
  {
    loading,
    addBillingEntity,
    alert,
    setAlert,
    clearAlert,
    visible = true,
    values,
    formRef = null,
    profile
  }
) => {
  const [isFormValid, setFormValid] = useState(true);
  const [renderOptions, setRenderOptions] = useState(provinces);
  
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [isScreen, setIsScreen] = useState(false);
  const [form, setForm] = useState(null);
  const [newForm] = Form.useForm();

  useEffect(() => {
    if (!formRef) {
      setForm(newForm);
      setIsScreen(true);
    } else {
      setForm(formRef);
      setIsScreen(false);
    }

  
  }, [formRef, form, newForm]);


  useEffect(()=> {
    if (form && profile && isScreen) {
        form.setFieldsValue({
          city: profile.city,
          region: profile.region,
          country: profile.country
        });
    }
  }, [form, profile, isScreen]);

  const handleCountryChange = () => {
    form.resetFields(['region']);
    const currentCountry = form.getFieldValue('country');

    if (currentCountry === 'USA') setRenderOptions(states);
    else setRenderOptions(provinces);

  };

  const onFormValuesChange = () => {
    const formHasErrors = !!form.getFieldsError().filter(({ errors }) => errors.length).length;
    if (form.isFieldsTouched(['name', 'email', 'phone'], true) && !formHasErrors) {
      return setFormValid(true);
    }
    setFormValid(false);
  }

  const onFinish = (values) => {
    const formattedData = { ...values };
    formattedData.phone = `${formattedData.prefix}${formattedData.phone}`;
    delete formattedData.prefix;
    const { error } = BillingEntitySchema.validate(formattedData);

    if (error) {
      setAlert(error.message);
    } else {
      clearAlert();
      addBillingEntity(formattedData);
      message.success('Creating billing information..')

    }
  };

  const handlePhone = (e) => {
    let newValue = e.target.value;
    if (newValue) {
      if (newValue.substr(0, 2) === '+1') {
        newValue = newValue.substr(2);
      }
      newValue = newValue.split('').filter(char => Number(char) || char === '0').join('');
    }
    if (newValue !== undefined) {
      if (newValue.length > 10) newValue = newValue.substr(0, 10);
      if (isScreen) {
        form.setFieldsValue({phone: newValue });
      } else {
        form.setFieldsValue({'organization.phone': newValue });
      }
    }
  }

  const phonePrefixes = [1];

  const prefixSelector = (
    <Form.Item name={isScreen?'prefix':'organization.prefix'} noStyle>
      <Select
        style={{ width: 70 }}
        size="large"
      >
        {phonePrefixes.map(prefix =>
          <Option key={prefix} value={prefix}>+{prefix}</Option>
        )}
      </Select>
    </Form.Item>
  );

  const buildForm = () => {
    return (<>
        <Form.Item
          label="Billing Information Name"
          name={isScreen?'name':'organization.name'}
          rules={[{ required: visible, message: 'Please input your billing info name' }]}
        >
          <Input
            id="name"
            size="large"
            type="text"
            placeholder="Billing Information Name"
          />
        </Form.Item>
        <Row gutter={30}>
          <Col xs={24} md={12}>
            <Form.Item
              label="Email"
              name={isScreen?'email':'organization.email'}
              validateTrigger={['onBlur']}
              rules={[
                { required: visible, message: 'Please input your Email' },
                { type: 'email', message: 'The input is not valid E-mail' }
              ]}
            >
              <Input
                id="email"
                size="large"
                type="email"
                placeholder="your.email@example.com"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name={isScreen?'phone':'organization.phone'}
              label="Phone Number"
              rules={[
                { required: visible, message: 'Please input your phone number' },
                { pattern: /^\d{10}$/, message: 'Phone number should be 10 digits' }
              ]}
            >
              <Input
                id="phone"
                type="text"
                onChange={handlePhone}
                addonBefore={prefixSelector}
                placeholder="1234567890"
                style={{ width: '100%' }}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col xs={24} md={24}>
            <Form.Item
              label="Address"
              name={isScreen?'address':'organization.address'}
            >
              <Input
                id="address"
                size="large"
                type="text"
                placeholder="Address"
              />
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={30}>
          <Col xs={24} md={8}>
            <Form.Item
              label="City"
              name={isScreen?'city':'organization.city'}
            >
              <Input
                id="city"
                size="large"
                type="text"
                placeholder="Ottawa"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label="Country"
              name={isScreen?'country':'organization.country'}
            >
              <Select
                size="large"
                placeholder="Country"
                onChange={handleCountryChange}
              >
                {countries.map(country =>
                  <Option key={country} value={country}>{country}</Option>)
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label="Province or State"
              name={isScreen?'region':'organization.region'}
            >
              <Select
                size="large"
                placeholder="Province or State"
              >
                {renderOptions.map(opt =>
                  <Option key={opt} value={opt}>{opt}</Option>)
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24}>
            <Form.Item
              label="Postal Code"
              name={isScreen?'postal_code':'organization.postal_code'}
            >
              <Input
                id="postal_code"
                size="large"
                type="text"
                placeholder="Postal Code"
              />
            </Form.Item>
          </Col>
        </Row>
        
    </>);
  };

  if (isScreen) {
  return ( !loading && form ?(
    <div className='newBillingEntityContainer flex-column d-flex'>
      <TitleBar
        titleIcon={<FaFileInvoice />}
        titleText="New Billing Information"
        classes={
          {title: '!text-green-700'}
        }
        icon="icon-layers"
        paths={[
          {
            title: "Billing Info",
            path: `/billing-entities`
          },
          {
            title: "New Billing Information",
          }
        ]}
      />
      <Form
        layout="vertical"
        name="basic"
        form={form}
        onFinish={onFinish}
        onFieldsChange={onFormValuesChange}
      >
        {buildForm()}
        <Row justify='end' gutter={[8, 8]} className='pd-b-30'>
              <Col xs={24} md={6} span={8}>
                
                <Button
                  className='w-100'
                  type='cancel'
                  size='large'
                  onClick={() => setCancelModalVisible(true)}
                  loading={loading}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs={24} md={6} span={8}>
              <Button
                  className='w-100'
                  type='primary'
                  size='large'
                  loading={loading}
                  disabled={!isFormValid}
                  htmlType="submit"
                >
                  Update
                </Button>
            
              </Col>
            </Row>
            <Modal title="Discard changes?" visible={cancelModalVisible} onCancel={()=>setCancelModalVisible(false)} onOk={()=>{
                   history.push(
                    `/`
                  );
                }}><p>You might have unsaved changes. You will lose these changes if you continue.</p></Modal>
      </Form>
    </div> ):<LoadingCard />
  );
  } else {
    return buildForm();
  }
};

const mapStateToProps = (state) => ({
  alert: state.alerts.alert,
  loading: state.billingEntities.loading || state.users.loading,
  profile: state.users.profile
});

const mapDispatchToProps = {
  addBillingEntity,
  setAlert,
  clearAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewBillingEntityScreen);
