import React from "react";
import PropTypes from "prop-types";
import { Card, Tooltip, OverlayTrigger, Badge } from "react-bootstrap";
import "./infoCard.css";

const InfoCard = ({
  id,
  icon,
  title,
  subtitle,
  text,
  badgeText,
  badgeVariant,
  buttonText,
  onClick,
  ...props
}) => {
  function onHover(props) {
    return <Tooltip {...props}>{props.text}</Tooltip>;
  }

  return (
    <Card {...props} onClick={onClick} className='infoCard'>
      <Card.Body className='infoCardBody'>
        {React.cloneElement(icon, { size: 40, className: "infoCardIcon" })}
        <OverlayTrigger
          placement='right'
          delay={{ show: 200, hide: 200 }}
          overlay={onHover({ text: title, ...props })}
          text={title}
        >
          <Card.Title className='infoCardTitle'>{title}</Card.Title>
        </OverlayTrigger>
        {badgeText && (
          <Badge variant={badgeVariant || "primary"}>{badgeText}</Badge>
        )}
        {subtitle && (
          <Card.Subtitle className='mb-2 text-muted'>{subtitle}</Card.Subtitle>
        )}
        <Card.Text className='infoCardDescription'>{text}</Card.Text>
      </Card.Body>
    </Card>
  );
};

InfoCard.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  badgeText: PropTypes.string,
  badgeVariant: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default InfoCard;
