import axios from "axios";

const setAuthToken = () => {
  if (localStorage.auth) {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth && auth.token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${auth.token}`;
    } else delete axios.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;
