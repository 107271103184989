import React from "react";
import { Breadcrumb as BC } from "react-bootstrap";
import "./breadcrumb.css";

const Breadcrumb = () => {
  return (
    <BC>
      <BC.Item href='#'>Home</BC.Item>
      <BC.Item href='https://getbootstrap.com/docs/4.0/components/BC/'>
        Library
      </BC.Item>
      <BC.Item active>Data</BC.Item>
    </BC>
  );
};

export default Breadcrumb;
