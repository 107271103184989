import authTypes from "./authTypes";

const initialState = {
  ...(localStorage.auth ? JSON.parse(localStorage.getItem("auth")) : null),
  loading: true,
  authenticated: false,
  confirmed: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case authTypes.REGISTER_REQUEST:
    case authTypes.CONFIRM_REQUEST:
    case authTypes.LOGIN_REQUEST:
    case authTypes.IS_ACCOUNT_CONFIRMED_REQUEST:
    case authTypes.RESEND_CODE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case authTypes.IS_ACCOUNT_CONFIRMED_SUCCESS:
    case authTypes.IS_ACCOUNT_CONFIRMED_FAILURE:
      return {
        ...state,
        loading: false,
        confirmed: action.payload
      };

    case authTypes.RESET_PASSWORD_SUCCESS:
    case authTypes.RESET_PASSWORD_FAILURE:
    case authTypes.CONFIRM_RESET_PASSWORD_SUCCESS:
    case authTypes.CONFIRM_RESET_PASSWORD_FAILURE:
    case authTypes.REGISTER_SUCCESS:
    case authTypes.RESEND_CODE_SUCCESS:
    case authTypes.RESEND_CODE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case authTypes.CONFIRM_SUCCESS:
    case authTypes.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        authenticated: true,
      };

    case authTypes.REGISTER_FAILURE:
    case authTypes.CONFIRM_FAILURE:
    case authTypes.LOGIN_FAILURE:
      return {
        ...state,
        id: null,
        user: null,
        uuid: null,
        token: null,
        refresh: null,
        loading: false,
        authenticated: false,
      };

    case authTypes.AUTHENTICATE_SUCCESS:
      return {
        ...state,
        loading: false,
        authenticated: true,
      };

    case authTypes.REFRESH_TOKEN:
      return {
        ...state,
        ...action.payload,
      };

    case "CLEAR_ALL":
    case authTypes.LOGOUT:
    case authTypes.AUTHENTICATE_FAILURE:
      return {
        ...state,
        loading: false,
        authenticated: false,
        id: null,
        user: null,
        uuid: null,
        token: null,
        refresh: null,
      };

    default:
      return state;
  }
};
