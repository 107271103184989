import siteTypes from "./siteTypes";
import { takeLatest, all } from "redux-saga/effects";
import {
  getSites,
  getSite,
  getSiteUsers,
  addSite,
  updateSite,
  inviteSiteUser,
  removeSiteUser,
  removeSite,
  getSiteInvites,
  acceptSiteInvite,
  declineSiteInvite
} from "./siteActions";

export default function* siteSagas() {
  yield all([
    yield takeLatest(siteTypes.ADD_SITE_REQUEST, addSite),
    yield takeLatest(siteTypes.GET_SITE_REQUEST, getSite),
    yield takeLatest(siteTypes.GET_SITE_USERS_REQUEST, getSiteUsers),
    yield takeLatest(siteTypes.GET_SITES_REQUEST, getSites),
    yield takeLatest(siteTypes.GET_SITE_INVITES_REQUEST, getSiteInvites),
    yield takeLatest(siteTypes.INVITE_SITE_USER_REQUEST, inviteSiteUser),
    yield takeLatest(siteTypes.REMOVE_SITE_USER_REQUEST, removeSiteUser),
    yield takeLatest(siteTypes.UPDATE_SITE_REQUEST, updateSite),
    yield takeLatest(siteTypes.REMOVE_SITE_REQUEST, removeSite),
    yield takeLatest(siteTypes.ACCEPT_SITE_INVITE_REQUEST, acceptSiteInvite),
    yield takeLatest(siteTypes.DECLINE_SITE_INVITE_REQUEST, declineSiteInvite)
  ]);
}
