import React from "react";
import "./iconButton.css";
import PropTypes from "prop-types";
import {
    FaPlus,
    FaTrash,
    FaPen,
    FaEye,
    FaInfoCircle,
    FaCircle,
    FaArrowLeft,
} from "react-icons/fa";

const iconMap = {
    edit: <FaPen/>,
    delete: <FaTrash/>,
    view: <FaEye/>,
    info: <FaInfoCircle/>,
    add: <FaPlus/>,
    back: <FaArrowLeft/>,
};

const IconButton = ({title, variant, onClick, ...props}) => {
    let icon = iconMap[variant] || <FaCircle/>;
    return (
        <div
            {...props}
            title={title}
            className='iconButton d-flex justify-content-center align-items-center mx-1'
            onClick={onClick}
        >
            {icon}
        </div>
    );
};

IconButton.propTypes = {
    title: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(Object.keys(iconMap)).isRequired,
    onClick: PropTypes.func.isRequired,
};

export default IconButton;
