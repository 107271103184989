import authTypes from "./authTypes";

export const register = (email, password) => ({
  type: authTypes.REGISTER_REQUEST,
  // API expects username and email, but only email is needed
  payload: { username: email, email, password },
});

export const confirm = (username, code) => ({
  type: authTypes.CONFIRM_REQUEST,
  payload: { username, code },
});

export const isAccountConfirmed = (username) => ({
  type: authTypes.IS_ACCOUNT_CONFIRMED_REQUEST,
  payload: {username}
});
export const resendCode = (username) => ({
  type: authTypes.RESEND_CODE_REQUEST,
  payload: {username}
});

export const login = (email, password) => ({
  type: authTypes.LOGIN_REQUEST,
  // API has "username" field for email
  payload: { username: email, password },
});

export const logout = () => ({
  type: authTypes.LOGOUT_ASYNC,
});

export const authenticate = () => ({
  type: authTypes.AUTHENTICATE_REQUEST,
});

export const resetPassword = (email) => ({
  type: authTypes.RESET_PASSWORD_REQUEST,
  payload: { username: email }
});
export const confirmResetPassword = (email, password, code) => ({
  type: authTypes.CONFIRM_RESET_PASSWORD_REQUEST,
  payload: { username: email, password, code }
});
