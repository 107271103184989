import siteTypes from "./siteTypes";

const initialState = {
  sites: null,
  siteUsers: null,
  loading: false,
  siteInvites: null,
  invitesLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case siteTypes.ADD_SITE_REQUEST:
    case siteTypes.GET_SITE_REQUEST:
    case siteTypes.GET_SITE_USERS_REQUEST:
    case siteTypes.GET_SITES_REQUEST:
    case siteTypes.INVITE_SITE_USER_REQUEST:
    case siteTypes.REMOVE_SITE_USER_REQUEST:
    case siteTypes.UPDATE_SITE_REQUEST:
    case siteTypes.REMOVE_SITE_REQUEST:
    case siteTypes.ACCEPT_SITE_INVITE_REQUEST:
    case siteTypes.ACCEPT_SITE_INVITE_SUCCESS:
    case siteTypes.DECLINE_SITE_INVITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case siteTypes.DECLINE_SITE_INVITE_SUCCESS:
    case siteTypes.ACCEPT_SITE_INVITE_FAILURE:
    case siteTypes.DECLINE_SITE_INVITE_FAILURE:
    case siteTypes.ADD_SITE_FAILURE:

      return {
        ...state,
        loading: false
      }
    case siteTypes.GET_SITE_INVITES_REQUEST:
      return {
        ...state,
        invitesLoading: true,
      };
    case siteTypes.ADD_SITE_SUCCESS:
    case siteTypes.GET_SITE_SUCCESS:
    case siteTypes.UPDATE_SITE_SUCCESS:
      return {
        ...state,
        sites: { ...state.sites, [action.payload.id]: action.payload },
        loading: false,
      };

    case siteTypes.GET_SITE_USERS_SUCCESS:
      return {
        ...state,
        siteUsers: {
          ...state.siteUsers,
          [action.site_id]: action.payload.reduce((obj, user) => {
            obj[user.id] = user;
            return obj;
          }, {}),
        },
        loading: false,
      };
    case siteTypes.GET_SITE_INVITES_FAILURE:
      return {
        ...state,
        invitesLoading: false
      }
    case siteTypes.GET_SITES_SUCCESS:
      return {
        ...state,
        sites: action.payload.reduce((obj, site) => {
          obj[site.id] = site;
          return obj;
        }, {}),
        loading: false,
      };
    case siteTypes.GET_SITE_INVITES_SUCCESS:
      return {
        ...state,
        siteInvites: action.payload,
        invitesLoading: false
      };

    case siteTypes.GET_SITE_USERS_FAILURE:
      return {
        ...state,
        siteUsers: null,
        loading: false,
      };

    case siteTypes.GET_SITES_FAILURE:
      return {
        ...state,
        sites: null,
        loading: false,
      };

    case siteTypes.INVITE_SITE_USER_SUCCESS:
      if (state.siteUsers[action.site_id]) {
        const { [action.site_id]: users, ...rest } = state.siteUsers;
        return {
          ...state,
          siteUsers: {
            ...rest,
            [action.site_id]: {
              ...users,
              [action.payload?.userId]: action.payload,
            },
          },
          loading: false,
        };
      } else
        return {
          ...state,
          siteUsers: {
            ...state.siteUsers,
            [action.site_id]: { [action.payload?.userId]: action.payload },
          },
          loading: false,
        };

    case siteTypes.REMOVE_SITE_USER_SUCCESS:
      if (state.siteUsers[action.site_id]) {
        const { [action.site_id]: users, ...rest } = state.siteUsers;
        const { [action.payload]: _, ...userRemoved } = users;
        return {
          ...state,
          siteUsers: { ...rest, [action.site_id]: userRemoved },
          loading: false,
        };
      } else return { ...state, loading: false };

    case siteTypes.REMOVE_SITE_SUCCESS:
      if (state.sites[action.payload]) {
        const { [action.payload]: _, ...siteRemoved } = state.sites;
        return {
          ...state,
          sites: siteRemoved,
          loading: false,
        };
      } else return { ...state, loading: false };

    case siteTypes.REMOVE_SITE_FAILURE:
    case siteTypes.UPDATE_SITE_FAILURE:
    case siteTypes.INVITE_SITE_USER_FAILURE:
    case siteTypes.REMOVE_SITE_USER_FAILURE:
    case siteTypes.GET_SITE_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case siteTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case "CLEAR_ALL":
    case siteTypes.CLEAR_SITE_STORE:
      return initialState;

    default:
      return state;
  }
};
