import React, { useState, useEffect } from "react";
import "./frame.css";
import { Header, Sidebar, Footer, ToastAlert, Logo } from "../";
import { getTitle } from '../../config/app'

import history from '../../utils/history';

import { Layout, Typography } from 'antd';

const { Content, Sider } = Layout;
const { Text } = Typography;

const initialLayoutOptions = {
  aside: true
};



const Frame = ({ layoutOptions = initialLayoutOptions, suppressAlerts, children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [asideClass, setAsideClass] = useState('');
  useEffect(() => {
    if (collapsed)
      setTimeout(() => { setAsideClass('aside-collapsed') }, 30);
    setAsideClass('');
  }, [collapsed]);

  return (
    <Layout className="moby__layout">
      {!suppressAlerts && <ToastAlert />}
      {layoutOptions.aside &&
        <Sider id="Aside" className="aside" trigger={null} collapsible collapsed={collapsed} theme='dark'>
          <div className='side-bar-header'>
            <Logo type="sm" className='logoHeader' />
            {!collapsed && <Text className="logoText" strong>{getTitle()}</Text>}
            <i className="icon-menu icon drawer-icon" onClick={() => setCollapsed(!collapsed)}></i>
          </div>
          <Sidebar
            history={history}
            collapsed={collapsed}
          />
        </Sider>
      }
      <Layout className={`main-content ${asideClass} ${!layoutOptions.aside ? 'mr-0' : ''}`}>
        <Header
          collapsed={collapsed}
          toggle={() => setCollapsed(!collapsed)}
        />
        <Content className="app-content">
          <div className='main-content-inner'>
            {children}
          </div>
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default Frame;
