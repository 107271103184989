import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DetailCard, LoadingCard, TitleBar } from "../../components";
import { getSiteInvites, getSites, acceptSiteInvite, declineSiteInvite, getSite } from "../../stores/sites";
import { getClinicInvites, getClinic, acceptClinicInvite, declineClinicInvite, getTempClinic } from "../../stores/clinics";
import { Button, Empty, Tabs } from "antd";
import moment from "moment";
import { isClinic, isSite } from '../../config/app'
import ClinicInviteScreen from './ClinicInviteScreen'
import SiteInviteScreen from './SiteInviteScreen'

const { TabPane } = Tabs;

const InviteScreen = ({ profile }) => {
  return <div>
    <TitleBar titleText="Invites" />
    <p className="text-base" >
      This console will display invitations you have received.  To receive an invitation,
      provide your email address ({profile && profile.email}) to a clinic administrator and ask them to
      send you an invitation.  When they do, the invitation will be displayed below.
    </p>
    {isClinic() ? <>
      <ClinicInviteScreen />
    </> : <>
      <SiteInviteScreen />
    </>}
  </div>;
}

const mapStateToProps = (state) => ({
  profile: state.users.profile,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteScreen);

// export default InviteScreen;

// const InviteScreenx = ({ getSiteInvites, loading, siteInvites, getClinicInvites, declineClinicInvite, acceptClinicInvite, clinicInvites, getSites, getClinic, getTempClinic, sites, clinics, acceptSiteInvite, declineSiteInvite, profile, getSite, tempClinics }) => {
//   useEffect(() => {
//     getSiteInvites();
//     getClinicInvites();
//     getSites();

//   }, [getSiteInvites, getClinicInvites, getSites]);

//   const receivedClinicInvites = React.useMemo(() => (clinicInvites || []).filter(({ status }) => status === 'sent'), [clinicInvites])
//   const filteredSiteInvites = React.useMemo(() => (siteInvites || []).filter(({ status }) => status === 'sent'), [siteInvites])

//   if (loading) {
//     return <LoadingCard />;
//   }

//   return <div>
//     <TitleBar titleText="Invites" />
//     <p className="text-base" >
//       This console will display invitations you have received from clinics.  To receive an invitation, provide your email address ({profile && profile.email}) to a clinic administrator and ask them to send you an invitation.  When they do, the invitation will be displayed below.
//     </p>
//     {receivedClinicInvites.length && filteredSiteInvites.length ? <div>
//       <Tabs defaultActiveKey="2">

//         <TabPane tab={`Clinics (${receivedClinicInvites ? receivedClinicInvites.length : 0})`} key="2">
//           {receivedClinicInvites.map(({ clinic, ...invite }) => {
//             let classes = {
//               card: "h-auto"
//             }
//             // let clinic = tempClinics[invite.clinic_id] || clinics[invite.clinic_id];
//             return (clinic && <DetailCard
//               classes={classes} key={invite.id}
//               title={`${clinic.name} ${invite.status !== 'sent' ? <span class="uppercase text-xs bg-green-600 text-white rounded-sm  px-1">${invite.status}</span> : ''}`}
//               details={[
//                 { text: `Invited ${moment(invite.created_at).format('MMMM Do YYYY')}` },
//                 { text: `Expires ${moment(invite.expired_at).format('MMMM Do YYYY')}` }
//               ]}>
//               <div className="flex w-1/4 float-right">
//                 <Button disabled={invite.status !== "sent"} onClick={() => declineClinicInvite(invite.id)} className="flex-1 mr-1">Decline</Button>
//                 <Button disabled={invite.status !== "sent"} onClick={() => acceptClinicInvite(invite.id)} type="primary" className="flex-1 ml-1">Accept</Button>
//               </div>
//             </DetailCard>)
//           })}
//         </TabPane>
//       </Tabs>

//     </div> : <div className="text-center w-100">
//       <Empty
//         image={Empty.PRESENTED_IMAGE_SIMPLE}
//         description='No Invites'
//       />
//     </div>}
//   </div>;
// };

// const mapStateToProps = (state) => ({
//   siteInvites: state.sites.siteInvites,
//   clinicInvites: state.clinics.clinicInvites,
//   sites: state.sites.sites,
//   clinics: state.clinics.clinics || {},
//   loading: state.sites.invitesLoading || state.sites.loading || state.clinics.loading,
//   profile: state.users.profile,
//   tempClinics: state.clinics.tempClinic
// });

// const mapDispatchToProps = {
//   getSites,
//   getClinic,
//   getSiteInvites,
//   getClinicInvites,
//   acceptSiteInvite,
//   declineSiteInvite,
//   declineClinicInvite,
//   acceptClinicInvite,
//   getSite,
//   getTempClinic
// };

// export default connect(mapStateToProps, mapDispatchToProps)(InviteScreen);
