import React from "react";
import PropTypes from "prop-types";
import "./detailCard.css";
import { Card } from 'antd';
import { DetailList } from "..";

const DetailCard = ({ icon, title, details, onClick, classes, ...props }) => {

  if (!classes)
    classes = {};

    if (icon && typeof icon === 'string' && icon.match(/icon-/)) {
      icon = `icon ${icon}`;
    } 
  return (
    <div className="site-card-border-less-wrapper">
      <Card
        {...props}
        className={`detailCard ${classes.card}`}
  
        title={title && icon ?
          (
            <span className={classes.title}>
              {typeof icon==='string'?<i className={`${icon} ${classes.title}`}><span className="path1"></span><span className={`path2 ${classes.title}`}></span></i>:icon} &nbsp;
              {title}
            </span>
          ) : /<\/?[a-z][\s\S]*>/i.test(title)?<div dangerouslySetInnerHTML={{__html:title}}></div>:title
        }
        bordered={false}
        onClick={onClick}
        hoverable={true}
      >
        { details && <DetailList details={details} /> }
        {props.children}
      </Card>
    </div >
  );
};

DetailCard.propTypes = {
  details: PropTypes.array.isRequired,
};

export default DetailCard;
