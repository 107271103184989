import React from "react";
// import "./input.css";
import PropTypes from "prop-types";

import { Input } from "antd";
import MaskedInput from 'antd-mask-input';

const Input1 = ({ id, name, classname, size = 'medium', disabled = false, mask=false, ...rest }) => {

  if (!mask) {
    return(
      <Input
      id={id}
      className={classname}
      name={name}
      disabled={disabled}
      {...rest}
      size={size}
    />
    );
  }
  return (
      <MaskedInput
        id={id}
        className={classname}
        name={name}
        disabled={disabled}
        {...rest}
        size={size}
        mask={mask}
      />
  );
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Input1;
