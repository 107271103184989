import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./dashboard.css";
import {
  Section,
  LoadingCard,
  AccountAlert,
  BillingEntityCard,
  TitleBar, ClinicCard, SiteCard
} from "../../components";
import { getSites } from "../../stores/sites";
import { getClinics } from "../../stores/clinics";
import { getBillingEntities } from "../../stores/billingEntities";
import { getPlans } from "../../stores/subscriptions";
import { clearAlert } from "../../stores/alerts/";
import { isClinic, isSite } from '../../config/app'
import { Row, Col, Button, Empty, Tabs } from 'antd';
import WelcomeWizard from "../../components/WelcomeWizard/WelcomeWizard";

const { TabPane } = Tabs;

const DashboardScreen = (
  {
    sites,
    clinics,
    billingEntities,
    getSites,
    getBillingEntities,
    clearAlert,
    getPlans,
    user,
    getClinics,
    loading,
    profile
  }
) => {

  useEffect(() => {
    clearAlert();
    getSites();
    getBillingEntities();
    getPlans();
    getClinics();
    //eslint-disable-next-line
  }, []);

  const displayWelcome = () => {
    return profile && !profile.onboarded;
  };

  return (
    <div className='dashboardContainer d-flex flex-column'>
      {/* <WelcomeWizard /> */}
      {loading && <LoadingCard />}
      {!loading && displayWelcome() && <WelcomeWizard />}
      {!loading && !displayWelcome() && (<div>
        <TitleBar
          titleText="Vet on Farm Manager"
          paths={[
            {
              title: "Vet on Farm",
            },
          ]}
        />
        <AccountAlert />
        <Tabs defaultActiveKey="1" onChange={() => { }}>
          {isClinic() ? <TabPane tab={`Veterinary Clinics (${clinics ? Object.keys(clinics).length : 0})`} key="1">
            {clinics && Object.keys(clinics).length > 0 ? (
              <Row gutter={[16, 16]}>
                {Object.values(clinics).map((clinic) => {
                  return (
                    <Col sm={24} md={12} key={clinic.id}>
                      <ClinicCard key={clinic.id} clinic={clinic} />
                    </Col>
                  )
                })}
              </Row>
            ) : (
              <div className="text-center w-100">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description='No Clinics'
                />
              </div>
            )}
          </TabPane> : null}

          {isSite() ? <TabPane tab={`Operation Sites (${sites ? Object.keys(sites).length : 0})`} key="2">
            {sites && Object.keys(sites).length > 0 ? (
              <Row gutter={[16, 16]}>
                {Object.values(sites).map((site) => {
                  return (
                    <Col sm={24} md={12} key={site.id}>
                      <SiteCard key={site.id} site={site} />
                    </Col>
                  )
                })}
              </Row>
            ) : (
              <div className="text-center w-100">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description='No Sites'
                />
              </div>
            )}
          </TabPane> : null}

          {/*  */}

          <TabPane tab={`Billing Info (${billingEntities ? Object.keys(billingEntities).length : 0})`} key="3">
            <Row gutter={[16, 16]}>

              {billingEntities && Object.values(billingEntities).length > 0 ? (
                Object.values(billingEntities).map((billingEntity) => {
                  if (billingEntity.id)
                    return (
                      <Col sm={24} md={12} key={billingEntity.id}>
                        <BillingEntityCard
                          key={billingEntity.id}
                          billingEntity={billingEntity}
                        />
                      </Col>
                    )
                  return '';
                })
              ) : (
                <div className="text-center w-100">
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description='No Billing Information'
                  />
                </div>
              )}

            </Row>
          </TabPane>
        </Tabs>

        {user && (!clinics && !sites) && user['cognito:groups'][0] === 'User' &&
          <Section
            id='trial'
            title='Free Trial'
          >
            <div className='mb-5 w-100 text-center'>
              <Button variant='success' size='lg' className='w-75'>
                Start Your Free Trial!
              </Button>
            </div>
          </Section>
        }
      </div>

      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  clinics: state.clinics.clinics,
  sites: state.sites.sites,
  billingEntities: state.billingEntities.billingEntities,
  plans: state.subscriptions.plans,
  user: state.auth.user,
  loading: state.clinics.loading || state.sites.loading || state.billingEntities.loading,
  profile: state.users.profile
});

const mapDispatchToProps = {
  getSites,
  getBillingEntities,
  clearAlert,
  getPlans,
  getClinics
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen);
