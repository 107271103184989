import { combineReducers } from "redux";
import authReducer from "./auth/authReducer";
import alertReducer from "./alerts/alertReducer";
import userReducer from "./users/userReducer";
import billingEntityReducer from "./billingEntities/billingEntityReducer";
import siteReducer from "./sites/siteReducer";
import subscriptionReducer from "./subscriptions/subscriptionReducer";
import clinicReducer from "./clinics/clinicReducer";

export default combineReducers({
  auth: authReducer,
  alerts: alertReducer,
  users: userReducer,
  billingEntities: billingEntityReducer,
  sites: siteReducer,
  subscriptions: subscriptionReducer,
  clinics: clinicReducer
});
