import siteTypes from "./siteTypes";
import { put, retry } from "redux-saga/effects";
import axios from "axios";
import { setAlert } from "../alerts";
import { authenticate } from "../auth";
import store from "../rootStore";
import setAuthToken from "../../utils/setAuthToken";
import history from "../../utils/history";
import callApi from "../../utils/callApi";

export function* getSite(action) {
  yield callApi(
    "get",
    `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/sites/${action.payload}`,
    "GET_SITE",
    "site",
    "An error occurred while loading a site."
  );
}

export function* getSiteUsers(action) {
  if (
    !store.getState().sites.siteUsers ||
    !store.getState().sites.siteUsers[action.payload]
  ) {
    const config = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };
    store.dispatch(authenticate());
    let error = null;
    try {
      setAuthToken();
      const res = yield retry(5, 2000, axios, {
        method: "get",
        url: `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/sites/${action.payload}/users`,
        ...config,
      });
      const { success, data } = res.data;
      if (success) {
        yield put({
          type: siteTypes.GET_SITE_USERS_SUCCESS,
          site_id: action.payload,
          payload: data.users,
        });
      } else {
        error = "An error occurred while loading users for site.";
      }
    } catch (err) {
      console.error(err.message);
      error = "An error occurred while loading users for site.";
    }
    if (error) {
      store.dispatch(
        setAlert(error, "danger", siteTypes.GET_SITE_USERS_FAILURE)
      );
      yield put({ type: siteTypes.GET_SITE_USERS_FAILURE, payload: error });
    }
  } else yield put({ type: siteTypes.SET_LOADING, payload: false });
}

export function* getSites() {
  if (!store.getState().sites.sites)
    yield callApi(
      "get",
      `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/sites`,
      "GET_SITES",
      "sites",
      "An error occurred while loading sites."
    );
  else yield put({ type: siteTypes.SET_LOADING, payload: false });
}
export function* getSiteInvites() {
  yield callApi(
    "get",
    `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/site-invites`,
    "GET_SITE_INVITES",
    "site_invites",
    "An error occurred while loading invites."
  );
}

export function* acceptSiteInvite(action) {
  let id = action.payload;
  yield callApi(
    "put",
    `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/site-invites/${id}/accept`,
    "ACCEPT_SITE_INVITE",
    "site_invite",
    "An error occurred while loading invites."
  );

  yield callApi(
    "get",
    `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/site-invites`,
    "GET_SITE_INVITES",
    "site_invites",
    "An error occurred while loading invites."
  );
}
export function* declineSiteInvite(action) {
  let id = action.payload;
  yield callApi(
    "put",
    `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/site-invites/${id}/decline`,
    "DECLINE_SITE_INVITE",
    "site_invite",
    "An error occurred while loading invites.",
  );

  yield callApi(
    "get",
    `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/site-invites`,
    "GET_SITE_INVITES",
    "site_invites",
    "An error occurred while loading invites."
  );
  // store.dispatch({ type: 'GET_SITE_INVITES' })
}

export function* addSite(action) {
  yield callApi(
    "post",
    `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/sites`,
    "ADD_SITE",
    "site",
    "An error occurred while creating a site.",
    action.payload,
    (site) => {
      history.push(`/site/${site.id}`);
    }
  );
}

export function* updateSite(action) {
  const { id, ...info } = action.payload;
  yield callApi(
    "put",
    `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/sites/${id}`,
    "UPDATE_SITE",
    "site",
    "An error occurred while updating this site.",
    info,
    (site) =>
      history.push(`/site/${site.id}`)
  );
}

export function* inviteSiteUser(action) {
  const config = {
    // headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };

  store.dispatch(authenticate());
  let error = null;
  try {
    setAuthToken();
    const { siteId, userId } = action.payload || {};
    // https://9a66a4ba-aae7-42ff-8f86-731477a9e4a9.site.api.chuteside.com/api/v1/animals

    const inviteRes = yield retry(
      1,
      2000,
      axios.post,
      `https://${siteId}.site.api.chuteside.com/api/v1/site-invites`,
      { email: userId },
      // `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/sites/${siteId}/users/${userId}`,
      config
    );
    console.log('xxxxxxxxxxxxxxxx', inviteRes);
    const { success, data } = inviteRes.data;

    // console.log(inviteRes.data)
    if (success && data.site_invite) {
      let newUser = null;
      try {
        const userRes = yield retry(
          5,
          2000,
          axios.get,
          `https://${siteId}.site.api.chuteside.com/api/v1/site-invites`,
          config
        );
        newUser =
          (userRes &&
            userRes.data &&
            userRes.data.data &&
            userRes.data.data.user) ||
          null;
      } catch {
        // Todo: Handle failure to fetch user
      }
      yield put({
        type: siteTypes.INVITE_SITE_USER_SUCCESS,
        site_id: siteId,
        payload: newUser,
      });
    } else {
      error = "An error occurred while inviting user to site.";
    }
  } catch (err) {
    console.error(err && err.message ? err.message : err);
    error = "An error occurred while inviting user to site.";
  }
  if (error) {
    store.dispatch(
      setAlert(error, "danger", siteTypes.INVITE_SITE_USER_FAILURE)
    );
    yield put({ type: siteTypes.INVITE_SITE_USER_FAILURE, payload: error });
  }
}

export function* removeSiteUser(action) {
  const config = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  store.dispatch(authenticate());
  let error = null;
  try {
    setAuthToken();
    const { siteId, userId } = action.payload || {};
    const res = yield retry(
      5,
      2000,
      axios.delete,
      `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/sites/${siteId}/users/${userId}`,
      config
    );
    const { success } = res.data;
    if (success) {
      yield put({
        type: siteTypes.REMOVE_SITE_USER_SUCCESS,
        site_id: siteId,
        payload: userId,
      });
    } else error = "An error occurred while removing user.";
  } catch (err) {
    console.error(err.message);
    error = "An error occurred while removing user.";
  }
  if (error) {
    store.dispatch(
      setAlert(error, "danger", siteTypes.INVITE_SITE_USER_FAILURE)
    );
    yield put({ type: siteTypes.INVITE_SITE_USER_FAILURE, payload: error });
  }
}



export function* removeSite(action) {
  const config = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  store.dispatch(authenticate());
  let error = null;
  try {
    setAuthToken();
    const res = yield retry(
      5,
      2000,
      axios.delete,
      `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/sites/${action.payload}`,
      config
    );
    const { success } = res.data;
    if (success) {
      yield put({
        type: siteTypes.REMOVE_SITE_SUCCESS,
        payload: action.payload,
      });
    } else error = "An error occurred while deleting a site.";
  } catch (err) {
    console.error(err.message);
    error = "An error occurred while deleting a site.";
  }
  if (error) {
    store.dispatch(setAlert(error, "danger", siteTypes.REMOVE_SITE_FAILURE));
    yield put({ type: siteTypes.REMOVE_SITE_FAILURE, payload: error });
  }
}
