import React, { useEffect, useState } from "react";
import "./billingEntityUserList.css";
import { InviteUser, ConfirmModal } from "../";
import PropTypes from "prop-types";
import {
  getBillingEntityUsers,
  removeBillingEntityUser,
  inviteBillingEntityUser,
} from "../../stores/billingEntities";
import { connect } from "react-redux";

import { Table, Button, Modal } from "antd";
const { confirm } = Modal;

const BillingEntityUserList = ({
  billingEntityId,
  billingEntityUsers,
  getBillingEntityUsers,
  inviteBillingEntityUser,
  removeBillingEntityUser,
}) => {
  const [removedUser, setRemovedUser] = useState(null);
  const [users, setUsers] = useState([])

  useEffect(() => {
    getBillingEntityUsers(billingEntityId);
    //eslint-disable-next-line
  }, []);


  useEffect(() => {
    if (billingEntityUsers && billingEntityUsers[billingEntityId]) {
      setUsers(Object.values(billingEntityUsers[billingEntityId]))
    }
  }, [billingEntityUsers, billingEntityId]);

  const showConfirm = (user) => {
    confirm({
      title: 'Are you sure?',
      content: `Are you sure you want to remove ${user.first_name} ${user.last_name}`,
      onOk() {
        removeBillingEntityUser(billingEntityId, user.id);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (user) => (
        <span>{user?.first_name} {user?.last_name}</span >
      ),
      width: '30%'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '30%'
    },
    {
      title: 'Role',
      key: 'role',
      render: (user) => (
        <span>{["Admin", "User"][parseInt(`0x${user.id[0]}`) % 2]}</span>
      ),
      width: '20%'
    },
    {
      title: 'Remove',
      key: 'remove',
      render: (user) => (
        <Button type='danger' onClick={() => showConfirm(user)}>Remove</Button>
      ),
      width: '10%'
    }
  ];

  const onInvite = (email) => {
    inviteBillingEntityUser(billingEntityId, email);
  };

  return (
    <div className='userListContainer w-100'>
      {removedUser && (
        <ConfirmModal
          show={removedUser}
          title='Are you sure?'
          text={`Are you sure you want to remove ${removedUser.first_name} ${removedUser.last_name} from this site?`}
          onHide={() => setRemovedUser(null)}
          onConfirm={() => {
            removeBillingEntityUser(billingEntityId, removedUser.id);
            setRemovedUser(null);
          }}
        />
      )}
      <InviteUser onInvite={onInvite} />
      <Table
        className={"no-users-table"}
        loading={!billingEntityUsers}
        dataSource={users}
        columns={columns}
        pagination={{ pageSize: 5 }}
        rowKey="id"
      />
    </div >
  );
};

BillingEntityUserList.propTypes = {
  billingEntityId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  billingEntityUsers: state.billingEntities.billingEntityUsers,
});

const mapDispatchToProps = {
  getBillingEntityUsers,
  removeBillingEntityUser,
  inviteBillingEntityUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingEntityUserList);
