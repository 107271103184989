import React from "react";
import "./section.css";
import PropTypes from "prop-types";

const Section = ({ id, title, subtitle, button, children, className, sectionDivClassName,  ...props }) => {



  return (
    <div {...props} id={id} className={(sectionDivClassName || '') + ' sectionDiv my-3'}>
      <div className='sectionTitle'>
        <h2>{title}</h2>
        {button}
      </div>
      {subtitle && (
        <div className='sectionSubtitle'>
          <p>{subtitle}</p>
        </div>
      )}
      <div className={className}>{children || null}</div>
    </div>
  );
};

Section.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  button: PropTypes.element,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default Section;
