import React, { useState } from 'react';
import { Drawer, Tooltip } from 'antd';
import PropTypes from 'prop-types';

// Components
import Sidebar from "../Sidebar";

// styles
import "./side-bar-drawer.css";


const SideBarDrawer = ({ history }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div id="SideBarDrawer" className="side-bar-drawer">
      <Tooltip placement="bottom" title="Drawer">
        <i className="cursor-pointer icon icon-menu drawer-btn" onClick={() => setVisible(!visible)}></i>
      </Tooltip>
      <Drawer
        placement="left"
        onClose={() => setVisible(!visible)}
        visible={visible}
      >
        <Sidebar
          history={history}
          toggle={() => setVisible(!visible)}
        />
      </Drawer>
    </div >
  );
}

SideBarDrawer.propTypes = {
  history: PropTypes.object,
};

export default SideBarDrawer;
