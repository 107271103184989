import React from "react";
import "./confirmModal.css";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";

const ConfirmModal = ({ title, text, show, onConfirm, onHide, ...props }) => {
  if (!show) return null;
  return (
    <Modal
      {...props}
      show={show}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='danger' onClick={onConfirm}>
          Delete
        </Button>
        <Button variant='outline-primary' onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default ConfirmModal;
