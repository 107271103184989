import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute";
import {
  LoginScreen,
  RegisterScreen,
  PasswordResetScreen,
  DashboardScreen,
  NotFoundScreen,
  TestingScreen,
  ProfileScreen,
  BillingEntityScreen,
  ConfirmRegistrationScreen,
  NewBillingEntityScreen,
  SiteScreen,
  NewSiteScreen,
  UpdateBillingEntityScreen,
  UpdateSiteScreen,
  NewCreditCardScreen,
  UpdateCreditCardScreen,
  ClinicScreen,
  NewClinicScreen,
  UpdateClinicScreen,
  BillingEntitiesScreen,
  InviteScreen
} from "./screens";
import history from "./utils/history";
import setAuthToken from "./utils/setAuthToken";


setAuthToken();

const App = () => {
  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute exact path='/' component={DashboardScreen} />
        <PrivateRoute exact path='/welcome/:step' component={DashboardScreen} />
        <PrivateRoute
          exact
          path='/billing-entities'
          component={BillingEntitiesScreen}
        />
        <PrivateRoute
          exact
          path='/billing-entities/new'
          component={NewBillingEntityScreen}
        />
        <PrivateRoute
          exact
          path='/billing-entities/:billingEntityId'
          component={BillingEntityScreen}
        />
        <PrivateRoute
          exact
          path='/billing-entities/:billingEntityId/edit'
          component={UpdateBillingEntityScreen}
        />
        <PrivateRoute
          exact
          path='/billing-entities/:billingEntityId/card/new'
          component={NewCreditCardScreen}
        />

        <PrivateRoute
          exact
          path='/billing-entities/:billingEntityId/card/edit'
          component={UpdateCreditCardScreen}
        />
        <PrivateRoute
          exact
          path='/site/new'
          component={NewSiteScreen}
        />
        <PrivateRoute
          exact
          path='/site/:siteId/edit'
          component={UpdateSiteScreen}
        />
        <PrivateRoute
          exact
          path='/site/:siteId'
          component={SiteScreen}
        />
        <PrivateRoute
          exact
          path='/clinic/new'
          component={NewClinicScreen}
        />
        <PrivateRoute
          exact
          path='/clinic/:clinicId/edit'
          component={UpdateClinicScreen}
        />
        <PrivateRoute
          exact
          path='/clinic/:clinicId'
          component={ClinicScreen}
        />
        <PrivateRoute
          exact
          path='/users/:id/profile'
          component={ProfileScreen}
        />
        <PrivateRoute
          exact
          path='/invites'
          component={InviteScreen}
        />
        <Route
          exact
          path='/confirm/:email'
          component={ConfirmRegistrationScreen}
        />
        {process.env.NODE_ENV  === "development" && <PrivateRoute exact path='/test' component={TestingScreen} />}
        <Route exact path='/login' component={LoginScreen} />
        <Route exact path='/register' component={RegisterScreen} />
        <Route exact path='/reset' component={PasswordResetScreen} />
        <Route exact path='/reset/confirm' component={PasswordResetScreen} test="test" />
        <Route path='*' component={NotFoundScreen} />
      </Switch>
    </Router>
  );
};

export default App;
