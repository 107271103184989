import React from "react";
import PropTypes from "prop-types";
import "./detailList.css";

const DetailList = ({ details, ...props }) => {
  let OGdetailsCount = details.length;

  details = details.filter((detail) => {
    return (detail.text && detail.text !== "") || (detail.html && detail.html !== "")
  } );

  let detailsCount = details.length;
  
  return (
    <div className='w-100 m-0' size='sm' {...props}>
      {details &&
        details.map((detail) => (
          <div key={detail.text || detail.html} className={ (detail.className||'') + ' detailText'}>
            {typeof detail.icon === 'string'?(<i className={`icon ${detail.icon}`}><span className="path1"></span><span className="path2"></span></i>):detail.icon}
            {
              detail.html?(<span dangerouslySetInnerHTML={{__html:detail.html}}></span>):detail.text
            }
          </div>
        ))
        }
        {
          OGdetailsCount-detailsCount > 0 && (new Array(OGdetailsCount-detailsCount)).fill(0).map(()=> (
            <div key={Math.random()} className="detailText">&nbsp;</div>
          ))
        }
    </div>
  );
};

DetailList.propTypes = {
  details: PropTypes.array.isRequired,
};

export default DetailList;
