import clinicTypes from "./clinicTypes";
import { takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  getClinics,
  getClinic,
  getTempClinic,
  getClinicUsers,
  addClinic,
  updateClinic,
  inviteClinicUser,
  removeClinicUser,
  removeClinic,
  getClinicInvites,
  acceptClinicInvite,
  declineClinicInvite,
  validateDomain
} from "./clinicActions";

export default function* clinicSagas() {
  yield all([
    yield takeLatest(clinicTypes.ADD_CLINIC_REQUEST, addClinic),
    yield takeLatest(clinicTypes.GET_CLINIC_REQUEST, getClinic),
    yield takeEvery(clinicTypes.GET_TEMP_CLINIC_REQUEST, getTempClinic),
    yield takeLatest(clinicTypes.GET_CLINIC_USERS_REQUEST, getClinicUsers),
    yield takeLatest(clinicTypes.GET_CLINICS_REQUEST, getClinics),
    yield takeLatest(clinicTypes.GET_CLINIC_INVITES_REQUEST, getClinicInvites),
    yield takeLatest(clinicTypes.INVITE_CLINIC_USER_REQUEST, inviteClinicUser),
    yield takeLatest(clinicTypes.REMOVE_CLINIC_USER_REQUEST, removeClinicUser),
    yield takeLatest(clinicTypes.UPDATE_CLINIC_REQUEST, updateClinic),
    yield takeLatest(clinicTypes.REMOVE_CLINIC_REQUEST, removeClinic),
    yield takeLatest(clinicTypes.ACCEPT_CLINIC_INVITE_REQUEST, acceptClinicInvite),
    yield takeLatest(clinicTypes.DECLINE_CLINIC_INVITE_REQUEST, declineClinicInvite),
    yield takeLatest(clinicTypes.VALIDATE_DOMAIN_REQUEST, validateDomain)
  ]);
}
