import React from "react";
import PropTypes from "prop-types";
import "./heading.css";

const Heading = (props) => {
  return (
    <h2 id={props.id} className='heading'>
      {props.children}
    </h2>
  );
};

Heading.propTypes = {
  id: PropTypes.string,
};

export default Heading;
