import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import "./updateSite.css";
import {
  Section,
  TitleBar,
  BillingEntityCard,
  LoadingCard,
  PlanCard,
  Input
} from "../../components";

import { getSite, updateSite } from "../../stores/sites";
import { clearAlert, setAlert } from "../../stores/alerts/";
import { addBillingEntity, getBillingEntity } from "../../stores/billingEntities/";
import { getPlans } from "../../stores/subscriptions/";
import history from "../../utils/history";
import { Form, Button, Select, message, Input as InputDescription, Row, Col, Radio, Modal } from "antd";
import { countries, provinces, states } from '../../consts';
import { BillingEntitySchema, SiteSchema } from "../../utils/schemas";
import NewBillingEntityScreen from "../NewBillingEntity";

const { Option } = Select;

const UpdateSiteScreen = ({
  match,
  loading,
  sites,
  billingEntities,
  plans,
  getSite,
  getBillingEntity,
  getPlans,
  updateSite,
  alert,
  setAlert,
  clearAlert,
  newEntityID,
  addBillingEntity
}) => {
  const [disableState, setDisableState] = useState(true);
  const [renderOptions, setRenderOptions] = useState(provinces);
  const [isFormValid, setFormValid] = useState(true);
  const [disablePostalCode, setDisablePostalCode] = useState(true);
  const [codeFormLabel, setCodeFormLabel] = useState();
  const [codeMask, setCodeMask] = useState();
  const [form] = Form.useForm();
  const [carousel, setCarousel] = useState({
    left: 0,
    center: 1,
    right: 2
  });
  const [siteInfo, setSiteInfo] = useState({
    id: match.params.siteId,
    billing_entity_id: match.params.billingEntityId
  });
  const [billing_entity_id, setBillingEntityID] = useState(null);
  const [entityIDCache, setEntityIDCache] = useState(null);
  const [submitForm, setSubmitForm] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);


  const billingEntity = billingEntities && sites && billingEntities[sites[match.params.siteId].billing_entity_id];



  useEffect(() => {
    if (billingEntity) setBillingEntityID(billingEntity.id);
  }, [billingEntity]);
  useEffect(() => {
    clearAlert();
    getPlans();
    getSite(match.params.siteId);
    const site = sites && sites[match.params.siteId];

    //eslint-disable-next-line
    if (site) {
      form.setFieldsValue({
        name: site.name || '',
        description: site.description || '',
        country: site.country || '',
        region: site.region || '',
        city: site.city || '',
        address: site.address || '',
        postal_code: site.postal_code || '',
        domain: site.domain || '',
        subdomain: site.subdomain || ''
      });
      if (site.country) {
        setDisableState(false);
        setDisablePostalCode(false);
        setCodeFieldProps(site.country);
      }
      setSiteInfo({plan_id: site.plan_id});

    }
    //eslint-disable-next-line
  }, [sites === null, match.params.siteId]);

  useEffect(() => {
    const site = sites && sites[match.params.siteId];
    if (plans && site) {
      
      Object.keys(plans).map((index) => {
        if (plans[index].id === site.plan_id) {
          let total = Object.keys(plans).length-1;
          index = parseInt(index);
          if (total > 0) {
            setCarousel({
              left: (index - 1 < 0 ? total : index - 1),
              center: index,
              right: (index + 1 > total ? 0 : index + 1)
            });
          } else {
            setCarousel({left: null, center: 0, right: null});
          }
        }
        return null;
      });
    }
  }, [plans, sites, match.params.siteId]);

 /**
  * Plan Carousel shift method.
  * @param {*} side direction to shift.
  */
  const onSpin = (side) => {
    if (plans && side === 'left') {
      if (carousel.left > 0) {
        setCarousel({
          left: carousel.left - 1,
          center: carousel.left,
          right: carousel.center,
        });
      } else if (carousel.left === 0) {
        setCarousel({
          left: plans.length - 1,
          center: carousel.left,
          right: carousel.center,
        });
      }
    } else if (plans && side === 'right') {
      if (carousel.right < plans.length - 1) {
        setCarousel({
          left: carousel.center,
          center: carousel.right,
          right: carousel.right + 1,
        });
      } else if (carousel.right === plans.length - 1) {
        setCarousel({
          left: carousel.center,
          center: carousel.right,
          right: 0,
        });
      }
    }
  }

  const onSelectPlan = (plan) => {
    setSiteInfo({ ...siteInfo, plan_id: plan.id });
  };

  const onFormValuesChange = () => {
    const formHasErrors = !!form.getFieldsError().filter(({ errors }) => errors.length).length;

    if (!formHasErrors) {
      return setFormValid(true);
    }

    setFormValid(false);
  }


  const submitSite = useCallback(values => {
    const filteredInfo = Object.fromEntries(Object.entries(values).filter(([key,value]) => value!==null));

    for (var prop in siteInfo)
      if (siteInfo[prop] && siteInfo[prop].length > 0) filteredInfo[prop] = siteInfo[prop];

    const { error } = SiteSchema.validate(filteredInfo, {
      context: { edit: true },
    });

  

    if (error) {
      setAlert(error.message);
    } else {
      clearAlert();
      updateSite(filteredInfo);
      message.success('Updating site..')
    }
  },[updateSite, clearAlert, setAlert, siteInfo]);

  const submitOrganization = useCallback((values) => {
    const formattedData = { ...values };
    formattedData.phone = `${formattedData.prefix}${formattedData.phone}`;
    delete formattedData.prefix;
    const { error, value } = BillingEntitySchema.validate(formattedData);

    if (error) {
      setAlert(error.message);
    } else {
      clearAlert();
      addBillingEntity(value);
      message.success('Creating billing info..');

    }
  },[addBillingEntity,clearAlert,setAlert]);

  /**
   * On form submit helper method. Use values from form ideally to update the clinic.
   * @param {object} values Object values to use to submit the clinic data with. 
   */
   const onSubmit = useCallback((values) => {
    const filteredInfo = {...values};


    const organizationInfo = {};
    //Filter out organization fields no matter what.
      Object.entries(filteredInfo).forEach((v,i) => {
        if (v[0].includes("organization.")) {
            let index = v[0].split('.');
            organizationInfo[index[1]] = v[1];
            delete filteredInfo[v[0]];
        }
      });

    if (filteredInfo.billing_entity_id === -1) {
      submitOrganization(organizationInfo);
    }else {
      submitSite(filteredInfo);
    }
    setSubmitForm(false);
  }, [submitSite, submitOrganization]);

 

 

 

    /**
   * Helper to change the code field mask, label and the like.
   * @param {string} country 
   */
     const setCodeFieldProps = (country) => {
      if (country === "USA") { setCodeMask('11111');  setCodeFormLabel('Zip Code');}
      if (country === "Canada") { setCodeMask('A1A 1A1');  setCodeFormLabel('Postal Code');}
      setDisablePostalCode(false);
    };


    const handleCountryChange = () => {
      form.resetFields(['region', 'postal_code']);
      const currentCountry = form.getFieldValue('country');
      setCodeFieldProps(currentCountry);
  
      if (currentCountry === 'USA') {
        setRenderOptions(states);
      } else {
        setRenderOptions(provinces);
      }
      setDisableState(false);
    };

    const handleSelectOrgClick = (orgId) => {
      setBillingEntityID(orgId);
    };



    useEffect( () => {
      if (submitForm === true) {
        onSubmit({...form.getFieldsValue(true), billing_entity_id});
        return;
      }
    }, [submitForm, billing_entity_id, form, onSubmit]);
  
      //There was a change to number of billing entities. Assume we added a new one.
      useEffect( () => {
        if (newEntityID && newEntityID !== entityIDCache) {
          setEntityIDCache(newEntityID);
          let info = {...form.getFieldsValue(true),id: match.params.siteId, billing_entity_id: newEntityID }
          
          //inorder to not have recusion second time through onSubmit should have a billing_enitity_id that is not -1.
          //Lets set it to the latest billing ID just added!
          setBillingEntityID(newEntityID);
          onSubmit(info);
        }
      }, [newEntityID, form, onSubmit, match.params.siteId,entityIDCache]);

  return (
    <>
      {sites && billingEntity ? (
        <div className='newSiteContainer flex-column d-flex'>
          <TitleBar
            titleText='Update Site'
            icon='icon-compass_calibration'
            classes= {
              {title: 'text-red-800'}
            }
            buttons={[
              
              {
                key: "back",
                title: "Back to Site",
                onClick: () =>
                  history.push(`/site/${match.params.siteId}`),
                icon: (
                  <>
                    <i className={`icon icon-arrow_back_outlined`}>
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </>
                ),
              },
            ]}
            paths={[
              {
                title: 'Sites'
              },
              {
                title: sites[match.params.siteId].name,
                path: `/site/${match.params.siteId}`
              },
              {
                title: 'Update'
              }
            ]}
          />
          {/* <Row gutter={[16, 8]}>
            <Col xs={24} md={14}>
              <BillingEntityCard billingEntity={billingEntity} hoverable />
            </Col>
     
          </Row> */}
          <Section
            title='Site Information'
            className='updateSiteSection'
            
          >
            <Row className="mb-3">
              <Col md={24}>Fields marked with (*) are required.</Col>
            </Row>
            <Form
              onFinish={() =>
                onSubmit({...form.getFieldsValue(true), billing_entity_id, id: match.params.siteId})
              }
              onFieldsChange={onFormValuesChange}
              layout='vertical'
              form={form}
            >
              <Form.Item
                name='name'
                label='Site Name'
                rules={[
                  { required: true, message: 'Please input your site name!' }
                ]}
              >
                <Input
                  name='name'
                  type='text'
                  placeholder="Site Name"
                />
              </Form.Item>
              <Form.Item
                label='Site Description'
                name='description'
                rules={[
                  { required: true, message: 'Please input your site description!' }
                ]}
              >
                <InputDescription.TextArea
                  rows='3'
                  name='description'
                  type='text'
                  placeholder="Describe your site"
                />
              </Form.Item>
              <Form.Item
                label='Domain'
                name='domain'
                        >
                <Input
                  name='domain'
                  type='text'
                  placeholder="https://example.com"
                />
              </Form.Item>
              <Form.Item
                label='Subdomain'
                name='subdomain'
                        >
                <Input
                  name='subdomain'
                  type='text'
                  placeholder=""
                />
              </Form.Item>
            </Form>
          </Section>
          <Section
            title='Site Location'
            sectionDivClassName="!mt-10"
          >
            <Form
              onFinish={() =>
                onSubmit({...form.getFieldsValue(true), billing_entity_id, id: match.params.siteId})
              }
              onFieldsChange={onFormValuesChange}
              layout='vertical'
              form={form}
            >
              <Form.Item
                name='address'
                label='Address'
              >
                <Input
                  name='address'
                  type='text'
                  placeholder='Address'
                />
              </Form.Item>
              <Row gutter={[8, 8]}>
                <Col span={8} >
                  <Form.Item
                    label='City'
                    name='city'
                  >
                    <Input
                      name='city'
                      type='text'
                      placeholder='City'
                    />
                  </Form.Item>
                </Col>
                <Col span={8} >
                  <Form.Item
                    label='Country'
                    name='country'
                  >
                    <Select
                      onChange={handleCountryChange}
                      placeholder='Country'
                    >
                      {countries.map(country => (
                        <Option key={country}>{country}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} >
                  <Form.Item
                    name='region'
                    label='Province or State'
                  >
                    <Select
                      placeholder='Province or State'
                      disabled={disableState}
                    >
                      {renderOptions.map(opt => <Option key={opt}>{opt}</Option>)}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label={codeFormLabel}
                name='postal_code'
                style={{ width: '33%' }}
                hidden={disablePostalCode}
              >
                <Input
                  type='text'
                  name='postal_code'
                  id="postal_code"
                  mask={codeMask}
                />
              </Form.Item>

              { billingEntities && (
            <Section title="Select Billing Info" sectionDivClassName="!mt-10">
              <Form.Item name="billing_entity_id">
              <Radio.Group name="billing_entity_id" className="w-full ">
                {
                  Object.values(billingEntities).map((entry, i) => {
                    return <BillingEntityCard classes={{card: `position-absolute ${billing_entity_id === entry.id?' !border-primary !border':''}`}} key={i} id={entry.id} billingEntity={entry} onClick={() => handleSelectOrgClick(entry.id)}>
                              <Radio.Button checked={billing_entity_id === entry.id}
                              style={{position: "absolute", right: "1em", bottom: "1em"}}
                              className={` ${billing_entity_id === entry.id?'ant-radio-button-wrapper-checked':''}`} value={entry.id}>Select</Radio.Button>
                    </BillingEntityCard>
                  })
                }
                <div className={`shadow-ant text-base p-4 !mb-5 ${billing_entity_id!==-1?'cursor-pointer':'!border-primary !border'}`} onClick={()=> { if(billing_entity_id!==-1) setBillingEntityID(-1)}}>
                  <div className="flex">
                    <span className="flex-1 font-bold">Create new Billing Information</span>
                    <Radio.Button onClick={() => {setBillingEntityID(-1)}} className={`flex-initial ${billing_entity_id===-1?'ant-radio-button-wrapper-checked':''}`} checked={billing_entity_id===-1} value={-1}>Select</Radio.Button>
                  </div>
                  <div className={`mt-10 ${billing_entity_id===-1?'':'hidden'}`}>
                    <NewBillingEntityScreen visible={billing_entity_id===-1} className="mt-10" form={form}></NewBillingEntityScreen>
                  </div>
                </div>
              </Radio.Group>
              </Form.Item>
              
            </Section>
          )
          }

            </Form>
          </Section>
          {plans && (
            <Section
              title='Select a Pricing Plan'
            >
              <Row gutter={30}>
                <Col xs={24} md={1} className="price-arrows" onClick={() => onSpin('left')}>
                  <span className="price-arrows-span">
                    <i className="icon-arrow_back_ios icon" />
                  </span>
                </Col>
                <Col xs={24} md={22}>
                  <div id="PricingPlan" className="pricing-plan text-center pd-t-30 pd-b-30">
                    <Row justify="center">
                      {
                        plans &&
                        Object.values(carousel).map((i,index) => {
                          return (
                            <PlanCard
                              key={index}
                              plan={plans[i]}
                              active={siteInfo.plan_id === plans[i]?.id}
                              onClick={onSelectPlan}
                              extraClass={plans[i]?.extraClass}
                            />
                          );
                        })
                      }
                    </Row>
                  </div>
                </Col>
                <Col xs={24} md={1} className="price-arrows" onClick={() => onSpin('right')}>
                  <span className="price-arrows-span">
                    <i className="icon-arrow_forward_ios icon" />
                  </span>
                </Col>
              </Row>
            </Section>
          )}
          <Form
            onFinish={() =>
              onSubmit({...form.getFieldsValue(true), billing_entity_id, id: match.params.siteId})
            }
            layout='vertical'
            form={form}
          >
            <Row justify='end' gutter={[8, 8]} className='pd-b-30'>
              <Col xs={24} md={6} span={8}>
                
                <Button
                  className='w-100'
                  type='cancel'
                  size='large'
                  onClick={() => setCancelModalVisible(true)}
                  loading={loading}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs={24} md={6} span={8}>
              <Button
                  className='w-100'
                  type='primary'
                  size='large'
                  loading={loading}
                  disabled={!isFormValid}
                  htmlType="submit"
                >
                  Update
                </Button>
                <Modal title="Discard changes?" visible={cancelModalVisible} onCancel={()=>setCancelModalVisible(false)} onOk={()=>{
                   history.push(
                    `/site/${match.params.siteId}`
                  );
                }}><p>You might have unsaved changes. You will lose these changes if you continue.</p></Modal>
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <LoadingCard />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alerts.alert,
  loading: state.sites.loading,
  sites: state.sites.sites,
  billingEntities: state.billingEntities.billingEntities,
  plans: state.subscriptions.plans,
  newEntityID: state.billingEntities.newEntityID
});

const mapDispatchToProps = {
  getSite,
  getBillingEntity,
  getPlans,
  updateSite,
  setAlert,
  addBillingEntity,
  clearAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSiteScreen);
