import React from "react";
import { connect } from "react-redux";
import { removeBillingEntity } from "../../stores/billingEntities";
import history from "../../utils/history";
import { DetailCard } from "..";
import { Modal } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const { confirm } = Modal;


const BillingEntityCard = ({ billingEntity, removeBillingEntity, onClick, ...props }) => {

  if (!billingEntity) {
    billingEntity = {
      id: 0,
      name: "Billing Info Unknown",
      address: "Unknown",
      city: "Unknown",
      region: "Unknown",
      country: "Unknown",
      postal_code: "Unknown",
    };
  }

  const formatPhone = (phone) => {
    if (phone) {
      if (phone.length === 11) phone = phone.substr(1, 10);
      return `+1 (${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6)}`;
    }
  }

  const editOrg = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`/billing-entities/${billingEntity.id}/edit`)
  }


  const showConfirmDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    confirm({
      title: 'Are you sure?',
      content: `Are you sure you want to delete this Billing info: ${billingEntity.name}`,
      onOk() {
        removeBillingEntity(billingEntity.id);
        history.push("/");
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      history.push(`/billing-entities/${billingEntity.id}`)
    }
  };

  if (!props.classes) {
    props.classes = {
      title: '',
      card: ''
    };
  } 

    props.classes.title += ' !text-green-700';
    props.classes.card += ' !border-green-700 border-t-0 border-b-0 border-r-0 border-l-4';
  
    let details = billingEntity.id?[
      {
        icon: <FontAwesomeIcon icon="fa-regular fa-location-dot" className="!text-black !mr-2" />,
        text: billingEntity.id?[
          billingEntity.address,
          billingEntity.city,
          billingEntity.region,
          billingEntity.country,
          billingEntity.postal_code,
        ]
          .filter((x) => x)
          .join(", "):'',
      },
      {
        icon: <FontAwesomeIcon icon="fa-regular fa-envelope" className="!text-black !mr-2" />,
        text: billingEntity.email,
      },
      {
        icon: <FontAwesomeIcon icon="fa-regular fa-phone" className="!text-black !mr-2" />,
        text: formatPhone(billingEntity.phone),
      },
    ]:[];

  return (<div>
    <DetailCard
      {...props}
      title={billingEntity.name}
      icon={<FontAwesomeIcon icon="file-invoice-dollar" />}
      onClick={billingEntity.id?handleClick:()=>{}}
      classes={props.classes}
      extra={
       billingEntity.id>0 &&  <>
            <i className={`icon icon-edit cursor-pointer`} onClick={(e) => editOrg(e)}>
                <span className="path1"></span>
                <span className="path2"></span>
            </i>
            <i className={`icon icon-delete cursor-pointer`} onClick={(e) =>  showConfirmDelete(e)}>
                <span className="path1"></span>
                <span className="path2"></span>
            </i>
        </>
    }
      details={details}
    >
      {
        billingEntity.id===0 && <div className="text-yellow-500 p-3 bg-yellow-100 border !border-yellow-500 rounded">
          <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" className="!mr-2" />
          Sorry we could not find the billing information. Please update the clinic to ensure payments can still be recieved.
        </div>
      }
    </DetailCard>
    </div>
  );
};

BillingEntityCard.propTypes = {

};


const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    removeBillingEntity
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingEntityCard);

