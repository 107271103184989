import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./site.css";
import { getSites } from "../../stores/sites";
import { clearAlert } from "../../stores/alerts/";
import history from "../../utils/history";

import {
  Section,
  SiteUserList,
  LoadingCard,
  TitleBar,
  BillingEntityCard,
  SiteCard,
} from "../../components";

import {
  getSite,
  removeSite,
  getSiteUsers,
  inviteSiteUser,
  removeSiteUser,
} from "../../stores/sites";

import { getBillingEntity } from "../../stores/billingEntities";
import { FaMapMarked } from "react-icons/fa";
import { getPlans } from "../../stores/subscriptions";
import { Col, Row, Modal } from 'antd';
import Alert from "../../components/Alert";

const { confirm } = Modal;



const SiteScreen = ({
  match,
  sites,
  plans,
  billingEntities,
  siteUsers,
  getSite,
  getPlans,
  getBillingEntity,
  getSites,
  removeSite,
}) => {
  useEffect(() => {
    clearAlert();
    getPlans();
    getSite(match.params.siteId);
    
    getSites();
    window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, [match.params.siteId]);
 // const site = sites && sites[match.params.siteId];
  const [currentPlan, setCurrentPlan] = useState(null);
  const [billingEntity, setBillingEntity] = useState(null);
  const [site, setSite] = useState(null);

  useEffect(() => {
    if (plans && site) {
      setCurrentPlan(plans.find((x) => x.id === site.plan_id));
    }
    if (site) {
      getBillingEntity(site.billing_entity_id);
    }
  }, [plans, site, getBillingEntity]);

  useEffect( () => {
    if (sites) {
      setSite(sites[match.params.siteId]);
      
    }
    if (billingEntities && sites) {
      setBillingEntity(billingEntities[sites[match.params.siteId].billing_entity_id])
    }
  }, [sites, billingEntities, match.params.siteId]);

  

  const launchSite = (e) => {
    confirm({
      title: 'Are you sure?',
      content: `Are you sure you want to launch this site: ${site.name}. You will be redirected to https://${site.subdomain}.chuteside.app`,
      okText: `Launch`,
      okButtonProps: { type: 'success' },
      onOk() {
        console.log('OK');

        window.open(`https://${site.subdomain}.chuteside.app`);
        // window.open(`https://cs.vetonfarm.com/u/login?site=${site.id}`);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const showConfirmDelete = () => {
    confirm({
      title: 'Are you sure?',
      content: `Are you sure you want to delete this site: ${site.name}`,
      onOk() {
        removeSite(site.id);
        history.push("/");
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  //const billingEntity = billingEntities && sites && billingEntities[sites[match.params.siteId].billing_entity_id];
  //console.log(site, billingEntity);
  return (
    <>
      {site && billingEntity && plans ? (
        <div className='siteContainer'>
          <div>
            <TitleBar
              titleIcon={<FaMapMarked />}
              titleText={site.name}
              icon='icon-compass_calibration'
              classes= {
                {title: 'text-red-800'}
              }
              buttons={[
                {
                  key: "view",
                  title: "Launch Site",
                  type: 'success',
                  icon: (
                    <>
                      <i className={`icon icon-play_arrow cursor-pointer`}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </>
                  ),
                  onClick: launchSite,
                },
                {
                  key: "edit",
                  title: "Edit Site",
                  type: "primary",
                  icon: (
                    <>
                      <i className={`icon icon-edit cursor-pointer`}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </>
                  ),
                  onClick: () =>
                    history.push(
                      `/site/${site.id}/edit`
                    ),
                },
                {
                  key: "delete",
                  title: "Delete Site",
                  type: "danger",
                  icon: (
                    <>
                      <i className={`icon icon-delete cursor-pointer`}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </>
                  ),
                  onClick: () => showConfirmDelete(site),
                },
              ]}
              paths={[
                {
                  title: 'Sites'
                },
                {
                  title: site.name,
                }
              ]}
            />
            <Row gutter={[16, 24]}>
              <Col md={12} sm={24}>
                <SiteCard 
                  site={site}
                  details={[
                    {
                      icon: 'icon-description',
                      text: site.description
                    },
                    {
                      icon: 'icon-place',
                      text: [
                        site.address,
                        site.city,
                        site.region,
                        site.country,
                        site.postal_code,
                      ]
                        .filter((x) => x)
                        .join(", "),
                    },
                    {
                      icon: 'icon-receipt',
                      text:
                        currentPlan && currentPlan.name ? currentPlan.name +
                          " Plan"
                          : null,
                    },
                  ]}
                />
           
              </Col>
              <Col md={12} sm={24}>
                {billingEntity && <BillingEntityCard billingEntity={billingEntity} hoverable={true} />}
              </Col>
            </Row>
          </div>
          <Section
            title='Site Users'
          >
            {siteUsers && Object.keys(Object.values(siteUsers)[0]).length === 0 && <Alert type="info" message="It looks like there are no users for this site yet." rowProps={{className: 'my-5'}} />}
            <SiteUserList siteId={match.params.siteId} />
          </Section>
          
        </div>
      ) : (
        <LoadingCard />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  sites: state.sites.sites,
  billingEntities: state.billingEntities.billingEntities,
  siteUsers: state.sites.siteUsers,
  siteLoading: state.sites.loading,
  plans: state.subscriptions.plans,
});

const mapDispatchToProps = {
  getPlans,
  getSite,
  removeSite,
  getSiteUsers,
  getBillingEntity,
  getSites,
  clearAlert,
  inviteSiteUser,
  removeSiteUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteScreen);
