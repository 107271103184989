import React from "react";
import "./ribbonAlert.css";
import { Alert, Button } from "react-bootstrap";
import PropTypes from "prop-types";

const RibbonAlert = ({
  title,
  text,
  variant,
  onClick,
  button,
  show,
  ...props
}) => {
  if (!show) return null;
  return (
    <Alert {...props} variant={variant || "info"} className='ribbonAlert'>
      {title && <Alert.Heading>{title}</Alert.Heading>}
      <p>{text}</p>
      <hr />
      <div className='d-flex justify-content-end'>
        {button}
        <Button onClick={onClick} variant='outline-danger' className=' ml-3'>
          Dismiss
        </Button>
      </div>
    </Alert>
  );
};

RibbonAlert.propTypes = {
  show: PropTypes.bool.isRequired,
  variant: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  button: PropTypes.element,
};

export default RibbonAlert;
