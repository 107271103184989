export const emailRules = [
    {
        required: true,
        message: 'E-mail address is required.'
    },
    {
        type: 'email',
        message: 'Please enter a valid email address'
    }
];

export const confirmPasswordRules = [
    {
        min: 8,
        message: "Password must be at least 8 characters long",
    },
    {
        required: true,
        message: `Confirm password cannot be left blank`
    }
    // ,
    // (form) => ({
    //     validator: (_, value) => {
    //         return form.getFieldValue('password') !== value ? Promise.reject('Passwords must match') : Promise.resolve();
    //     }
    // })
    //,
    // (form) => ({
    //     validator: (_, value) => {
    //         return value.match(/[A-Z]/) ? Promise.resolve() :  Promise.reject('Password must contain at least one Uppercase letter.') ;
    //     }
    // }),
    // (form) => ({
    //     validator: (_, value) => {
    //         return value.match(/[1-9]/) ? Promise.resolve() :  Promise.reject('Password must contain at least one number.') ;
    //     }
    // }),
    // (form) => ({
    //     validator: (_, value) => {
    //         return value.match(/[a-z]/) ? Promise.resolve() :  Promise.reject('Password must contain at least one lowercase letter.') ;
    //     }
    // }),
    // (form) => ({
    //     validator: (_, value) => {
    //         return value.match(/[!@#$%^&*\+=\?\;]/) ? Promise.resolve() :  Promise.reject('Password must contain at least one lowercase letter.') ;
    //     }
    // })
];

export const passwordRules = [
    {
        min: 8,
        message: "Password must be at least 8 characters long",
    },
    {
        required: true,
        message: `Password cannot be left blank`
    }
    // ,
    // (form) => ({
    //     validator: (_, value) => {
    //         return form.getFieldValue('confirm_password') !== value ? Promise.reject('Passwords must match') : Promise.resolve();
    //     }
    // })
    // ,
    // (form) => ({
    //     validator: (_, value) => {
    //         return value.match(/[A-Z]/) ? Promise.resolve() :  Promise.reject('Password must contain at least one Uppercase letter.') ;
    //     }
    // })

];


