import { Spin } from "antd";
import React from "react";
import iconImg from "../../assets/vetonfarmlogo-sm.png";
import "./loadingCard.css";

const LoadingCard = ({ size = 'large', ...props }) => {
  return (
    <Spin
      size={size}
      className='loadingCard'
      indicator={<img className="animate-spin-slow" src={iconImg} />}
      {...props}
    />
  );
};

export default LoadingCard;
