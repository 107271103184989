import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./updateCreditCard.css";
import {
  Section,
  FormAlert,
  TitleBar,
  BillingEntityCard,
  LoadingCard,
} from "../../components";
import { Row, Col, Input, Button, Select, Form, Typography } from "antd";
import {
  updateCreditCard,
  getCreditCards,
  getBillingEntity,
} from "../../stores/billingEntities";
import { clearAlert, setAlert } from "../../stores/alerts/";
import { CreditCardSchema } from "../../utils/schemas";
import history from "../../utils/history";
import { FaCreditCard } from "react-icons/fa";
import Cleave from 'cleave.js/react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

const { Option } = Select;
const { Text } = Typography;

const UpdateCreditCardScreen = ({
  match,
  loading,
  billingEntities,
  creditCards,
  getBillingEntity,
  getCreditCards,
  updateCreditCard,
  alert,
  setAlert,
  clearAlert,
}) => {
  const [form] = Form.useForm();
  const [isValidForm, setValidForm] = useState(false);
  const [card, setCard] = useState({
    number: '0000000000000000',
    month: ' ',
    year: ' ',
    cvv: ' ',
    focus: ' '
  });

  const billingEntity = billingEntities && billingEntities[match.params.billingEntityId];
  const creditCard = creditCards && creditCards[match.params.billingEntityId];

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i <= currentYear + 20; i++) years.push(i);

  useEffect(() => {
    getBillingEntity(match.params.billingEntityId);
    getCreditCards();
    //eslint-disable-next-line
  }, []);

  const onFinish = (values) => {
    const formattedData = { ...values, billing_entity_id: match.params.billingEntityId };

    if (formattedData.exp_month < 10) {
      formattedData.exp_month = `0${String(formattedData.exp_month)}`;
    } else formattedData.exp_month = String(formattedData.exp_month);
    formattedData.exp_year = String(formattedData.exp_year);
    formattedData.id = creditCard.id;

    const { error, value } = CreditCardSchema.validate(formattedData, {
      context: { edit: true },
    });
    if (
      parseInt(value.exp_year) === currentYear &&
      parseInt(value.exp_month) < new Date().getMonth() + 1
    ) {
      setAlert("Expiry date cannot be in the past.");
      return;
    }

    if (error) {
      setAlert(error.message);
    } else {
      clearAlert();
      updateCreditCard(value);
    }
  };

  const validCreditCard = value => {
    let card = value.split(' ').join('');
    if (((+card.substr(0, 2) === 34 || +card.substr(0, 2) === 37) && card.length === 15)
      || (((+card.substr(0, 3) >= 300 && +card.substr(0, 3) <= 305)
        || +card.substr(0, 3) === 309) && card.length === 14)
      || (card.length === 16)) {
      return true;
    } else {
      return false;
    }
  }

  const onFormValuesChange = () => {
    const formHasErrors = !!form.getFieldsError().filter(({ errors }) => errors.length).length;

    setCard({
      number: form.getFieldValue('number') || '',
      month: form.getFieldValue('exp_month') || '',
      year: form.getFieldValue('exp_year') || '',
      cvv: form.getFieldValue('cvc') || '',
      focus: card.focus
    })

    if (form.isFieldsTouched(true) && !formHasErrors) {
      return setValidForm(true);
    }

    setValidForm(false);
  }

  const handleCvc = (e) => {
    let newValue = e.target.value;
    if (newValue) {
      newValue = newValue.split('').filter(char => Number(char) || char === '0').join('');
    }

    if (newValue !== undefined) {
      if (newValue.length > 3) newValue = newValue.substr(0, 3);
      form.setFieldsValue({ cvc: newValue });
    }
  }

  return (
    <>
      {billingEntity ? (
        <div className='billing-info-form'>
          <TitleBar
            titleIcon={<FaCreditCard />}
            titleText={'Edit Credit Card'}
            buttons={[
              {
                key: "back",
                title: "Back to Billing Information",
                onClick: () => {
                  clearAlert();
                  history.push(`/billing-entities/${match.params.billingEntityId}`);
                },
                icon: (
                  <>
                    <i className={`icon icon-arrow_back_outlined`}>
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </>
                ),
              },
            ]}
            paths={[
              {
                title: "Billing Info",
              },
              {
                title: billingEntity.name,
                path: `/billing-entities/${match.params.billingEntityId}`
              },
              {
                title: 'Edit Credit Card'
              }
            ]}
          />
          <Row gutter={15}>
            <Col xs={24} md={24}>
              <BillingEntityCard billingEntity={billingEntity} />
            </Col>
          </Row>
          <Section
            title='Edit Credit Card'
          >
            <Row gutter={[15,15]}>
              <Col md={24}>
                Fields marked with (*) are required.
              </Col>
              <Col xs={24} md={12}>
                <div className='flex-column mx-auto'>
                  {alert && <FormAlert>{alert.message}</FormAlert>}
                  <Form
                    form={form}
                    onFieldsChange={onFormValuesChange}
                    onFinish={onFinish}
                    layout="vertical"
                    name="creditcard"
                    initialValues={{ remember: true }}
                  >
                    <Row gutter={15}>
                      <Col xs={24} md={23}>
                        <Form.Item
                          label="Card Number"
                          name="number"
                          id='card-number-input'
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Card Number!'
                            },
                            () => ({
                              validator(_, value) {
                                if (validCreditCard(value) || value.length === 0) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error('Invalid card number'));
                              }
                            })
                          ]}
                        >
                          <Cleave
                            className='ant-input ant-input-lg'
                            placeholder="XXXX XXXX XXXX XXXX"
                            options={{ creditCard: true }}
                            name='number'
                            onFocus={() => {
                              let cCard = JSON.parse(JSON.stringify(card));
                              cCard.focus = 'number';
                              setCard(cCard);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={15} className="card-form-mycvv">
                      <Col xs={24} md={8}>
                        <Form.Item
                          label="Exp. Month"
                          name="exp_month"
                          dependencies={['exp_year']}
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Expire Month'
                            },
                            () => ({
                              validator(_, value) {
                                if (
                                  form.getFieldValue('exp_year') !== new Date().getFullYear()
                                  || (form.getFieldValue('exp_year') === new Date().getFullYear()
                                    && value >= new Date().getMonth() + 1)
                                ) return Promise.resolve();
                                else return Promise.reject(
                                  new Error(`Expiry date can't be in the past`)
                                );
                              }
                            })
                          ]}
                        >
                          <Select
                            size="large"
                            name='exp_month'
                            onFocus={() => {
                              let cCard = JSON.parse(JSON.stringify(card));
                              cCard.focus = 'expiry';
                              setCard(cCard);
                            }}
                          >
                            {[...Array(12).keys()].map(month =>
                              <Option key={month + 1} value={month + 1}>{month + 1}</Option>
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Text className="slash">/</Text>
                      <Col xs={24} md={8}>
                        <Form.Item
                          label="Exp. Year"
                          name="exp_year"
                          dependencies={['exp_month']}
                          rules={[{
                            required: true,
                            message: 'Please input your card Expire Year'
                          }]}
                        >
                          <Select
                            size="large"
                            name="exp_year"
                            onFocus={() => {
                              let cCard = JSON.parse(JSON.stringify(card));
                              cCard.focus = 'expiry';
                              setCard(cCard);
                            }}
                          >
                            {years.map(year => <Option key={year} value={year}>{year}</Option>)}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={7}>
                        <Form.Item
                          label="CVV"
                          name="cvc"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your CVV Number'
                            },
                            {
                              len: 3,
                              message: 'Should be 3 digits'
                            }
                          ]}
                        >
                          <Input
                            id='cvv'
                            size="large"
                            type="text"
                            onChange={handleCvc}
                            maxLength={3}
                            placeholder="CVV"
                            name='cvc'
                            onFocus={() => {
                              let cCard = JSON.parse(JSON.stringify(card));
                              cCard.focus = 'cvc';
                              setCard(cCard);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={15}>
                      <Col xs={24} md={24} className="card-buttons">
                        <Form.Item>
                          <Button
                            className="card-button-update"
                            type="primary"
                            htmlType="submit"
                            size="large"
                            disabled={!isValidForm}
                            loading={loading}
                          >
                            Update Credit Card
                          </Button>
                          <Button
                            type="ghost"
                            size="large"
                            disabled={loading}
                            onClick={() =>
                              history.push(`/billing-entities/${match.params.billingEntityId}`)
                            }
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
              <Col xs={24} md={12} className="credit-card-image">
                <Cards
                  cvc={card.cvv}
                  expiry={`${card.month > 9 ? card.month : '0' + card.month}${card.year}`}
                  focused={card.focus}
                  name={` `}
                  number={card.number}
                />
              </Col>
            </Row>
          </Section>
        </div>
      ) : (
        <LoadingCard />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alerts.alert,
  loading: state.billingEntities.loading,
  creditCards: state.billingEntities.creditCards,
  billingEntities: state.billingEntities.billingEntities,
});

const mapDispatchToProps = {
  getCreditCards,
  updateCreditCard,
  setAlert,
  clearAlert,
  getBillingEntity,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateCreditCardScreen);
