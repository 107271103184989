import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./clinic.css";
import { getClinics } from "../../stores/clinics";
import { clearAlert } from "../../stores/alerts";
import history from "../../utils/history";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Section,
  ClinicUserList,
  LoadingCard,
  TitleBar,
  BillingEntityCard,
  ClinicCard,
} from "../../components";

import {
  getClinic,
  removeClinic,
  getClinicUsers,
  inviteClinicUser,
  removeClinicUser,
} from "../../stores/clinics";

import { getBillingEntity } from "../../stores/billingEntities";
import { FaMapMarked } from "react-icons/fa";
import { getPlans } from "../../stores/subscriptions";
import { Col, Row, Modal } from 'antd';

const { confirm } = Modal;


const ClinicScreen = ({
  match,
  clinics,
  plans,
  billingEntities,
  clinicUsers,
  getClinic,
  getPlans,
  getBillingEntity,
  getClinics,
  removeClinic,
}) => {
  useEffect(() => {
    clearAlert();
    getPlans();
    getClinic(match.params.clinicId);
    //getBillingEntity(match.params.billingEntityId);
    getClinics();
    window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, []);

  const [billingEntity, setBillingEntity] = useState(null);
  const [clinic, setClinic] = useState(null);

  useEffect(() => {

    if (clinic) {
      getBillingEntity(clinic.billing_entity_id);
    }
  }, [plans, clinic, getBillingEntity]);

  useEffect(() => {
    if (clinics) {
      setClinic(clinics[match.params.clinicId]);
    }

  }, [clinics, match.params.clinicId]);

  useEffect(() => {
    if (billingEntities && clinic) {
      setBillingEntity(billingEntities[clinic.billing_entity_id]);
    }

  }, [billingEntities, clinic]);



  const launchClinic = (e) => {
    confirm({
      title: 'Are you sure?',
      content: `Are you sure you want to launch this clinic: ${clinic.name}`,
      okText: `Launch`,
      okButtonProps: { type: 'success' },
      onOk() {

       /* if (clinic.domain) {
          window.open(`${clinic.domain}`);
        } else*/ if (clinic.subdomain) {
          console.log(`${clinic.subdomain}.clinic.api.chuteside.com`);
          window.open(`https://${clinic.subdomain}.clinic.chuteside.com`);
        } else {
          console.log(`${clinic.id}.clinic.api.chuteside.com`);
          window.open(`https://${clinic.id}.clinic.chuteside.com`);
        }

      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const showConfirmDelete = () => {
    confirm({
      title: 'Are you sure?',
      content: `Are you sure you want to delete this clinic: ${clinic.name}`,
      onOk() {
        removeClinic(clinic.id);
        history.push("/");
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }


  return (
    <>
      {clinic && plans ? (
        <div className='clinicContainer'>
          <div>
            <TitleBar
              titleIcon={<FaMapMarked />}
              titleText={clinic.name}
              classes={
                { title: '!text-clinicBlue' }
              }
              icon={<FontAwesomeIcon icon="fa-solid fa-clinic-medical" />}
              buttons={[
                {
                  key: "view",
                  title: "Launch Clinic",
                  type: 'success',
                  icon: (
                    <>
                      <i className={`icon icon-play_arrow cursor-pointer`}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </>
                  ),
                  onClick: launchClinic,
                },
                {
                  key: "edit",
                  title: "Edit Clinic",
                  type: "primary",
                  icon: (
                    <>
                      <i className={`icon icon-edit cursor-pointer`}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </>
                  ),
                  onClick: () =>
                    history.push(
                      `/clinic/${clinic.id}/edit`
                    ),
                },
                {
                  key: "delete",
                  title: "Delete Clinic",
                  type: "danger",
                  icon: (
                    <>
                      <i className={`icon icon-delete cursor-pointer`}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </>
                  ),
                  onClick: () => showConfirmDelete(clinic),
                },
              ]}
              paths={[
                {
                  title: 'Clinics'
                },
                {
                  title: clinic.name,
                }
              ]}
            />
            <Row gutter={[16, 24]}>
              <Col md={12} sm={24}>
                <ClinicCard key={clinic.id} clinic={clinic} />

              </Col>
              <Col md={12} sm={24}>
                <BillingEntityCard billingEntity={billingEntity} hoverable={true} />
              </Col>
            </Row>
          </div>

          <Section
            title='Clinic Users'
          >
            <ClinicUserList clinicId={match.params.clinicId} />
          </Section>
        </div>
      ) : (
        <LoadingCard />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  clinics: state.clinics.clinics,
  billingEntities: state.billingEntities.billingEntities,
  clinicUsers: state.clinics.clinicUsers,
  clinicLoading: state.clinics.loading,
  plans: state.subscriptions.plans
});

const mapDispatchToProps = {
  getPlans,
  getClinic,
  removeClinic,
  getClinicUsers,
  getClinics,
  clearAlert,
  inviteClinicUser,
  removeClinicUser,
  getBillingEntity
};

export default connect(mapStateToProps, mapDispatchToProps)(ClinicScreen);
