import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import "./updateClinic.css";
import {
  Section,
  TitleBar,
  BillingEntityCard,
  LoadingCard,
  PlanCard,
  Input
} from "../../components";

import { getClinic, updateClinic, validateDomain, clearValidateDomain } from "../../stores/clinics";
import { clearAlert, setAlert } from "../../stores/alerts";
import { getPlans } from "../../stores/subscriptions";
import history from "../../utils/history";
import { Form, Button,Input as InputDescription, Row, Col, Radio, Modal, Collapse } from "antd";
import { countries,  tld, phonePrefixes } from '../../consts';
import { BillingEntitySchema, ClinicSchema } from "../../utils/schemas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewBillingEntityScreen from "../NewBillingEntity";
import { addBillingEntity, getBillingEntities } from "../../stores/billingEntities";
import { DNS_HOSTNAME, DEFAULT_DOMAIN } from "../../config/hosts";
import ContactInfoForm from "../../components/ContactInfoForm";

const { Panel } = Collapse;

const UpdateClinicScreen = ({
  match,
  loading,
  clinics,
  billingEntities,
  plans,
  getClinic,
  getPlans,
  updateClinic,
  setAlert,
  clearAlert,
  validateDomain,
  validate_domain,
  clearValidateDomain,
  addBillingEntity,
  newEntityID,
  profile,
  getBillingEntities
}) => {

  /*----------------------------------------------------------------------------------------------- 
                                              INITIALIZATION
   -------------------------------------------------------------------------------------------------*/

  //const billingEntity = billingEntities && clinics && billingEntities[clinics[match.params.clinicId].billing_entity_id];
  const [isFormValid, setFormValid] = useState(true);
  const [form] = useState(Form.useForm()[0]);
  const [clinicInfo, setClinicInfo] = useState({
    id: match.params.clinicId
  });
  const [carousel, setCarousel] = useState({
    left: 0,
    center: 1,
    right: 2
  });
  const [billing_entity_id, setBillingEntityID] = useState(null);
  const [billingEntity, setBillingEntity] = useState(null);
  const [clinic, setClinic] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const invalidFieldNames = {
    'name': 'Clinic Name',
    'billing_entity_id': 'Billing Information',
    'subdomain':'Subdomain',
    'plan_id':'Pricing Plan',
    'organization.name': 'Organization Name',
    'organization.email': 'Organization E-mail',
    'organization.phone': 'Organization Phone'
  };

  /*----------------------------------------------------------------------------------------------- 
                                           HOOKS
-------------------------------------------------------------------------------------------------*/
    useEffect(() => {
      clearAlert();
      getPlans();
      getClinic(match.params.clinicId);
      getBillingEntities(2000);
    }, [clearAlert, getPlans, getClinic, getBillingEntities, match.params.clinicId]);

    useEffect(() => {
      if (plans && clinic) {
        Object.keys(plans).map((index) => {
          if (plans[index].id === clinic.plan_id) {
            let total = Object.keys(plans).length - 1;
            index = parseInt(index);
            if (total > 0) {
            setCarousel({
              left: (index - 1 < 0 ? total : index - 1),
              center: index,
              right: (index + 1 > total ? 0 : index + 1)
            });
          } else {
            setCarousel({left: null, center: 0, right: null});
          }
          }
          return null;
        });
      }
      if (clinic) {
        form.setFieldsValue({
            id: clinic.id || '',
            name: clinic.name || '',
            description: clinic.description || '',
            country: clinic.country || '',
            region: clinic.region || '',
            city: clinic.city || '',
            address: clinic.address || '',
            postal_code: clinic.postal_code || '',
            plan_id: clinic.plan_id || '',
            domain: (clinic.domain|| '').replace("https://",""),
            subdomain: clinic.subdomain || '',
            email: clinic.email || "",
            phone: clinic.phone && clinic.phone.length===11?clinic.phone.substr(1,10):'',
            prefix: clinic.phone && clinic.phone.length===11?clinic.phone[0]:phonePrefixes[countries.indexOf(clinic.country)] || '1',
        });

        setClinicInfo({ plan_id: clinic.plan_id });
      }
      if (profile && form) {
        form.setFieldsValue({
          "organization.country": profile.country,
          "organization.region": profile.region,
          "organization.city": profile.city
        });
      }
    }, [plans, clinic, form, profile]);

    useEffect(() => {
      if (billingEntity) { 
        setBillingEntityID(billingEntity.id);
      }

    }, [billingEntity]);

    useEffect(() => {
      if (clinics) {
        setClinic(clinics[match.params.clinicId]);
      }
      if (billingEntities && clinic) {
        setBillingEntity(billingEntities[clinics[match.params.clinicId].billing_entity_id]);
      }
    }, [billingEntities,clinics, clinic, match.params.clinicId]);


    useEffect(() => {
      onFormValuesChange();
    });


  /*----------------------------------------------------------------------------------------------- 
                                             HELPER METHODS
  -------------------------------------------------------------------------------------------------*/


  const handleSelectOrgClick = (orgId) => {
    setBillingEntityID(orgId);
  };

  /**
   * Plan Carousel shift method.
   * @param {*} side direction to shift.
   */
  const onSpin = (side) => {
    if (plans && side === 'left') {
      if (carousel.left > 0) {
        setCarousel({
          left: carousel.left - 1,
          center: carousel.left,
          right: carousel.center,
        });
      } else if (carousel.left === 0) {
        setCarousel({
          left: plans.length - 1,
          center: carousel.left,
          right: carousel.center,
        });
      }
    } else if (plans && side === 'right') {
      if (carousel.right < plans.length - 1) {
        setCarousel({
          left: carousel.center,
          center: carousel.right,
          right: carousel.right + 1,
        });
      } else if (carousel.right === plans.length - 1) {
        setCarousel({
          left: carousel.center,
          center: carousel.right,
          right: 0,
        });
      }
    }
  }

  /**
   * Helper method for on select plan it will populate clinic info with the plan ID.
   * @param {object} plan Objec to populate. 
   */
  const onSelectPlan = (plan) => {
    setClinicInfo({ ...clinicInfo, plan_id: plan.id });
  };

  /**
   * Form method on change to validate form.
   * @returns 
   */
   const onFormValuesChange = () => {
    const formHasErrors = !!form.getFieldsError().filter(({ errors }) => errors.length).length;

    let clinicFields = ['name', 'billing_entity_id', 'subdomain','plan_id'];
    let organizationFields = ['organization.name', 'organization.email', 'organization.phone'];

    let fields = clinicFields.concat(billing_entity_id === -1 ? organizationFields : []);

    // if (billingEntities && billing_entity_id) {
    //   fields = fields.filter(value => {
    //     return value !== 'billing_entity_id';
    //   });
    // }

    displayInvalidFields(fields);
    if (!formHasErrors && invalidFields.length === 0) {
      return setFormValid(true);
    } else {
      return setFormValid(false);
    }
   
  }

  const submitClinic = useCallback(values => {
    const filteredInfo = values;
    filteredInfo.phone = `${filteredInfo.prefix}${filteredInfo.phone}`;
    delete filteredInfo.prefix;
    //Replace nulls with empty strings. 
    Object.keys(filteredInfo).forEach((i) => {
      if (filteredInfo[i] === null) filteredInfo[i] = "";
      if (i === 'domain' && filteredInfo[i]) filteredInfo[i] = "https://"+filteredInfo[i];
    });

    for (var prop in clinicInfo) {
      if (clinicInfo[prop] && clinicInfo[prop].length > 0) {
        filteredInfo[prop] = clinicInfo[prop];
      }
    }
    filteredInfo.billing_entity_id = billing_entity_id;
    const { error } = ClinicSchema.validate(filteredInfo, {
      context: { edit: true },
    });
    //validateDomain(match.params.clinicId, null, form.getFieldValue(['subdomain']));


    if (error) {
      console.error(error);
      setAlert(error.message);
    } else {
      clearAlert();
      updateClinic(filteredInfo);
     
    }
  }, [updateClinic, clearAlert, setAlert, clinicInfo, billing_entity_id]);


  const submitOrganization = useCallback((values) => {
    const formattedData = { ...values };
    formattedData.phone = `${formattedData.prefix}${formattedData.phone}`;
    delete formattedData.prefix;
    const { error, value } = BillingEntitySchema.validate(formattedData);

    if (error) {
      setAlert(error.message);
    } else {
      clearAlert();
      addBillingEntity(value);
    }
  }, [addBillingEntity, clearAlert, setAlert]);

  useEffect(() => {
    if (validate_domain !== null) {
      if (validate_domain.success) {
        let filteredInfo = { ...form.getFieldsValue(true), id: match.params.clinicId, billing_entity_id }

        const organizationInfo = {};
        //Filter out organization fields no matter what.
          Object.entries(filteredInfo).forEach((v,i) => {
            if (v[0].includes("organization.")) {
                let index = v[0].split('.');
                organizationInfo[index[1]] = v[1];
                delete filteredInfo[v[0]];
            }
          });
    
        if (filteredInfo.billing_entity_id === -1) {
          submitOrganization(organizationInfo);
        } else {
          submitClinic(filteredInfo);
        }
      } 
      clearValidateDomain();
    }
  }, [validate_domain, clearAlert, clearValidateDomain, clinicInfo, form, match.params.clinicId, setAlert, submitClinic, submitOrganization, billing_entity_id]);



  /**
   * On form submit helper method. Use values from form ideally to update the clinic.
   * @param {object} values Object values to use to submit the clinic data with. 
   */
  const onSubmit = useCallback((values) => {
    let domain =form.getFieldValue(['domain']);

    if(!billing_entity_id) {
      setAlert('Please select billing information.');
      return;
    }

    if (domain && (domain.match(new RegExp(`.(${tld.join('|')})`,'g'))|| []).length  === 0) {
      setAlert('Domain does not contain a valid top level domain (e.g .com, .ca, .net, .org)');
      return;
    }

    validateDomain(match.params.clinicId, domain?'https://'+form.getFieldValue(['domain']):null, form.getFieldValue(['subdomain']));
    return;
  }, [form, validateDomain,match.params.clinicId,billing_entity_id, setAlert]);

  useEffect( () => {
    if (newEntityID) {
      let info = form.getFieldsValue(true);
      
      //inorder to not have recusion second time through onSubmit should have a billing_enitity_id that is not -1.
      //Lets set it to the latest billing ID just added!
      info.billing_entity_id = newEntityID;
      setBillingEntityID(newEntityID);
      onSubmit(info);
    }
  }, [newEntityID, form, onSubmit]);


  const subdomainOnChange = (e) => {

    let newVal = e.currentTarget.value.replace(/[^a-zA-Z0-9-]+/, '');
    let alertMsg = null;

    let newVal2 = newVal.replace(/^-/, '');
    if (newVal !== newVal2) alertMsg = 'Subdomains may not start with a dash';

    let newVal3 = newVal2.replace(/-$/, '');
    if (newVal2 !== newVal3 && !alertMsg) alertMsg = 'Subdomains may not end with a dash.';
    
    newVal = newVal3.toLowerCase();

    if (newVal.length > 25) {
      newVal = newVal.substr(0, 25);
      if (!alertMsg) alertMsg = 'Subdomains must be 25 characters or less.';
    }

    if (alertMsg)
      setAlert(alertMsg, 'warning');

    form.setFieldsValue({ subdomain: newVal });
  }
  const domainChange = (e) => {

    let newVal = e.currentTarget.value.replace(/[^.a-zA-Z0-9-]+/, '');
    let alertMsg = null;

    let newVal2 = newVal.replace(/^-/, '');
    if (newVal !== newVal2) alertMsg = 'Domains may not start with a dash';

    let newVal3 = newVal2.replace(/-$/, '');
    if (newVal2 !== newVal3 && !alertMsg) alertMsg = 'Domains may not end with a dash.';
    
    newVal = newVal3.toLowerCase();


    //Only allow 1 dot.
    //if ((newVal.match(/\./g) || []).length > 1) newVal = newVal.substr(0,newVal.length-1);

    if (newVal.length > 25) {
      newVal = newVal.substr(0, 25);
      if (!alertMsg) alertMsg = 'Domains must be 25 characters or less.';
    }

    if (alertMsg)
      setAlert(alertMsg, 'warning');
    form.setFieldsValue({ domain: newVal });
  }
  const displayInvalidFields = (fields) => {
    let currentFormValues = {...form.getFieldsValue(true), billing_entity_id, plan_id: clinicInfo.plan_id};
    let temp = [...invalidFields];
    //
    let temp2 = temp.filter(v=>fields.includes(v));
    if (temp2.length !== temp.length) {
      temp = temp2;
      setInvalidFields(temp);
    }
    
    let changed = false;
    fields.forEach(field=> {
      let index = invalidFields.indexOf(field);
      if (!currentFormValues[field] && index === -1) {
        temp.push(field);
        changed = true;
      } else if (currentFormValues[field] && index > -1) {
        temp.splice(index,1);
        changed  = true;
      } 
    });

    if (changed) {
      setInvalidFields(temp);
    } 
  };

  const copyInformation = () => {

    let fieldValues = form.getFieldsValue();
    form.setFieldValue("organization.email", fieldValues.email);
    form.setFieldValue("organization.address", fieldValues.address);
    form.setFieldValue("organization.postal_code", fieldValues.postal_code);
    form.setFieldValue("organization.phone", fieldValues.phone);
    form.setFieldValue("organization.country", fieldValues.country);
    form.setFieldValue("organization.region", fieldValues.region);
    form.setFieldValue("organization.city", fieldValues.city);

    onFormValuesChange();
  };

  const clearInformation = () => {
    form.setFieldValue("organization.email", '');
    form.setFieldValue("organization.address",'');
    form.setFieldValue("organization.postal_code", '');
    form.setFieldValue("organization.phone",'');
    form.setFieldValue("organization.country", '');
    form.setFieldValue("organization.region", '');
    form.setFieldValue("organization.city", '');
    onFormValuesChange();
  };
 

  /*----------------------------------------------------------------------------------------------- 
                                           RENDER
-------------------------------------------------------------------------------------------------*/
  return (
    <>
      {clinic && billingEntities && form ? (
        <div className='newClinicContainer flex-column d-flex'>

          <TitleBar
            titleText='Update Clinic'
            icon={<FontAwesomeIcon icon="fa-solid fa-clinic-medical" />}
            classes={
              { title: '!text-clinicBlue' }
            }
            buttons={[
           
              {
                key: "back",
                title: "Back to Clinic",
                onClick: () =>
                  history.push(`/clinic/${match.params.clinicId}`),
                icon: (
                  <>
                    <i className={`icon icon-arrow_back_outlined`}>
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </>
                ),
              },
            ]}
            paths={[
              {
                title: 'Clinics'
              },
              {
                title: clinics[match.params.clinicId].name,
                path: `/clinic/${match.params.clinicId}`
              },
              {
                title: 'Update'
              }
            ]}
          />

            <Form
              onFinish={onSubmit}
              onFieldsChange={onFormValuesChange}
              layout='vertical'
              form={form}
            >
          <Section
            title='Clinic Information'
            className='updateClinicSection'
          >
            <Row className="mb-3">
              <Col md="24">Fields marked with (*) are required.</Col>
            </Row>
         
              <Form.Item
                name='name'
                label='Clinic Name'
                rules={[
                  { required: true, message: 'Name is required' }
                ]}
              >
                <Input
                  type='text'
                  placeholder="Clinic Name"
                />
              </Form.Item>
              <Form.Item
                label='Clinic Description'
                name='description'
              >
                <InputDescription.TextArea
                  rows='3'
                  type='text'
                  placeholder="Describe your clinic"
                />
              </Form.Item>
              </Section>
              <Section
                title='Accessing your HerdRX App'
                className='updateClinicSection'
                sectionDivClassName='mt-10'
              >
            
          
              <div className="ant-col ant-form-item-label"><label className="font-bold" title="Subdomain">Subdomain <span className="text-red-400">*</span></label></div>
              <div className=" !mt-1 leading-normal text-xs bg-blue-100 text-gray-500 p-3 rounded mb-3">
              Set a subdomain of {DEFAULT_DOMAIN} that can be used to access your HerdRx application.<br />
                Subdomains must:
                <ul className="list-disc list-inside">
                    <li>Be a minimum of 3 characters</li>
                    <li>Only be letters, numbers or dashes (-)</li>
                    <li>Not start or end with a dash (-)</li>
                  </ul>
                </div>
              <Form.Item
                name='subdomain'
                rules={[
                  { required: true, message: 'Subdomain is required' }
                ]}
              >
                 
                <Input
                  id='subdomain'
                  type='text'
                  placeholder=""
                  onChange={subdomainOnChange}
                  addonBefore={'https://'}
                  addonAfter={`.${DEFAULT_DOMAIN}`}

                />
               
              </Form.Item>

              <Collapse className="!mb-5" defaultActiveKey={clinic.domain?1:null}>
              <Panel header="I have a custom domain" key="1">
              <div className="ant-col ant-form-item-label">
                <label className="font-bold" title="Domain">Domain</label>
              </div>
              <div className=" !mt-1 leading-normal text-xs bg-blue-100 text-gray-500 p-3 rounded mb-2">
                    To access your HerdRx app via custom domain you must:
                    <ul className="list-disc list-inside ">
                      <li>
                        Own or control your custom domain
                      </li>
                      <li>create a DNS A record pointing your domain to <b><i>{DNS_HOSTNAME}</i></b></li>
                    </ul>
                  </div>
                 <div className=" !mt-1 leading-normal text-xs bg-blue-100 text-gray-500 p-3 rounded mb-2">
              Domains must:
              <ul className="list-disc list-inside ">
                  <li>Must have a suffix of a top level domain (i.e. .com, .net, .org, .ca, etc.)</li>
                  <li>Be a minimum of 3 characters</li>
                  <li>Only be letters, numbers or dashes (-)</li>
                  <li>Not start or end with a dash (-)</li>
                </ul>
              </div>
              <Form.Item
                label='Domain'
                name='domain'
              >
                <Input
                  type='text'
                  placeholder="example.com"
                  id="domain"
                  name="domain"
                  addonBefore={'https://'}
                  onChange={domainChange}
                />
              </Form.Item>
              </Panel></Collapse>
           
          </Section>
          <Section
            title='Clinic Location'
            sectionDivClassName='mt-10'
            >
              <ContactInfoForm form={form} />
          </Section>
          {plans && (
            <Section
              title='Select a Pricing Plan'
              sectionDivClassName='mt-10'

            >
              <Row gutter={30}>
                { plans && plans.length > 3 && <Col xs={24} md={1} className="price-arrows" onClick={() => onSpin('left')}>
                  <span className="price-arrows-span">
                    <i className="icon-arrow_back_ios icon" />
                  </span>
                </Col> }
                <Col xs={24} md={22}>
                  <div id="PricingPlan" className="pricing-plan text-center pd-t-30 pd-b-30">
                    <Row justify="center">
                      {
                        plans &&
                        Object.values(carousel).map((i,index) => {
                            return (
                              <PlanCard
                                key={index}
                                plan={plans[i]}
                                active={clinicInfo.plan_id === plans[i]?.id}
                                onClick={onSelectPlan}
                                extraClass={plans[i]?.extraClass}
                              />
                            );
                        })
                      }
                    </Row>
                  </div>
                </Col>
                {plans && plans.length > 3 && <Col xs={24} md={1} className="price-arrows" onClick={() => onSpin('right')}>
                  <span className="price-arrows-span">
                    <i className="icon-arrow_forward_ios icon" />
                  </span>
                </Col> }
              </Row>
            </Section>
          )}

        

              { billingEntities && (
            <Section title="Select Billing Info" >
              <Form.Item name="billing_entity_id">
              <Radio.Group name="billing_entity_id" className="w-full ">
                {
                  Object.values(billingEntities).map((entry, i) => {
                    return <BillingEntityCard classes={{card: `position-absolute ${billing_entity_id === entry.id?' !border-primary !border':''}`}} key={i} id={entry.id} billingEntity={entry} onClick={() => handleSelectOrgClick(entry.id)}>
                              <Radio.Button checked={billing_entity_id === entry.id}
                              style={{position: "absolute", right: "1em", bottom: "1em"}}
                              className={` ${billing_entity_id === entry.id?'ant-radio-button-wrapper-checked':''}`} value={entry.id}>Select</Radio.Button>
                    </BillingEntityCard>
                  })
                }
                <div className={`shadow-ant text-base p-4 !mb-5 ${billing_entity_id!==-1?'cursor-pointer':'!border-primary !border'}`} onClick={()=> { if(billing_entity_id!==-1) setBillingEntityID(-1)}}>
                  <div className="flex">
                    <span className="flex-1 font-bold">Create new Billing Information</span>
                    <Radio.Button onClick={() => {setBillingEntityID(-1)}} className={`flex-initial ${billing_entity_id===-1?'ant-radio-button-wrapper-checked':''}`} checked={billing_entity_id===-1} value={-1}>Select</Radio.Button>
                  </div>
                  <div className={`mt-2 justify-start gap-1 ${billing_entity_id === -1 ? 'flex' : 'hidden'}`}>
                        <Button onClick={copyInformation} className="">Copy Clinic Information</Button>
                        <Button onClick={clearInformation}>Clear</Button>
                  </div>
                  <div className={`mt-10 ${billing_entity_id===-1?'':'hidden'}`}>
                    <NewBillingEntityScreen visible={billing_entity_id===-1} className="mt-10" formRef={form}></NewBillingEntityScreen>
                  </div>
                </div>
              </Radio.Group>
              </Form.Item>
              
            </Section>
          )
          }
          {invalidFields.length>0 ? <Row justify="end" gutter={[8,8]} className="pd-b-30">
              <Col xs={24} md={12} span={8}>
                <div className=" !mt-1 leading-normal text-xs bg-yellow-100 text-gray-500 p-3 rounded mb-2">
                  <span className="font-bold">Some information is missing. Please fill out: </span><br /> {invalidFields.map(key=> invalidFieldNames[key]).join(', ')}
                </div>
              </Col>
            </Row>:''}
            <Row justify='end' gutter={[8, 8]} className='pd-b-30'>
              <Col xs={24} md={6} span={8}>
              <Button
                  className='w-100'
                  type='cancel'
                  size='large'
                  onClick={() =>setModalVisible(true)}
                  loading={loading}
                >
                  Cancel
                </Button>
               
              </Col>
              <Col xs={24} md={6} span={8}>
              <Button
                  className='w-100'
                  type='primary'
                  size='large'
                  loading={loading || clinics === null}
                  disabled={!isFormValid}
                  htmlType="submit"
                >
                 
                  Update 
                </Button>
                <Modal title="Discard changes?" visible={modalVisible} onCancel={()=>setModalVisible(false)} onOk={()=> history.push(
                      `/clinic/${match.params.clinicId}`
                    )}>
                  <p>You might have unsaved changes. You will lose these changes if you continue.</p>
                </Modal>
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <LoadingCard />
      )}
    </>
  );
};

/*----------------------------------------------------------------------------------------------- 
                                           REDUX METHODS
-------------------------------------------------------------------------------------------------*/

const mapStateToProps = (state) => ({
  alert: state.alerts.alert,
  loading: state.clinics.loading,
  clinics: state.clinics.clinics,
  billingEntities: state.billingEntities.billingEntities,
  plans: state.subscriptions.plans,
  validate_domain: state.clinics.validate_domain,
  newEntityID: state.billingEntities.newEntityID,
  profile: state.users.profile
});

const mapDispatchToProps = {
  getClinic,
  getPlans,
  updateClinic,
  setAlert,
  clearAlert,
  validateDomain,
  clearValidateDomain,
  addBillingEntity,
  getBillingEntities
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateClinicScreen);
