import { Form, Input, Select } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import { countries, states, provinces } from '../../consts';

const { Option } = Select;

const ProfileScreen = ({ form, profile, setValidForm, updatePassword = true }) => {

  const [disableState, setDisableState] = useState(true);
  const [renderOptions, setRenderOptions] = useState(provinces);
  const [password, setPassword] = useState(null);
  const [confirm_password, setConfirmPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(false);


  useEffect( () => {
    if (profile && profile.country) {
        setDisableState(false);
    }
  }, [profile]);

  const validatePasswords = useCallback(() => {

    let symbols = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g;
  
    if (!!password || !!confirm_password) {
        if (password !== confirm_password) {
          setPasswordError("Passwords do not match");
        } else if (password.length < 8 || confirm_password.length < 8) {
          setPasswordError("Password must be 8 characters long");
        } else if (!password.match(/[A-Z]/g) || !confirm_password.match(/[A-Z]/g)) {
          setPasswordError("Password must have at least 1 uppercase letter");
        } else if (!password.match(/[a-z]/g) || !confirm_password.match(/[a-z]/g)) {
          setPasswordError("Password must have at least 1 lowercase letter");
        } else if (!password.match(/[0-9]/g) || !confirm_password.match(/[0-9]/g)) {
          setPasswordError("Password must have at least 1 number");
        } else if (password.match(/\s/g) || confirm_password.match(/\s/g)) {
          setPasswordError("Password cannot contain spaces.");
        } else if (!password.match(symbols) || !confirm_password.match(symbols)) {
          setPasswordError("Password must contain at least 1 symbol");
        } else { //password is strong enough.
            //updatePassword({ password });
            setPasswordError(false);
            setValidForm(true);
            return;
        }
    } else {
      setValidForm(true);
      setPasswordError(false);
      return;
    }
    setValidForm(false);
  }, [confirm_password, password, setValidForm]);

  useEffect( useCallback(() => {
    validatePasswords();
  }, [validatePasswords]));

  const handleCountryChange = () => {
    form.resetFields(['region'])
    const currentCountry = form.getFieldValue('country');
    if (currentCountry === 'USA') {
        setRenderOptions(states);
        setDisableState(false);
    } else if (currentCountry === 'Canada') {
        setRenderOptions(provinces);
        setDisableState(false);
    } else {
      setDisableState(true);
    }
    
};
 




  return (
    <>
      <Form.Item label="First Name" name="first_name">
        <Input id="first_name" name="first_name" type="text" placeholder="First Name" />
      </Form.Item>
      <Form.Item label="Last name" name="last_name">
        <Input id="last_name" name="last_name" type="text" placeholder="Last name" />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: "Please input your email address!" },
          { type: "email", message: "Please enter a valid email" },
        ]}
        required
      >
        <Input id="email" name="email" type="email" placeholder="Email" />
      </Form.Item>

      

      <Form.Item label="Country" name="country"
      >
        {/* <Input id="country" name="country" type="text" placeholder="Country" /> */}
        <Select onChange={handleCountryChange} value="" placeholder="Country">
        <Option key="">-- Select Country --</Option>
          {countries.map((country) => (
            <Option key={country}>{country}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Province or State" name="region">
        <Select placeholder={form.getFieldValue('country')==='Canada'?'Choose a province':form.getFieldValue('country')==='USA'?'Choose a state':''} value="" disabled={disableState}>
        <Option key="">-- Select --</Option>
          {renderOptions.map((opt) => (
            <Option key={opt}>{opt}</Option>
          ))}
        </Select>
         {/* <Input id="region" name="region" type="text" placeholder="Province or State" /> */}
      </Form.Item>
      <Form.Item label="City" name="city">
        <Input id="city" type="text" name="city" placeholder="City" />
      </Form.Item>

      {updatePassword && <div className={`shadow-ant p-3 mb-3 ${passwordError?"!border !border-red-500":"border"}`}>
     
        <Form.Item label="Update Password" name="password" onChange={(e) => {setPassword(e.target.value); }}>
          <Input id="password" name="password" type="password" placeholder="Password" />
        </Form.Item>
        <Form.Item name="password_confirm" onChange={(e) => { setConfirmPassword(e.target.value); }}>
          <Input id="password_confirm" name="password_confirm" type="password" placeholder="Password Confirm" />
        </Form.Item>
        <div className="text-sm mb-2">
          Password must be at least 8 characters, have at least 1 symbol, 1 uppercase letter, 1 lowercase letter and one number.
        </div>

        { passwordError && <div className="text-red-500">
            {passwordError}
        </div> }
      </div>}

    </>
  );
};

export default ProfileScreen;
