import clinicTypes from "./clinicTypes";

export const clearClinicStore = () => ({
  type: clinicTypes.CLEAR_CLINIC_STORE,
});

export const getClinic = (clinicId) => ({
  type: clinicTypes.GET_CLINIC_REQUEST,
  payload: clinicId,
});
export const getTempClinic = (clinicId) => ({
  type: clinicTypes.GET_TEMP_CLINIC_REQUEST,
  payload: clinicId,
});

export const getClinicUsers = (clinicId) => ({
  type: clinicTypes.GET_CLINIC_USERS_REQUEST,
  payload: clinicId,
});

export const addClinic = (clinicInfo) => ({
  type: clinicTypes.ADD_CLINIC_REQUEST,
  payload: clinicInfo,
});

export const updateClinic = (clinicInfo) => ({
  type: clinicTypes.UPDATE_CLINIC_REQUEST,
  payload: clinicInfo,
});

export const getClinics = () => ({
  type: clinicTypes.GET_CLINICS_REQUEST,
});

export const getClinicInvites = () => ({
  type: clinicTypes.GET_CLINIC_INVITES_REQUEST,
});
export const acceptClinicInvite = (id) => ({
  type: clinicTypes.ACCEPT_CLINIC_INVITE_REQUEST,
  payload: id
});

export const declineClinicInvite = (id) => ({
  type: clinicTypes.DECLINE_CLINIC_INVITE_REQUEST,
  payload: id
});

export const inviteClinicUser = (clinicId, inviteeEmail, inviterUserId ) => ({
  type: clinicTypes.INVITE_CLINIC_USER_REQUEST,
  payload: { clinicId, inviteeEmail, inviterUserId },
});

export const removeClinicUser = (clinicId, userId) => ({
  type: clinicTypes.REMOVE_CLINIC_USER_REQUEST,
  payload: { clinicId, userId },
});

export const removeClinic = (clinicId) => ({
  type: clinicTypes.REMOVE_CLINIC_REQUEST,
  payload: clinicId,
});

export const validateDomain = (clinic_id, domain, subdomain) => ({
  type: clinicTypes.VALIDATE_DOMAIN_REQUEST,
  payload: {clinic_id, domain, subdomain}
});

export const clearValidateDomain = () => ({
  type: clinicTypes.VALIDATE_DOMAIN_CLEAR
});