import React from "react";
import { Alert } from "react-bootstrap";
import { BsFillExclamationCircleFill as ExclamationIcon } from "react-icons/bs";
import PropTypes from "prop-types";

const FormAlert = (props) => {
  return (
    <Alert {...props} variant={props.variant || "danger"} dismissable='true'>
      {props.icon || <ExclamationIcon />} {props.children}
    </Alert>
  );
};

FormAlert.propTypes = {
  variant: PropTypes.string,
};

export default FormAlert;
