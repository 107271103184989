import React from "react";
import PropTypes from "prop-types";
import "./sub-header.css";
import { Row, Col, Breadcrumb, Button, Space } from "antd";

import history from "../../utils/history";

const TitleBar = ({ titleText, buttons, paths, classes, icon }) => {

  const handleBreadCrumbLinkClick = (e, path) => {
    e.preventDefault()
    history.push(path)
  }

  if (!classes) {
    classes = {
      title: ""
    };
  }

  
  if (icon && typeof icon === 'string' && icon.match(/icon-/)) {
    icon = `icon ${icon}`;
  } 

  return (
    <div id="SubHeader" className={`sub-header pd-30 pd-l-0 pd-r-0 pd-t-0`}>
      <Row>
        <Col xs={24} md={24} lg={24}>
          { paths && 
          <Breadcrumb>
            {paths.map((it, i) => {
              if (it.path) {
                return <Breadcrumb.Item className='prev-breadcrumb-links' key={i} href={it.path} onClick={(e) => handleBreadCrumbLinkClick(e, it.path)}>{it.title}</Breadcrumb.Item>
              }
              if (i === 0) {
                return <Breadcrumb.Item className='root-breadcumb-link prev-breadcrumb-links' key={i}>{it.title}</Breadcrumb.Item>
              } else if (i === paths.length - 1) {
                return <Breadcrumb.Item key={i}>{it.title}</Breadcrumb.Item>
              }

              return <Breadcrumb.Item className='prev-breadcrumb-links' key={i}>{it.title}</Breadcrumb.Item>
            })}
          </Breadcrumb> }
        </Col>
        </Row>
      <Row>
        <Col xs={24} md={24} lg={buttons && buttons.length?12:24}>
        <h1 className={`welcome-text ${classes.title}`}>
            {typeof icon === 'string'?<i className={`${icon} lg:!text-4.5xl ${classes.title} mr-2`}><span className="path1 lg:!text-4.5xl"></span><span className={`path2 lg:!text-4.5xl ${classes.title}`}></span></i>:<>{icon} </>}
            {titleText}
          </h1>
        </Col>
        {buttons && buttons.length ? (
          <Col xs={24} md={24} lg={12} className='flex justify-flex-end'>
            <Space>
              {buttons && buttons.map((button) => (
                <Button key={button.key} type={button.type} onClick={button.onClick} loading={button.loading} icon={button.icon} disabled={button.disabled}>
                  {button.title}
                </Button>
              ))}
            </Space>
          </Col>
        ) : null}
      </Row>
    </div >
  );
};

TitleBar.propTypes = {
  titleIcon: PropTypes.element,
  customButton: PropTypes.element,
  titleText: PropTypes.string.isRequired,
  buttons: PropTypes.array,
};

export default TitleBar;
