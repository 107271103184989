import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import "./subscriptionItemCard.css";
import { DetailList, OptionRow, ConfirmModal } from "..";
import { removeSubscriptionItem, getPlans } from "../../stores/subscriptions";
import { connect } from "react-redux";
import { useEffect } from "react";

const SubscriptionItemCard = ({
  item,
  plans,
  showOptions,
  removeSubscriptionItem,
  ...props
}) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  useEffect(() => {
    if (!plans) getPlans();
    //eslint-disable-next-line
  }, []);

  if (!plans || !plans.some((x) => x.id === item.plan_id)) return null;

  const plan = plans.find((x) => x.id === item.plan_id);
  return (
    <Card
      {...props}
      bg='primary'
      className={`subscriptionItemCard m-2 flex-grow-1`}
    >
      <ConfirmModal
        show={showDeleteConfirm}
        title='Are you sure?'
        text={`Are you sure you want to delete this subscription plan: ${plan.name}?`}
        onHide={() => setShowDeleteConfirm(false)}
        onConfirm={() => {
          removeSubscriptionItem();
          setShowDeleteConfirm(false);
        }}
      />
      <Card.Header className='d-flex flex-row align-content-center text-white'>
        <Card.Title className='align-self-center m-0'>{plan.name}</Card.Title>
        <div className='ml-auto'>
          <OptionRow
            buttons={[
              {
                key: "edit",
                title: "Edit plan",
                onClick: () => console.log("Edit plan: " + plan.id),
              },
              {
                key: "delete",
                title: "Remove plan",
                onClick: () => setShowDeleteConfirm(true),
              },
            ]}
          />
        </div>
      </Card.Header>
      <Card.Body className='bg-white p-2 d-flex flex-column'>
        <p>
          Pellentesque eu tincidunt tortor aliquam nulla facilisi cras fermentum
          odio. Id aliquet lectus proin nibh nisl condimentum id venenatis.
        </p>
        <DetailList
          details={[
            { text: "attribute 1" },
            { text: "attribute 2" },
            { text: "attribute 3" },
            { text: "attribute 4" },
          ]}
        />
        <h4 className='align-self-end'>
          ${plan.amount / 100} / {plan.interval}
        </h4>
      </Card.Body>
    </Card>
  );
};

SubscriptionItemCard.propTypes = {
  item: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  plans: state.subscriptions.plans,
});

const mapDispatchToProps = {
  removeSubscriptionItem,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionItemCard);
