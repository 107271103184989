import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import "./siteCard.css";

import history from "../../utils/history";
import { removeSite } from "../../stores/sites";

import { DetailCard } from "..";
import { Modal } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const { confirm } = Modal;

const SiteCard = ({ 
    site, 
    removeSite,
    ...props
 }) => {
    const launchSite = (e) => {
        e.preventDefault();
        e.stopPropagation()
        console.log(`Launch site id = ${site.id}`);
        window.open(`https://cs.vetonfarm.com`);
    };

    const editSite = (e) => {
        e.preventDefault();
        e.stopPropagation()
        history.push(`/site/${site.id}/edit`)
    }

    const showConfirmDelete = (e) => {
        e.preventDefault();
        e.stopPropagation()
        confirm({
            title: 'Are you sure?',
            content: `Are you sure you want to delete this site: ${site.name}`,
            onOk() {
                removeSite(site.id);
                history.push("/");
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    let created_at = site.created_at.substring(0, site.created_at.length - 17)

    return (
        <DetailCard
            title={site.name}
            icon='icon-compass_calibration'
            onClick={() => history.push(`/site/${site.id}`)}
            hoverable={true}
            classes={
                {title: '!text-red-800', card: '!border-red-800 border-t-0 border-b-0 border-r-0 border-l-4'}
            }
            extra={
                <>
                    <i className={`icon icon-play_arrow cursor-pointer`} onClick={(e) => launchSite(e)}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                    </i>

                    <i className={`icon icon-edit cursor-pointer`} onClick={(e) => editSite(e)}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                    </i>

                    <i className={`icon icon-delete cursor-pointer`} onClick={(e) => showConfirmDelete(e)}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                    </i>
                </>
            }
            details={[
                {
                    icon: <FontAwesomeIcon icon="fa-regular fa-file-lines" className="!text-black !mr-2" />,
                    text: site.description
                },
                {
                    icon: <FontAwesomeIcon icon="fa-regular fa-location-dot" className="!text-black !mr-2" />,
                    text: [
                        site.address,
                        site.city,
                        site.region,
                        site.country,
                        site.postal_code,
                    ]
                        .filter((x) => x)
                        .join(", "),
                },
                {
                    icon: <FontAwesomeIcon icon="fa-regular fa-calendar" className="!text-black !mr-2" />,
                    text: `Creation date: ${created_at}`
                }
            ]}
            {...props}
        />
    );
};

SiteCard.propTypes = {
    site: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});
  
  const mapDispatchToProps = {
    removeSite
  };
export default connect(mapStateToProps, mapDispatchToProps)(SiteCard);
