import Joi from "@hapi/joi";

const ClinicSchema = Joi.object({
  id: Joi.string().messages({
    "string.base": `Clinic Id should be a text string.`,
  }),
  billing_entity_id: Joi.any().when("$edit", {
    is: Joi.boolean().valid(true).required(),
    then: Joi.string(),
    otherwise: Joi.string().required().messages({
      "string.base": `Billing Entity Id should be a text string.`,
      "string.empty": `Billing Entity Id cannot be an empty field`,
      "boolean.required": "$edit flag must be set in validation.",
      "any.required": `Billing Entity Id is a required field`,
      "any.unknown": "Billing Entity Id cannot be edited.",
    }),
  }),
  plan_id: Joi.any().when("$edit", {
    is: Joi.boolean().valid(true).required(),
    then: Joi.string(),
    otherwise: Joi.string().required().messages({
      "string.base": `Plan Id should be a text string.`,
      "string.empty": `You must select a plan to continue.`,
      "boolean.required": "$edit flag must be set in validation.",
      "any.required": `You must select a plan to continue.`,
    }),
  }),
  name: Joi.any()
    .when("$edit", {
      is: Joi.boolean().valid(true).required(),
      then: Joi.string(),
      otherwise: Joi.string().required(),
    })
    .messages({
      "string.base": `Clinic Name should be a text string.`,
      "string.empty": `Clinic Name cannot be an empty field`,
      "boolean.required": "$edit flag must be set in validation.",
      "any.required": `Clinic Name is a required field`,
    }),
  description: Joi.string().allow(""),
  domain: Joi.string().pattern(/^https?:\/\/((?:[a-z\d](?:[a-z\d-]{0,63}[a-z\d])?|\*)\.)+[a-z\d][a-z\d-]{0,63}[a-z\d]$/).allow("").messages({
    "string.pattern.base":"Domain must be valid to continue"
  }),
  subdomain: Joi.string().min(3).allow("").messages({
   
  }),
  email: Joi.any().allow("")
  .when("$edit", {
    is: Joi.boolean().valid(true).required(),
    then: Joi.string(),
    otherwise: Joi.string().required(),
  })
  .messages({
    "string.base": `Email should be a text string.`,
    "string.empty": `Email cannot be an empty field`,
    "any.required": `Email is a required field`,
  }),
  phone: Joi.string().allow("")
  .replace(/\D/g, "")
  .min(0)
  .max(11)
  .when("$edit", {
    is: Joi.boolean().valid(true).required(),
    then: Joi.any(),
    otherwise: Joi.required(),
  })
  .messages({
    "string.base": `Phone Number should be a text string.`,
    "string.empty": `Phone Number cannot be an empty field`,
    "string.min": `Please enter a valid Phone Number.`,
    "string.max": `Please enter a valid Phone Number.`,
    "any.required": `Phone Number is a required field`,
  }),
  address: Joi.string().allow("", null).messages({
    "string.base": `Address should be a text string.`,
    "string.empty": `Address cannot be an empty field`,
    "any.required": `Address is a required field`,
  }),
  city: Joi.string().allow("", null).messages({
    "string.base": `City should be a text string.`,
    "string.empty": `City cannot be an empty field`,
    "any.required": `City is a required field`,
  }),
  region: Joi.string().allow("", null).messages({
    "string.base": `Postal Code should be a text string.`,
    "string.empty": `Postal Code cannot be an empty field`,
    "any.required": `Postal Code is a required field`,
  }),
  country: Joi.string().allow("", null).messages({
    "string.base": `Country should be a text string.`,
    "string.empty": `Country cannot be an empty field`,
    "any.required": `Country is a required field`,
  }),
  postal_code: Joi.string().allow("", null).messages({
    "string.base": `Postal Code should be a text string.`,
    "string.empty": `Postal Code cannot be an empty field`,
    "object.regex": `Please enter a valid Postal Code.`,
    "any.required": `Postal Code is a required field`,
  }),
  code: Joi.string().allow("", null).messages({
    "string.base": `Code should be a text string.`,
  }),
});

export default ClinicSchema;
