import billingEntityTypes from "./billingEntityTypes";

export const clearBillingEntityStore = () => ({
  type: billingEntityTypes.CLEAR_CUSTOMER_STORE,
});

export const getBillingEntity = (billingEntityId) => ({
  type: billingEntityTypes.GET_CUSTOMER_REQUEST,
  payload: billingEntityId,
});

export const getBillingEntityUsers = (billingEntityId) => ({
  type: billingEntityTypes.GET_CUSTOMER_USERS_REQUEST,
  payload: billingEntityId,
});

export const addBillingEntity = (billingEntityInfo) => ({
  type: billingEntityTypes.ADD_CUSTOMER_REQUEST,
  payload: billingEntityInfo,
});

export const updateBillingEntity = (billingEntityInfo) => ({
  type: billingEntityTypes.UPDATE_CUSTOMER_REQUEST,
  payload: billingEntityInfo,
});

export const getBillingEntities = (per_page, force = false) => ({
  type: billingEntityTypes.GET_CUSTOMERS_REQUEST,
  payload: {
    per_page,
    force
  }
});

export const inviteBillingEntityUser = (billingEntityId, userId) => ({
  type: billingEntityTypes.INVITE_CUSTOMER_USER_REQUEST,
  payload: { billingEntityId, userId },
});

export const removeBillingEntityUser = (billingEntityId, userId) => ({
  type: billingEntityTypes.REMOVE_CUSTOMER_USER_REQUEST,
  payload: { billingEntityId, userId },
});

export const removeBillingEntity = (billingEntityId) => ({
  type: billingEntityTypes.REMOVE_CUSTOMER_REQUEST,
  payload: billingEntityId,
});

export const getCreditCard = (cardId) => ({
  type: billingEntityTypes.GET_CREDIT_CARD_REQUEST,
  payload: cardId,
});

export const getCreditCards = () => ({
  type: billingEntityTypes.GET_CREDIT_CARDS_REQUEST,
});

export const addCreditCard = (cardInfo) => ({
  type: billingEntityTypes.ADD_CREDIT_CARD_REQUEST,
  payload: cardInfo,
});

export const updateCreditCard = (cardInfo) => ({
  type: billingEntityTypes.UPDATE_CREDIT_CARD_REQUEST,
  payload: cardInfo,
});

export const removeCreditCard = (cardId) => ({
  type: billingEntityTypes.REMOVE_CREDIT_CARD_REQUEST,
  payload: cardId,
});

export const getBillingEntityCard = (billingEntityId) => ({
  type: billingEntityTypes.GET_CUSTOMER_CARD_REQUEST,
  payload: billingEntityId,
});
