import userTypes from "./userTypes";

export const updatePassword = (password, onSuccess) => ({
  type: userTypes.UPDATE_PASSWORD_REQUEST,
  payload: {
    password, 
    onSuccess
  }
});

export const clearUserStore = () => ({
  type: userTypes.CLEAR_USER_STORE,
});
export const clearPasswordUpdated = () => ({
  type: userTypes.CLEAR_PASSWORD_UPDATED,
});

export const loadUser = () => ({
  type: userTypes.LOAD_USER_REQUEST,
});

export const updateUser = (userInfo, successCallback) => ({
  type: userTypes.UPDATE_USER_REQUEST,
  payload: {
    user: userInfo,
    successCallback
  },
});
