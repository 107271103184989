import clinicTypes from "./clinicTypes";

const initialState = {
  clinics: null,
  clinicUsers: null,
  loading: false,
  clinicInvites: null,
  invitesLoading: false,
  validate_domain: null,
  tempClinic: [] //Grab clinic data temporarily without storing it in the above array
};

export default (state = initialState, action) => {
  switch (action.type) {
    case clinicTypes.ADD_CLINIC_REQUEST:
    case clinicTypes.GET_CLINIC_REQUEST:
    case clinicTypes.GET_CLINIC_USERS_REQUEST:
    case clinicTypes.GET_CLINICS_REQUEST:
    case clinicTypes.INVITE_CLINIC_USER_REQUEST:
    case clinicTypes.REMOVE_CLINIC_USER_REQUEST:
    case clinicTypes.UPDATE_CLINIC_REQUEST:
    case clinicTypes.REMOVE_CLINIC_REQUEST:
    case clinicTypes.VALIDATE_DOMAIN_REQUEST:
    case clinicTypes.DECLINE_CLINIC_INVITE_REQUEST:
    case clinicTypes.ACCEPT_CLINIC_INVITE_REQUEST:
    case clinicTypes.GET_TEMP_CLINIC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case clinicTypes.VALIDATE_DOMAIN_FAILURE:
    case clinicTypes.VALIDATE_DOMAIN_SUCCESS:
        return {
          ...state,
          validate_domain: action.payload,
          loading: false
        };
    case clinicTypes.VALIDATE_DOMAIN_CLEAR:
      return {
        ...state,
        validate_domain: null,
        loading: false
      }

    case clinicTypes.ADD_CLINIC_SUCCESS:
    case clinicTypes.GET_CLINIC_SUCCESS:
    case clinicTypes.UPDATE_CLINIC_SUCCESS:
      return {
        ...state,
        clinics: { ...state.clinics, [action.payload.id]: action.payload },
        loading: false,
      };
    case clinicTypes.GET_TEMP_CLINIC_SUCCESS: 
      return {
        ...state,
        tempClinic: {...state.tempClinic, [action.payload.id]: action.payload },
        loading: false
      }

    case clinicTypes.GET_CLINIC_USERS_SUCCESS:
      return {
        ...state,
        clinicUsers: {
          ...state.clinicUsers,
          [action.clinic_id]: action.payload.reduce((obj, user) => {
            obj[user.id] = user;
            return obj;
          }, {}),
        },
        loading: false,
      };

    case clinicTypes.GET_CLINICS_SUCCESS:
      return {
        ...state,
        clinics: action.payload.reduce((obj, clinic) => {
          obj[clinic.id] = clinic;
          return obj;
        }, {}),
        loading: false,
      };
    case clinicTypes.GET_CLINIC_INVITES_REQUEST:
      return {
        ...state,
        clinicInvites: action.payload,
        invitesLoading: false,
      };
      case clinicTypes.GET_CLINIC_INVITES_SUCCESS:
        return {
          ...state,
          clinicInvites: action.payload,
          invitesLoading: false
        };
    case clinicTypes.GET_CLINIC_USERS_FAILURE:
      return {
        ...state,
        clinicUsers: null,
        loading: false,
      };
      case clinicTypes.GET_CLINIC_INVITES_FAILURE:
        return {
          ...state,
          invitesLoading: false
        }

    case clinicTypes.GET_CLINICS_FAILURE:
      return {
        ...state,
        clinics: null,
        loading: false,
      };

    case clinicTypes.INVITE_CLINIC_USER_SUCCESS:
      if (state.clinicUsers[action.clinic_id]) {
        const { [action.clinic_id]: users, ...rest } = state.clinicUsers;
        return {
          ...state,
          clinicUsers: {
            ...rest,
            [action.clinic_id]: {
              ...users,
              [action.payload.userId]: action.payload,
            },
          },
          loading: false,
        };
      } else
        return {
          ...state,
          clinicUsers: {
            ...state.clinicUsers,
            [action.clinic_id]: { [action.payload.userId]: action.payload },
          },
          loading: false,
        };

    case clinicTypes.REMOVE_CLINIC_USER_SUCCESS:
      if (state.clinicUsers[action.clinic_id]) {
        const { [action.clinic_id]: users, ...rest } = state.clinicUsers;
        const { [action.payload]: _, ...userRemoved } = users;
        return {
          ...state,
          clinicUsers: { ...rest, [action.clinic_id]: userRemoved },
          loading: false,
        };
      } else return { ...state, loading: false };

    case clinicTypes.REMOVE_CLINIC_SUCCESS:
      if (state.clinics[action.payload]) {
        const { [action.payload]: _, ...clinicRemoved } = state.clinics;
        return {
          ...state,
          clinics: clinicRemoved,
          loading: false,
        };
      } else return { ...state, loading: false };

    case clinicTypes.REMOVE_CLINIC_FAILURE:
    case clinicTypes.UPDATE_CLINIC_FAILURE:
    case clinicTypes.INVITE_CLINIC_USER_FAILURE:
    case clinicTypes.REMOVE_CLINIC_USER_FAILURE:
    case clinicTypes.GET_CLINIC_FAILURE:
    case clinicTypes.GET_TEMP_CLINIC_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case clinicTypes.DECLINE_CLINIC_INVITE_SUCCESS:
    case clinicTypes.DECLINE_CLINIC_INVITE_FAILURE:
    case clinicTypes.ACCEPT_CLINIC_INVITE_SUCCESS:
    case clinicTypes.ACCEPT_CLINIC_INVITE_FAILURE:
      console.log(state, action);
    return {
      ...state,
      loading: false,
      clinicInvites: 
        state.clinicInvites.map((invite)=>{
          if (invite.id === action.payload.id)
            return action.payload;
          return invite;
        })
    };

    case clinicTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case "CLEAR_ALL":
    case clinicTypes.CLEAR_CLINIC_STORE:
      return initialState;

    default:
      return state;
  }
};
