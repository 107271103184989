export default {
  SET_LOADING: "SITE_SET_LOADING",
  CLEAR_SITE_STORE: "CLEAR_SITE_STORE",
  GET_SITE_REQUEST: "GET_SITE_REQUEST",
  GET_SITE_SUCCESS: "GET_SITE_SUCCESS",
  GET_SITE_FAILURE: "GET_SITE_FAILURE",
  ADD_SITE_REQUEST: "ADD_SITE_REQUEST",
  ADD_SITE_SUCCESS: "ADD_SITE_SUCCESS",
  ADD_SITE_FAILURE: "ADD_SITE_FAILURE",
  UPDATE_SITE_REQUEST: "UPDATE_SITE_REQUEST",
  UPDATE_SITE_SUCCESS: "UPDATE_SITE_SUCCESS",
  UPDATE_SITE_FAILURE: "UPDATE_SITE_FAILURE",
  GET_SITE_USERS_REQUEST: "GET_SITE_USERS_REQUEST",
  GET_SITE_USERS_SUCCESS: "GET_SITE_USERS_SUCCESS",
  GET_SITE_USERS_FAILURE: "GET_SITE_USERS_FAILURE",
  GET_SITES_REQUEST: "GET_SITES_REQUEST",
  GET_SITES_SUCCESS: "GET_SITES_SUCCESS",
  GET_SITES_FAILURE: "GET_SITES_FAILURE",
  INVITE_SITE_USER_REQUEST: "INVITE_SITE_USER_REQUEST",
  INVITE_SITE_USER_SUCCESS: "INVITE_SITE_USER_SUCCESS",
  INVITE_SITE_USER_FAILURE: "INVITE_SITE_USER_FAILURE",
  REMOVE_SITE_USER_REQUEST: "REMOVE_SITE_USER_REQUEST",
  REMOVE_SITE_USER_SUCCESS: "REMOVE_SITE_USER_SUCCESS",
  REMOVE_SITE_USER_FAILURE: "REMOVE_SITE_USER_FAILURE",
  REMOVE_SITE_REQUEST: "REMOVE_SITE_REQUEST",
  REMOVE_SITE_SUCCESS: "REMOVE_SITE_SUCCESS",
  REMOVE_SITE_FAILURE: "REMOVE_SITE_FAILURE",
  GET_SITE_INVITES_REQUEST: "GET_SITE_INVITES_REQUEST",
  GET_SITE_INVITES_SUCCESS: "GET_SITE_INVITES_SUCCESS",
  GET_SITE_INVITES_FAILURE: "GET_SITE_INVITES_FAILURE",
  ACCEPT_SITE_INVITE_REQUEST: "ACCEPT_SITE_INVITE_REQUEST",
  ACCEPT_SITE_INVITE_SUCCESS: "ACCEPT_SITE_INVITE_SUCCESS",
  ACCEPT_SITE_INVITE_FAILURE: "ACCEPT_SITE_INVITE_FAILURE",
  DECLINE_SITE_INVITE_REQUEST: "DECLINE_SITE_INVITE_REQUEST",
  DECLINE_SITE_INVITE_SUCCESS: "DECLINE_SITE_INVITE_SUCCESS",
  DECLINE_SITE_INVITE_FAILURE: "DECLINE_SITE_INVITE_FAILURE",

};
