import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Button } from "antd";
import "./login.css";
import { Input, ToastAlert, Logo } from "../../components";
import { setAlert, clearAlert } from "../../stores/alerts";
import { login, authenticate } from "../../stores/auth";
import history from "../../utils/history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LoginScreen = ({
  authenticate,
  authenticated,
  alert,
  loading,
  login,
  clearAlert,
}) => {
  const [form] = Form.useForm();
  const [isValidForm, setValidForm] = useState(false);

  useEffect(() => {
    clearAlert();
    authenticate();
    if (authenticated) history.push("/");
    //eslint-disable-next-line
  }, [authenticated]);

  useEffect(() => {
    if (alert && alert.message === 'Email is not confirmed') {
      setValidForm(false);
    } 
    if (alert && alert.message === 'Wrong email or password') {
      setValidForm(false);
    }
    //eslint-disable-next-line
  }, [alert]);

  useEffect(() => onFormValuesChange());
  
  const onFormValuesChange = () => {

    const formHasErrors = !!form.getFieldsError().filter(({ errors }) => errors.length).length;
    if (!formHasErrors) {
      return setValidForm(true);
    }
    setValidForm(false);
  }

  const onSubmit = (values) => {
    const { email, password } = values;

    clearAlert();
    login(email, password);
  };

  const handleGoToSignUp = (e) => {
    e.preventDefault()
    history.push('/register')
  }

  const handleGoToReset = (e) => {
    e.preventDefault()
    history.push('/reset')
  }

  return (
    <div className='registerContainer'>
      <ToastAlert />
      <div className='registerMainCard '>
        <div className='registerFormCard pb-20'>
        <Logo className='logoContainer' />
        <h1 className="text-2xl">Log into account</h1>
          {alert 
            && (alert.message === 'Wrong email or password' ||  alert.message === 'Email is not confirmed')
          }
          <Form
            form={form}
            onFinish={onSubmit}
            onFieldsChange={onFormValuesChange}
            layout='vertical'
            style={{ width: "400px" }}
            initialValues={{ remember: true }}
            className="sign-in-form"
          >
            <Form.Item
              name='email'
              label='Email address'
              rules={[{ required: true, message: 'Please input your email address!' }]}
              required
            >
              <Input
                id='email'
                type='text'
                name='email'
                placeholder='your.email@example.com'
              />
            </Form.Item>
            <Form.Item
              name='password'
              label='Password'
              rules={[{ required: true, message: 'Please input your password!' }]}
              required
            >
              <Input
                id='password'
                type='password'
                name='password'
                placeholder='Password'
              />
            </Form.Item>
       
            <Button 
              type="primary" 
              htmlType="submit" 
              block size="large" 
              disabled={!isValidForm}
            >
             <FontAwesomeIcon icon="fa-solid fa-right-to-bracket" /> &nbsp; Log In
            </Button>
            <div className="mt-5 text-center">
              Don't have an account? <a href="/register" onClick={handleGoToSignUp}>Create an Account</a><br />
              <a className="form-forgot-link" href="/reset" onClick={handleGoToReset}>Forget your password?</a>
            </div>
          </Form>
        </div>
        {/* <div className='loginImageCard'>
          <img src={loginImage} className='loginImage' alt='' />
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alerts.alert,
  authenticated: state.auth.authenticated,
  loading: state.auth.loading,
});

const mapDispatchToProps = {
  authenticate,
  login,
  setAlert,
  clearAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
