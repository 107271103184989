import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Input } from "..";
import { BsCheck } from "react-icons/bs";
import { setAlert } from "../../stores/alerts";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const RegisterConfirmModal = ({ setAlert, ...props }) => {
  const [code, setCode] = useState("");

  const onChange = (e) => {
    setCode(e.target.value);
  };

  const onConfirm = () => {
    if (code.length === 0) setAlert("Please enter a confirmation code.");
  };

  return (
    <Modal
      {...props}
      // Animation uses a recently-depreciated method - setting to false avoids console errors for now
      animation={false}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Check Your Email
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          We have sent a confirmation code to your email. Please enter the code
          below.
        </p>
        <Input
          id='confirmationCode'
          type='text'
          name='confirmationCode'
          placeholder='Confirmation Code'
          value={code}
          onChange={onChange}
          icon={<BsCheck />}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onConfirm}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
};

RegisterConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  //onHide: PropTypes.func,
};

const mapDispatchToProps = {
  setAlert,
};

export default connect(null, mapDispatchToProps)(RegisterConfirmModal);
