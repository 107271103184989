import subscriptionTypes from "./subscriptionTypes";
import { takeLatest, all } from "redux-saga/effects";
import {
  getPlans,
  getPlan,
  getSubscription,
  getSubscriptions,
  getSubscriptionItem,
  getSubscriptionItems,
  addSubscriptionItem,
  updateSubscriptionItem,
  deleteSubscriptionItem,
} from "./subscriptionActions";

export default function* subscriptionSagas() {
  yield all([
    yield takeLatest(subscriptionTypes.GET_PLANS_REQUEST, getPlans),
    yield takeLatest(subscriptionTypes.GET_PLAN_REQUEST, getPlan),
    yield takeLatest(
      subscriptionTypes.GET_SUBSCRIPTION_REQUEST,
      getSubscription
    ),
    yield takeLatest(
      subscriptionTypes.GET_SUBSCRIPTIONS_REQUEST,
      getSubscriptions
    ),
    yield takeLatest(
      subscriptionTypes.GET_SUBSCRIPTION_ITEM_REQUEST,
      getSubscriptionItem
    ),
    yield takeLatest(
      subscriptionTypes.GET_SUBSCRIPTION_ITEMS_REQUEST,
      getSubscriptionItems
    ),
    yield takeLatest(
      subscriptionTypes.ADD_SUBSCRIPTION_ITEM_REQUEST,
      addSubscriptionItem
    ),
    yield takeLatest(
      subscriptionTypes.UPDATE_SUBSCRIPTION_ITEM_REQUEST,
      updateSubscriptionItem
    ),
    yield takeLatest(
      subscriptionTypes.REMOVE_SUBSCRIPTION_ITEM_REQUEST,
      deleteSubscriptionItem
    ),
  ]);
}
