import React from "react";
import "./optionRow.css";
import { IconButton } from "..";

const OptionRow = ({ buttons, variant, ...props }) => {
  return (
    <div
      className={`optionRow d-flex flex-row justify-content-center align-items-center text-${
        variant || "dark"
      }`}
      {...props}
    >
      {buttons.map((button) => (
        <IconButton variant={button.key} {...button} />
      ))}
    </div>
  );
};

export default OptionRow;
