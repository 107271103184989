import React from "react";
import "./logo.css";
import chuteSidelogo from "../../assets/chuteside-logo.png";
import smallChuteSideLogo from "../../assets/chuteside-logo-sm.png";
import herdRxlogo from "../../assets/herdrx-logo.png";
import smallHerdRxlogo from "../../assets/herdrx-logo-sm.png";
import { isClinic, isSite } from '../../config/app'

const getLogo = () => {
  return isClinic() ? herdRxlogo : chuteSidelogo
};

const getLogoSm = () => {
  return isClinic() ? smallHerdRxlogo : smallChuteSideLogo
};

const Logo = ({ className, type }) => {
  return (
    <div className={className}>
      <img className={className} alt="logo" src={type === 'sm' ? getLogoSm() : getLogo()} />
    </div>
  );
};

export default Logo;
