import React, { useEffect, useState } from "react";
import "./clinicUserList.css";
import { InviteUser } from "..";
import PropTypes from "prop-types";
import {
  getClinicUsers,
  removeClinicUser,
  inviteClinicUser,
} from "../../stores/clinics";
import { connect } from "react-redux";
import { Table, Button, Modal } from "antd";

const { confirm } = Modal;

const ClinicUserList = ({
  clinicId,
  clinicUsers,
  getClinicUsers,
  inviteClinicUser,
  removeClinicUser,
  loading,
  profile
}) => {
  const [users, setUsers] = useState([])
  useEffect(() => {
    setUsers(null);
    getClinicUsers(clinicId);
    //eslint-disable-next-line
  }, [clinicId]);

  useEffect(() => {
    if (clinicUsers && clinicUsers[clinicId]) {
      setUsers(Object.values(clinicUsers[clinicId]))
    }
  }, [clinicUsers, clinicId]);

  const showConfirm = (user) => {
    confirm({
      title: 'Are you sure?',
      content: `Are you sure you want to remove ${user.first_name} ${user.last_name} from this clinic?`,
      onOk() {
        removeClinicUser(clinicId, user.id);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const onInvite = (email) => {
    console.log(clinicId +' - '+ email + ' - '+ profile.id);
    inviteClinicUser(clinicId, email, profile.id);
  };
  
  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (user) => (
        <span>{user?.first_name} {user?.last_name}</span >
      ),
      width: '30%'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '30%'
    },
    {
      title: 'Role',
      key: 'role',
      render: (user) => (
        <span>{["Admin", "User"][parseInt(`0x${user.id[0]}`) % 2]}</span>
      ),
      width: '20%'
    },
    {
      title: 'Remove',
      key: 'remove',
      render: (user) => (
        <Button type='danger' onClick={() => showConfirm(user)}>Remove</Button>
      ),
      width: '10%'
    }
  ];

  return (
    <div className='userListContainer w-100'>
      <InviteUser onInvite={onInvite} />
      <Table
        loading={!users}
        dataSource={users}
        columns={columns}
        pagination={{ pageSize: 5 }}
        rowKey="id"
      />
    </div>
  );
};

ClinicUserList.propTypes = {
  clinicId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  clinicUsers: state.clinics.clinicUsers,
  loading: state.clinics.loading,
  profile: state.users.profile
});

const mapDispatchToProps = {
  getClinicUsers,
  removeClinicUser,
  inviteClinicUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClinicUserList);
