import userTypes from "./userTypes";
import { takeLatest, all } from "redux-saga/effects";
import { loadUser, updatePassword, updateUser } from "./userActions";

function* loadUserWatcher() {
  yield takeLatest(userTypes.LOAD_USER_REQUEST, loadUser);
}

function* updateUserWatcher() {
  yield takeLatest(userTypes.UPDATE_USER_REQUEST, updateUser);
}
function* updatePasswordWatcher() {
  yield takeLatest(userTypes.UPDATE_PASSWORD_REQUEST, updatePassword);
}

export default function* userSagas() {
  yield all([loadUserWatcher(), updateUserWatcher(), updatePasswordWatcher()]);
}
