import clinicTypes from "./clinicTypes";
import { put, retry } from "redux-saga/effects";
import axios from "axios";
import { setAlert } from "../alerts";
import { authenticate } from "../auth";
import store from "../rootStore";
import setAuthToken from "../../utils/setAuthToken";
import history from "../../utils/history";
import callApi from "../../utils/callApi";
import qs from "querystring";


export function* getClinic(action) {
  yield callApi(
    "get",
    `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/clinics/${action.payload}`,
    "GET_CLINIC",
    "clinic",
    "An error occurred while loading a clinic."
  );
}
export function* getTempClinic(action) {
  yield callApi(
    "get",
    `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/clinics/${action.payload}`,
    "GET_TEMP_CLINIC",
    "clinic",
    "An error occurred while loading a clinic."
  );
}
export function* declineClinicInvite(action) {
  let  id = action.payload;
  yield callApi(
    "put",
    `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/clinic-invites/${id}/decline`,
    "DECLINE_CLINIC_INVITE",
    "clinic_invite",
    "Could not decline invite.",
    null,
    () => {
      store.dispatch(setAlert("Invite Declined", "success",clinicTypes.DECLINE_CLINIC_INVITE_SUCCESS));
    }
  );
}

export function* acceptClinicInvite(action) {
  let  id = action.payload;
  yield callApi(
    "put",
    `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/clinic-invites/${id}/accept`,
    "ACCEPT_CLINIC_INVITE",
    "clinic_invite",
    "Could not accept invite",
    null,
    () => {
      store.dispatch(setAlert("Invite Accepted", "success",clinicTypes.ACCEPT_CLINIC_INVITE_SUCCESS));
    }
  );
}

export function* getClinicUsers(action) {
  if (
    !store.getState().clinics.clinicUsers ||
    !store.getState().clinics.clinicUsers[action.payload]
  ) {
    const config = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };
    store.dispatch(authenticate());
    let error = null;
    try {
      setAuthToken();
      const res = yield retry(5, 2000, axios, {
        method: "get",
        url: `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/clinics/${action.payload}/users`,
        ...config,
      });
      const { success, data } = res.data;
      if (success) {
        yield put({
          type: clinicTypes.GET_CLINIC_USERS_SUCCESS,
          clinic_id: action.payload,
          payload: data.users,
        });
      } else {
        error = "An error occurred while loading users for clinic.";
      }
    } catch (err) {
      console.error(err.message);
      error = "An error occurred while loading users for clinic.";
    }
    if (error) {
      store.dispatch(
        setAlert(error, "danger", clinicTypes.GET_CLINIC_USERS_FAILURE)
      );
      yield put({ type: clinicTypes.GET_CLINIC_USERS_FAILURE, payload: error });
    }
  } else yield put({ type: clinicTypes.SET_LOADING, payload: false });
}

export function* getClinics() {
  if (!store.getState().clinics.clinics)
    yield callApi(
      "get",
      `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/clinics`,
      "GET_CLINICS",
      "clinics",
      "An error occurred while loading clinics."
    );
  else yield put({ type: clinicTypes.SET_LOADING, payload: false });
}
export function* getClinicInvites() {
    yield callApi(
      "get",
      `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/clinic-invites`,
      "GET_CLINIC_INVITES",
      "clinic_invites",
      "An error occurred while loading clinics."
    );
}



export function* inviteClinicUser(action) {
  const { clinicId, inviteeEmail, inviterUserId } = action.payload;
  let endpoint = process.env.REACT_APP_CLINIC_SHIM_ENDPOINT;
  endpoint = endpoint.replace("{{clinic_id}}",clinicId);

  yield callApi(
    "post",
    `${endpoint}/api/v1/clinic-invites`,
    "INVITE_CLINIC_USER",
    "clinic_invite",
    "An error occurred while updating clinic.",
    {
      email: inviteeEmail,
      invited_by: inviterUserId
    },
    (data)=>{
      store.dispatch(setAlert("Invite sent.", "success", `INVITE_CLINIC_USER_SUCCESS`));
    }
  );

}

export function* addClinic(action) {
  yield callApi(
    "post",
    `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/clinics`,
    "ADD_CLINIC",
    "clinic",
    "An error occurred while creating a clinic.",
    action.payload,
    (clinic) => {
      history.push(`/clinic/${clinic.id}`);
    }
  );
}

export function* updateClinic(action) {
  const { id, ...info } = action.payload;
  yield callApi(
    "put",
    `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/clinics/${id}`,
    "UPDATE_CLINIC",
    "clinic",
    "An error occurred while updating clinic.",
    info,
    (clinic) => {
      history.push(`/clinic/${clinic.id}`);
    }
  );

}



// export function* inviteClinicUser(action) {
//   const config = {
//     headers: { "Content-Type": "application/x-www-form-urlencoded" },
//   };
//   let endpoint = process.env.REACT_APP_CLINIC_SHIM_ENDPOINT;
//   endpoint.replace("{{clinic_id}}",clinicId);

//   store.dispatch(authenticate());
//   let error = null;
//   try {
//     setAuthToken();
//     const { clinicId, inviteeEmail, inviterUserId } = action.payload;
//     const inviteRes = yield retry(
//       2,
//       2000,
//       axios.post,
//       `${endpoint}/api/v1/clinic-invites`,
//       config
//     );
//     const { success, data } = inviteRes.data;
//     if (success && data.clinic_user) {
//       let newUser = null;
//       try {
//         const userRes = yield retry(
//           5,
//           2000,
//           axios.get,
//           `${endpoint}/api/v1/clinic-invites`,
//           config
//         );
//         newUser =
//           (userRes &&
//             userRes.data &&
//             userRes.data.data &&
//             userRes.data.data.user) ||
//           null;
//       } catch {
//         // Todo: Handle failure to fetch user
//       }
//       yield put({
//         type: clinicTypes.INVITE_CLINIC_USER_SUCCESS,
//         clinic_id: clinicId,
//         payload: newUser,
//       });
//     } else {
//       error = "An error occurred while inviting user to clinic.";
//     }
//   } catch (err) {
//     console.error(err.message);
//     error = "An error occurred while inviting user to clinic.";
//   }
//   if (error) {
//     store.dispatch(
//       setAlert(error, "danger", clinicTypes.INVITE_CLINIC_USER_FAILURE)
//     );
//     yield put({ type: clinicTypes.INVITE_CLINIC_USER_FAILURE, payload: error });
//   }
// }

export function* removeClinicUser(action) {
  const config = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  store.dispatch(authenticate());
  let error = null;
  try {
    setAuthToken();
    const { clinicId, userId } = action.payload;
    const res = yield retry(
      5,
      2000,
      axios.delete,
      `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/clinics/${clinicId}/users/${userId}`,
      config
    );
    const { success } = res.data;
    if (success) {
      yield put({
        type: clinicTypes.REMOVE_CLINIC_USER_SUCCESS,
        clinic_id: clinicId,
        payload: userId,
      });
    } else error = "An error occurred while removing user.";
  } catch (err) {
    console.error(err.message);
    error = "An error occurred while removing user.";
  }
  if (error) {
    store.dispatch(
      setAlert(error, "danger", clinicTypes.INVITE_CLINIC_USER_FAILURE)
    );
    yield put({ type: clinicTypes.INVITE_CLINIC_USER_FAILURE, payload: error });
  }
}

export function* removeClinic(action) {
  const config = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  store.dispatch(authenticate());
  let error = null;
  try {
    setAuthToken();
    const res = yield retry(
      5,
      2000,
      axios.delete,
      `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/clinics/${action.payload}`,
      config
    );
    const { success } = res.data;
    if (success) {
      yield put({
        type: clinicTypes.REMOVE_CLINIC_SUCCESS,
        payload: action.payload,
      });
    } else error = "An error occurred while deleting a clinic.";
  } catch (err) {
    console.error(err.message);
    error = "An error occurred while deleting a clinic.";
  }
  if (error) {
    store.dispatch(setAlert(error, "danger", clinicTypes.REMOVE_CLINIC_FAILURE));
    yield put({ type: clinicTypes.REMOVE_CLINIC_FAILURE, payload: error });
  }
}

export function *validateDomain(action) {
  
  const body = Object.fromEntries(Object.entries(action.payload).filter(([key,v])=>v!==null));
  const method = "post";
  const url = `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/clinics/validate-domain`;
  const typePrefix = "VALIDATE_DOMAIN";
  const dataProp = "";
  let errorMessage = "tests2";
  const onSuccess = (clinic) => {
     // history.push(`/clinic/${clinic.id}`);
    }
  const callAuthenticate = true;


  const config = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  if (callAuthenticate) store.dispatch(authenticate());
  let error = null;
  try {
    setAuthToken();
    let tries = 5;
    if (method === "post") tries = 1;
    if (method === "put") tries = 2;
    const res = yield retry(tries, 1000, axios, {
      method,
      url,
      data: body && qs.stringify(body),
      ...config,
    });
    let { success, data } = res.data;
    data = { ...body, ...data, success};
    if (success) {
      yield put({
        type: `${typePrefix}_SUCCESS`,
        payload: data,
      });
      if (onSuccess) onSuccess(data && data[dataProp]);
    } else {
      error = true;
      errorMessage = "test";
    }
  } catch (err) {
    console.error(err.message);
    errorMessage = err.response.data.message;
    error = true;
  }
  if (error) {
    store.dispatch(setAlert(errorMessage, "danger", `${typePrefix}_FAILURE`));
    yield put({ type: `${typePrefix}_FAILURE`, payload: errorMessage });
  }

}
