import userTypes from "./userTypes";

const initialState = {
  profile: null,
  loading: false,
  passwordUpdated: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case userTypes.UPDATE_USER_REQUEST:
    case userTypes.UPDATE_PASSWORD_REQUEST:
    case userTypes.LOAD_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case  userTypes.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        passwordUpdated: true
      }
      case  userTypes.UPDATE_PASSWORD_FAILURE:
        return {
          ...state,
          loading: false,
          passwordUpdated: false
      }
      case userTypes.CLEAR_PASSWORD_UPDATED:
        return {
          ...state,
          loading: false,
          passwordUpdated: null
        }

    case userTypes.UPDATE_USER_SUCCESS:
    case userTypes.LOAD_USER_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        loading: false,
      };

    case userTypes.UPDATE_USER_FAILURE:
    case userTypes.LOAD_USER_FAILURE:
      return {
        ...state,
        profile: null,
        loading: false,
      };

    case "CLEAR_ALL":
    case userTypes.CLEAR_USER_STORE:
      return initialState;

    default:
      return state;
  }
};
