import siteTypes from "./siteTypes";

export const clearSiteStore = () => ({
  type: siteTypes.CLEAR_SITE_STORE,
});

export const getSite = (siteId) => ({
  type: siteTypes.GET_SITE_REQUEST,
  payload: siteId,
});

export const getSiteUsers = (siteId) => ({
  type: siteTypes.GET_SITE_USERS_REQUEST,
  payload: siteId,
});

export const addSite = (siteInfo) => ({
  type: siteTypes.ADD_SITE_REQUEST,
  payload: siteInfo,
});

export const updateSite = (siteInfo) => ({
  type: siteTypes.UPDATE_SITE_REQUEST,
  payload: siteInfo,
});

export const getSites = () => ({
  type: siteTypes.GET_SITES_REQUEST,
});
export const getSiteInvites = () => ({
  type: siteTypes.GET_SITE_INVITES_REQUEST,
});

export const acceptSiteInvite = (id) => ({
  type: siteTypes.ACCEPT_SITE_INVITE_REQUEST,
  payload: id
});

export const declineSiteInvite = (id) => ({
  type: siteTypes.DECLINE_SITE_INVITE_REQUEST,
  payload: id
});

export const inviteSiteUser = (siteId, userId) => ({
  type: siteTypes.INVITE_SITE_USER_REQUEST,
  payload: { siteId, userId },
});



export const removeSiteUser = (siteId, userId) => ({
  type: siteTypes.REMOVE_SITE_USER_REQUEST,
  payload: { siteId, userId },
});

export const removeSite = (siteId) => ({
  type: siteTypes.REMOVE_SITE_REQUEST,
  payload: siteId,
});
