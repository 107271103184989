import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Button, Input as AntInput } from "antd";
import "./register.css";
import { Logo, Input } from "../../components";
import { setAlert, clearAlert } from "../../stores/alerts";
import { register, authenticate } from "../../stores/auth";
import history from "../../utils/history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RegisterScreen = ({
  authenticate,
  authenticated,
  loading,
  register,
  clearAlert,
  alert
}) => {
  const [form] = Form.useForm();
  const [isValidForm, setValidForm] = useState(false);

  useEffect(() => {
    clearAlert();
    authenticate();
    if (authenticated) history.push("/");
    //eslint-disable-next-line
  }, [authenticated]);

  useEffect(() => {
    if (alert && alert.message === "User with this email already registered") {
      form.setFields([{
        name: 'email',
        errors: [`Already exist, please enter another email`]
      }]);
    }
    //eslint-disable-next-line
  }, [alert])

  const handleGoToSignIn = (e) => {
    e.preventDefault()
    clearAlert();
    history.push('/login')
  }

  const onFormValuesChange = () => {
    const formHasErrors = !!form.getFieldsError().filter(({ errors }) => errors.length).length;
    if (form.isFieldsTouched(true) && !formHasErrors) {
      return setValidForm(true);
    }
    setValidForm(false);
  }

  const onSubmit = (values) => {
    const { email, password } = values;
    clearAlert();
    register(email, password);
  };

  return (
    <div className='registerContainer'>
      <div className='registerMainCard'>
        <div className='registerFormCard pb-20'>
          <Logo className='logoContainer' />
          <h1 className="text-2xl">Create account</h1>
          <Form
            style={{ width: "400px" }}
            onFinish={onSubmit}
            onFieldsChange={onFormValuesChange}
            layout='vertical'
            form={form}
          >
            <Form.Item
              name='email'
              label='Email address'
              rules={[{ required: true, message: 'Please input your email address!' },
              { pattern: /^\S+@\S+\.\S+$/, message: 'Please enter a valid email' }]}
              required
            >
              <Input
                id='email'
                type='text'
                name='email'
                placeholder='Email Address'
              />
            </Form.Item>
            <Form.Item
              name='password'
              label='Password'
              tooltip="Password is required.
                Minimum 8 letters and numbers. 
                At least 1 special character.
                Lowercase and Uppercase letter"
              required
              rules={[
                { required: true, message: 'Please input your password!' },
                // {
                //   pattern: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$',
                //   message: 'Invalid password'
                // },
                { min: 8, message: 'Password must be at least 8 characters long' },
                { pattern: '[0-9]', message: 'Need at least one number' },
                { pattern: '[a-z]', message: 'Need at least one lowercase letter' },
                { pattern: '[A-Z]', message: 'Need at least one uppercase letter' },
                { pattern: '[@$!%*?&]', message: 'Need at least one symbol' }

              ]}
            >
              <AntInput.Password
                id='password'
                type='password'
                name='password'
                placeholder='Password'
              />
            </Form.Item>
            <Form.Item
              name='confirm'
              label='Confirm Password'
              dependencies={['password']}
              rules={[
                { required: true, message: 'Please input your password!' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('The two passwords that you entered do not match!');
                  }
                })
              ]}
              required
            >
              <AntInput.Password
                id='confirm'
                type='password'
                name='confirm'
                placeholder='Confirm password'
              />
            </Form.Item>
            <Button 
              type="primary" 
              htmlType="submit" 
              block size="large" 
              disabled={!isValidForm}
            >
             <FontAwesomeIcon icon="fa-solid fa-user-plus" /> &nbsp; Create
            </Button>
            <div className='registerAccount mt-5'>
              <span className="text-center">Already have an account? <a href="/login" onClick={handleGoToSignIn}>Log In</a></span>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alerts.alert,
  error: state.auth.error,
  loading: state.auth.loading,
  authenticated: state.auth.authenticated,
});

const mapDispatchToProps = {
  authenticate,
  register,
  setAlert,
  clearAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterScreen);
