import React from "react";
import { connect } from "react-redux";
import { logout } from "../../stores/auth";
import "./logoutButton.css";

const LogoutButton = ({ }) => {
  return (
    <a className="title mr-b-0 font-bold" >
      <i className="icon icon-exit_to_app_outlined warning" />
      Logout
    </a>
  );
};

const mapDispatchToProps = {
};

export default connect(null, mapDispatchToProps)(LogoutButton);
