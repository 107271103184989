import React, { useState } from "react";
import { connect } from "react-redux";
import "./updateBillingEntity.css";
import { LoadingCard, TitleBar } from "../../components";
import { getBillingEntity, updateBillingEntity } from "../../stores/billingEntities";
import { clearAlert, setAlert } from "../../stores/alerts/";
import { BillingEntitySchema } from "../../utils/schemas";
import { useEffect } from "react";
import history from "../../utils/history";
import { FaFileInvoice } from "react-icons/fa";
import { Form, Input, Row, Col, Select, Button, Modal } from "antd";
import { countries, provinces, states } from '../../consts';

const { Option } = Select;

const UpdateBillingEntityScreen = (
  {
    match,
    loading,
    billingEntities,
    getBillingEntity,
    updateBillingEntity,
    alert,
    setAlert,
    clearAlert,
  }
) => {
  const [disableState, setDisableState] = useState(true);
  const [renderOptions, setRenderOptions] = useState(provinces);
  const [form] = Form.useForm();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [isFormValid, setFormValid] = useState(true);


  useEffect(() => {
    let billingEntity = undefined;
    if (billingEntities === null) getBillingEntity(match.params.billingEntityId);
    else billingEntity = billingEntities[match.params.billingEntityId];

    if (billingEntity) {
      if (billingEntity?.phone?.length === 11 && billingEntity?.phone.substr(0, 1) === '1') {
        billingEntity.phone = billingEntity?.phone.substr(1, 10);
      }

      form.setFieldsValue({
        name: billingEntity.name || '',
        email: billingEntity.email || '',
        phone: billingEntity.phone || '',
        address: billingEntity.address || '',
        city: billingEntity.city || '',
        region: billingEntity.region || '',
        country: billingEntity.country || '',
        postal_code: billingEntity.postal_code || '',
      });

      if (billingEntities[match.params.billingEntityId].country) {
        setDisableState(false);
      }
    }
  }, [billingEntities, form, getBillingEntity, match.params.billingEntityId]);

  const handleCountryChange = () => {
    form.resetFields(['region']);
    const currentCountry = form.getFieldValue('country');

    if (currentCountry === 'USA') setRenderOptions(states);
    else setRenderOptions(provinces);

    setDisableState(false);
  };

  const onFormValuesChange = () => {
    const controlFields = form.getFieldsValue(['name', 'email', 'phone']);

    let fieldNotEmpty = true;

    for (let key in controlFields) {
      if (controlFields[key]?.length === 0) fieldNotEmpty = false
    }

    const formHasErrors = !!form.getFieldsError().filter(({ errors }) => errors.length).length;

    if (fieldNotEmpty && !formHasErrors) {
      return setFormValid(true);
    }
    setFormValid(false);
  }

  const onFinish = (values) => {
    const formattedData = { ...values };
    formattedData.phone = `${formattedData.prefix}${formattedData.phone}`;
    delete formattedData.prefix;
    const { error, value } = BillingEntitySchema.validate(formattedData);

    if (error) {
      setAlert(error.message);
    } else {
      clearAlert();
      updateBillingEntity({ id: match.params.billingEntityId, ...value });
    }
  };

  const handlePhone = (e) => {
    let newValue = e.target.value;
    if (newValue) {
      if (newValue.substr(0, 2) === '+1') {
        newValue = newValue.substr(2);
      }
      newValue = newValue.split('').filter(char => Number(char) || char === '0').join('');
    }
    if (newValue !== undefined) {
      if (newValue.length > 10) newValue = newValue.substr(0, 10);
      form.setFieldsValue({ phone: newValue });
    }
  }

  const phonePrefixes = [1];

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{ width: 70 }}
        size="large"
      >
        {phonePrefixes.map(prefix =>
          <Option key={prefix} value={prefix}>+{prefix}</Option>
        )}
      </Select>
    </Form.Item>
  );

  return (
    <>
    
      {billingEntities && billingEntities[match.params.billingEntityId] && !loading ? (

        <div className='newBillingEntityContainer flex-column d-flex'>
          <TitleBar
            titleIcon={<FaFileInvoice />}
            titleText="Update Billing Info"
            classes={
              {title: '!text-green-700'}
            }
            icon="icon-layers"
            buttons={[
              {
                key: "back",
                title: "Back to Billing Info",
                onClick: () =>
                  history.push(`/billing-entities/${match.params.billingEntityId}`),
                icon: (
                  <>
                    <i className={`icon icon-arrow_back_outlined`}>
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </>
                ),
              },
            ]}
            paths={[
              {
                title: "Billing Info",
                path: '/billing-entities'
              },
              {
                title: "Update Billing Info",
              }
            ]}
          />
          <Form
            layout="vertical"
            name="basic"
            form={form}
            initialValues={{
              prefix: 1
            }}
            onFinish={onFinish}
            onFieldsChange={onFormValuesChange}
          >
            <Form.Item
              label="Billing Information Name"
              name="name"
              rules={[{ required: true, message: 'Please input your Billing Information Name' }]}
            >
              <Input
                id="name"
                size="large"
                type="text"
                placeholder="Billing Information Name"
              />
            </Form.Item>
            <Row gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  validateTrigger={['onBlur']}
                  rules={[
                    { required: true, message: 'Please input your Email' },
                    { type: 'email', message: 'The input is not valid E-mail' }
                  ]}
                >
                  <Input
                    id="email"
                    size="large"
                    type="email"
                    placeholder="your.email@example.com"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="phone"
                  label="Phone Number"
                  rules={[
                    { required: true, message: 'Please input your phone number' },
                    // { len: 10, message: 'Phone number should be 10 digits' },
                    { pattern: /^\d{10}$/, message: 'Phone number should be 10 digits' }
                  ]}
                >
                  <Input
                    id="phone"
                    type="text"
                    onChange={handlePhone}
                    addonBefore={prefixSelector}
                    placeholder="1234567890"
                    style={{ width: '100%' }}
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col xs={24} md={24}>
                <Form.Item
                  label="Address"
                  name="address"
                >
                  <Input
                    id="address"
                    size="large"
                    type="text"
                    placeholder="Address"
                  />
                </Form.Item>
              </Col>

            </Row>
            <Row gutter={30}>
              <Col xs={24} md={8}>
                <Form.Item
                  label="City"
                  name="city"
                >
                  <Input
                    id="city"
                    size="large"
                    type="text"
                    placeholder="Ottawa"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Country"
                  name="country"
                >
                  <Select
                    size="large"
                    placeholder="Country"
                    onChange={handleCountryChange}
                  >
                    {countries.map(country =>
                      <Option key={country} value={country}>{country}</Option>)
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  label="Province or State"
                  name="region"
                >
                  <Select
                    size="large"
                    disabled={disableState}
                    placeholder="Province or State"
                  >
                    {renderOptions.map(opt =>
                      <Option key={opt} value={opt}>{opt}</Option>)
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={24}>
                <Form.Item
                  label="Postal Code"
                  name="postal_code"
                >
                  <Input
                    id="postal_code"
                    size="large"
                    type="text"
                    placeholder="Postal Code"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify='end' gutter={[8, 8]} className='pd-b-30'>
              <Col xs={24} md={6} span={8}>
                
                <Button
                  className='w-100'
                  type='cancel'
                  size='large'
                  onClick={() => setCancelModalVisible(true)}
                  loading={loading}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs={24} md={6} span={8}>
              <Button
                  className='w-100'
                  type='primary'
                  size='large'
                  loading={loading}
                  disabled={!isFormValid}
                  htmlType="submit"
                >
                  Update
                </Button>
            
              </Col>
            </Row>
         
                <Modal title="Discard changes?" visible={cancelModalVisible} onCancel={()=>setCancelModalVisible(false)} onOk={()=>{
                   history.push(
                    `/billing-entities/${match.params.billingEntityId}`
                  );
                }}><p>You might have unsaved changes. You will lose these changes if you continue.</p></Modal>
          </Form>
        </div>
      ) : (
        <LoadingCard />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alerts.alert,
  loading: state.billingEntities.loading,
  billingEntities: state.billingEntities.billingEntities,
});

const mapDispatchToProps = {
  getBillingEntity,
  updateBillingEntity,
  setAlert,
  clearAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateBillingEntityScreen);
