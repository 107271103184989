import React, {useEffect, useState} from "react";
import { Row, Col, Form, Input, Select } from "antd";
import { countries, provinces, states, phonePrefixes } from '../../consts';
const { Option } = Select;

const ContactInfoForm = ({ ...props }) => {

    let { form, required } = props;
    const [renderOptions, setRenderOptions] = useState(provinces);
    const [codeFormLabel, setCodeFormLabel] = useState();
    const [codeMask, setCodeMask] = useState();
    const [disablePostalCode, setDisablePostalCode] = useState(false);
    const [stateLabel, setStateLabel] = useState(null);


    

    /**
     * Require certain opbjects.
     * phone
     * email
     */
    if (!required) {
        required = {
            email: false,
            phone: false
        };
    }

        /**
     * Helper to change the code field mask, label and the like.
     * @param {string} country 
     */
    const setCodeFieldProps = (country) => {
        if (country === "USA") { setCodeMask('11111'); setCodeFormLabel('Zip Code'); setStateLabel('State'); }
        else if (country === "Canada") { setCodeMask('A1A 1A1'); setCodeFormLabel('Postal Code'); setStateLabel('Province'); }
        if (country === '') setDisablePostalCode(true); else setDisablePostalCode(false);
        //setDisablePostalCode(false);
    };

    const handleCountryChange = (resetFields = true) => {
        if (resetFields)
            form.resetFields(['region', 'postal_code']);

        const currentCountry = form.getFieldValue('country');
        setCodeFieldProps(currentCountry);

    
        if (currentCountry === 'USA') setRenderOptions(states);
        else setRenderOptions(provinces);
    
      };

    const handlePhone = (e) => {
        let newValue = e.target.value;
        if (newValue) {
          if (newValue.substr(0, 2) === '+1') {
            newValue = newValue.substr(2);
          }
          newValue = newValue.split('').filter(char => Number(char) || char === '0').join('');
        }
        if (newValue !== undefined) {
          if (newValue.length > 10) newValue = newValue.substr(0, 10);
          form.setFieldsValue({ phone: newValue });
        }
      }

      const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select
            style={{ width: 70 }}
            size="large"
          >
            {phonePrefixes.map(prefix =>
              <Option key={prefix} value={prefix}>+{prefix}</Option>
            )}
          </Select>
        </Form.Item>
      );

    useEffect(() => {
        handleCountryChange(false);
    }, [handleCountryChange]);

    return <>
        <Row gutter={30}>
            <Col xs={24} md={12}>
                <Form.Item
                    label="Email"
                    name="email"
                    validateTrigger={['onBlur']}
                    rules={[
                        required&&required.email?{ required: true, message: 'Please input your Email' }:{},
                        { type: 'email', message: 'The input is not valid E-mail' }
                    ]}
                >
                    <Input
                        id="email"
                        size="large"
                        type="email"
                        placeholder="your.email@example.com"
                    />
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item
                    name="phone"
                    label="Phone Number"
                    rules={[
                        required&&required.phone? { required: true, message: 'Please input your phone number' }:{},
                        // { len: 10, message: 'Phone number should be 10 digits' },
                        { pattern: /^\d{10}$/, message: 'Phone number should be 10 digits' }
                    ]}
                >
                    <Input
                        id="phone"
                        type="text"
                        onChange={handlePhone}
                        addonBefore={prefixSelector}
                        placeholder="1234567890"
                        style={{ width: '100%' }}
                        size="large"
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col xs={24} md={24}>
                <Form.Item
                    label="Address"
                    name="address"
                >
                    <Input
                        id="address"
                        size="large"
                        type="text"
                        placeholder="Address"
                    />
                </Form.Item>
            </Col>

        </Row>
        <Row gutter={30}>
            <Col xs={24} md={12}>
                <Form.Item
                    label="City"
                    name="city"
                >
                    <Input
                        id="city"
                        size="large"
                        type="text"
                        placeholder="Ottawa"
                    />
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item
                    label="Country"
                    name="country"
                >
                    <Select
                        size="large"
                        placeholder="Country"
                        onChange={handleCountryChange}
                    >
                        {countries.map(country =>
                            <Option key={country} value={country}>{country}</Option>)
                        }
                    </Select>
                </Form.Item>
            </Col>
          
        </Row>
        <Row gutter={30}>
            <Col xs={24} md={12}>
                {stateLabel!==null && <Form.Item
                    label={stateLabel}
                    name="region"
                >
                    <Select
                        size="large"
                        placeholder={stateLabel}
                    >
                        {renderOptions.map(opt =>
                            <Option key={opt} value={opt}>{opt}</Option>)
                        }
                    </Select>
                </Form.Item> }
            </Col>
            <Col xs={24} md={12}>
                <Form.Item
                    label={codeFormLabel}
                    name="postal_code"
                    hidden={disablePostalCode}
                >
                    <Input
                        id="postal_code"
                        size="large"
                        type="text"
                        placeholder={codeMask}
                    />
                </Form.Item>
            </Col>
        </Row>
    </>

};

export default ContactInfoForm;