import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import "./clinicCard.css";

import history from "../../utils/history";

import { DetailCard } from "..";
import { Modal } from 'antd';
import { removeClinic } from "../../stores/clinics";

import moment from 'moment';

import logo from '../../assets/herdrx-logo-transparent.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { confirm } = Modal;

const ClinicCard = ({ clinic, removeClinic, details, plans, ...props }) => {
    let plan = plans && plans.find((v, i) => clinic.plan_id === v.id);
    // useEffect(() => {
        
    //     console.log(plan);
    // }, [plan]);

    const editClinic = (e) => {
        e.preventDefault();
        e.stopPropagation();
        history.push(`/clinic/${clinic.id}/edit`);
    }

    let url = null;
    if (clinic.domain) {
        url = (`${clinic.domain}`);
    } else if (clinic.subdomain) {
        url = (`https://${clinic.subdomain}.clinic.api.chuteside.volt.vet`);
    } else {
        url = (`https://${clinic.id}.clinic.api.chuteside.volt.vet`);
    }

    const launchClinic = (e) => {
        e.preventDefault();
        e.stopPropagation();

        window.open(url);
      
    };

    const showConfirmDelete = (e) => {
        e.preventDefault();
        e.stopPropagation();
        confirm({
            title: 'Are you sure?',
            content: `Are you sure you want to delete this clinic: ${clinic.name}`,
            onOk() {
                removeClinic(clinic.id);
                history.push("/");
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    let created_at = clinic.created_at
    let plan_line = '';

    if (plan && plan.name) {
        plan_line += plan.name;
    } else {
        plan_line += 'Unknown Plan';
    }
    plan_line += ' @ ';
    if (plan && plan.amount) {
        plan_line += '$' + (plan.amount/100).toFixed(2) + ' / ' + plan.interval;
    } else {
        plan_line += 'Unknown Amount';
    }

    return (
        <>
        <DetailCard
            title={clinic.name}
            icon={<FontAwesomeIcon icon="fa-solid fa-clinic-medical" />}
            hoverable={true}
            onClick={() => history.push(`/clinic/${clinic.id}`)}
            style={{backgroundImage: `url(${logo})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'right bottom', backgroundSize: '25%'}}
            classes={
                { title: '!text-clinicBlue', card: '!border-clinicBlue border-t-0 border-b-0 border-r-0 border-l-4' }
            }
            extra={
                <>

                    <i className={`icon icon-play_arrow cursor-pointer`} onClick={(e) => launchClinic(e)}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                    </i>

                    <i className={`icon icon-edit cursor-pointer`} onClick={(e) => editClinic(e)}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                    </i>

                    <i className={`icon icon-delete cursor-pointer`} onClick={(e) => showConfirmDelete(e)}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                    </i>
                </>
            }
            details={details ? details : [
                {
                    icon: <FontAwesomeIcon icon="fa-regular fa-file-lines" className="!text-black !mr-2" />,
                    text: clinic.description
                },
                {
                    icon: <FontAwesomeIcon icon="fa-regular fa-calendar" className="!text-black !mr-2" />,
                    text: `Created ${moment(created_at).format("MMMM Do YYYY")}`
                },
                {
                    icon: <FontAwesomeIcon icon="fa-regular fa-location-dot" className="!text-black !mr-2" />,
                    text: [clinic.address, clinic.city, clinic.region].filter(v=>v!==null&&v.length>0).join(', ')
                },
                {
                    icon: <FontAwesomeIcon icon="fa-regular fa-globe" className="!text-black !mr-2" />,
                    html: `<a href="${url}" target="_blank">${url.replace(/https*:\/\//, '')}</a>`
                }, 
                {
                    icon: <FontAwesomeIcon icon="fa-regular fa-chart-simple" className="!text-black !mr-2" />,
                    text: plan_line
                }
            ]}
            {...props}
        />
        </>
    );
};

ClinicCard.propTypes = {
    clinic: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => {  return {
    plans: state.subscriptions.plans
}};

const mapDispatchToProps = {
    removeClinic
};

export default connect(mapStateToProps, mapDispatchToProps)(ClinicCard);

