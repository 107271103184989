import { Col, Layout, Row } from 'antd';
import React from "react";
import { getTitle } from '../../config/app';
import "./footer.css";

const { Footer: AntFooter } = Layout;

const Footer = () => {
  return (
    <AntFooter id="MyFooter" className="footer">
      <Row>
        <Col xs={24} sm={12} md={12} lg={12}>
          <h3>{(new Date()).getFullYear()} © {getTitle()}</h3>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
        </Col>
      </Row>
    </AntFooter>
  );
};

export default Footer;
