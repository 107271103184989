import billingEntityTypes from "./billingEntityTypes";
import { takeLatest, all } from "redux-saga/effects";
import {
  getBillingEntities,
  getBillingEntity,
  getBillingEntityUsers,
  addBillingEntity,
  updateBillingEntity,
  inviteBillingEntityUser,
  removeBillingEntityUser,
  removeBillingEntity,
  getCreditCard,
  getCreditCards,
  addCreditCard,
  updateCreditCard,
  removeCreditCard,
} from "./billingEntityActions";

export default function* billingEntitySagas() {
  yield all([
    yield takeLatest(billingEntityTypes.ADD_CUSTOMER_REQUEST, addBillingEntity),
    yield takeLatest(billingEntityTypes.GET_CUSTOMER_REQUEST, getBillingEntity),
    yield takeLatest(
      billingEntityTypes.GET_CUSTOMER_USERS_REQUEST,
      getBillingEntityUsers
    ),
    yield takeLatest(billingEntityTypes.GET_CUSTOMERS_REQUEST, getBillingEntities),
    yield takeLatest(billingEntityTypes.UPDATE_CUSTOMER_REQUEST, updateBillingEntity),
    yield takeLatest(
      billingEntityTypes.INVITE_CUSTOMER_USER_REQUEST,
      inviteBillingEntityUser
    ),
    yield takeLatest(
      billingEntityTypes.REMOVE_CUSTOMER_USER_REQUEST,
      removeBillingEntityUser
    ),
    yield takeLatest(billingEntityTypes.REMOVE_CUSTOMER_REQUEST, removeBillingEntity),
    yield takeLatest(billingEntityTypes.GET_CREDIT_CARD_REQUEST, getCreditCard),
    yield takeLatest(billingEntityTypes.GET_CREDIT_CARDS_REQUEST, getCreditCards),
    yield takeLatest(billingEntityTypes.ADD_CREDIT_CARD_REQUEST, addCreditCard),
    yield takeLatest(
      billingEntityTypes.UPDATE_CREDIT_CARD_REQUEST,
      updateCreditCard
    ),
    yield takeLatest(
      billingEntityTypes.REMOVE_CREDIT_CARD_REQUEST,
      removeCreditCard
    ),
  ]);
}
