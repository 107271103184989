import { Col, Row } from "antd";
import React from "react";
import "./Alert.css"

const Alert =  ({ 
    type, 
    message, 
    rowProps 
}) => {


const getIcon = type => {
    switch (type) {
        case "info":
            return (<i className="icon-info_outlined flex-initial text-5xl mr-6 ml-3"></i>)
        case "warning":
            return (<i className="icon-warning_outlined flex-initial text-5xl mr-6 ml-3"></i>)
        case "error":
            return (<i className="icon-error_outline_outlined flex-initial text-5xl mr-6 ml-3"></i>)
        case "check":
            return (<i className="icon-check_circle_outline_outlined flex-initial text-5xl mr-6 ml-3"></i>)
        default:
            return (<></>);
    };
}


return  (
<Row gutter={[15,15]} {...rowProps}>
    <Col xs={24} md={24}>
      <div className="text-base bg-gray-100 p-3 shadow-ant flex">
        {getIcon(type)}
        {/* <i class="icon-info_outlined flex-initial text-5xl mr-6 ml-3"></i> */}
        <p className="flex-1">
          {message}
        </p>
        </div>
    </Col>
  </Row>
  )
};


export default Alert;
