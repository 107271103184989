import billingEntityTypes from "./billingEntityTypes";
import { put, retry } from "redux-saga/effects";
import axios from "axios";
import { setAlert } from "../alerts";
import { authenticate } from "../auth";
import store from "../rootStore";
import qs from "querystring";
import setAuthToken from "../../utils/setAuthToken";
import history from "../../utils/history";
import callApi from "../../utils/callApi";

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

function* handleError(message, type) {
  console.error(message);
  store.dispatch(setAlert(message, "danger", type));
  yield put({
    type,
    payload: message,
  });
}

export function* getBillingEntity(action) {
  yield callApi(
    "get",
    `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/billing-entities/${action.payload}`,
    "GET_CUSTOMER",
    "billing_entity",
    "An error occurred while loading the billingEntity."
  );
}

export function* getBillingEntityUsers(action) {
  if (!store.getState().billingEntities.billingEntityUsers)
    yield callApi(
      "get",
      `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/billing-entities/${action.payload}/users`,
      "GET_CUSTOMER_USERS",
      "users",
      "An error occurred while loading users for a billingEntity."
    );
  else
    yield put({
      type: billingEntityTypes.SET_LOADING,
      payload: false,
    });
}

export function* getBillingEntities(action) {
  if (!store.getState().billingEntities.billingEntities || action.payload.force) {
    yield callApi(
      "get",
      `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/billing-entities${action.payload.per_page?'?per_page='+action.payload.per_page:''}`,
      "GET_CUSTOMERS",
      "billing_entities",
      "An error occurred while loading billingEntities."
    );
  }
  else
    yield put({
      type: billingEntityTypes.SET_LOADING,
      payload: false,
    });
}

export function* addBillingEntity(action) {
  yield callApi(
    "post",
    `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/billing-entities`,
    "ADD_CUSTOMER",
    "billing_entity",
    "An error occurred while adding a billingEntity.",
    action.payload
  );
}

export function* updateBillingEntity(action) {
  store.dispatch(authenticate());
  let error = null;
  try {
    setAuthToken();
    const { id, ...info } = action.payload;
    const res = yield retry(
      5,
      2000,
      axios.put,
      `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/billing-entities/${id}`,
      qs.stringify(info),
      config
    );
    const { success, data } = res.data;
    if (success && data.billing_entity) {
      history.push(`/billing-entities/${data.billing_entity.id}`);
      yield put({
        type: billingEntityTypes.UPDATE_CUSTOMER_SUCCESS,
        payload: data.billing_entity,
      });
    } else {
      error = "An error occurred while updating a billingEntity.";
    }
  } catch (err) {
    console.error(err.message);
    error = "An error occurred while updating a billingEntity.";
  }
  if (error) handleError(error, billingEntityTypes.UPDATE_CUSTOMER_FAILURE);
}

export function* inviteBillingEntityUser(action) {
  store.dispatch(authenticate());
  let error = null;
  try {
    setAuthToken();
    const { billingEntityId, userId } = action.payload;
    const inviteRes = yield retry(
      2,
      2000,
      axios.post,
      `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/billing-entities/${billingEntityId}/users/${userId}`
    );
    const { success, data } = inviteRes.data;
    if (success && data.billing_entity_user) {
      let newUser = null;
      try {
        const userRes = yield retry(
          5,
          2000,
          axios.get,
          `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/users/${userId}`,
          config
        );
        newUser =
          (userRes &&
            userRes.data &&
            userRes.data.data &&
            userRes.data.data.user) ||
          null;
      } catch {
        // Todo: Handle failure to fetch user
      }
      yield put({
        type: billingEntityTypes.INVITE_CUSTOMER_USER_SUCCESS,
        payload: newUser,
      });
    } else {
      error = "An error occurred while inviting user.";
    }
  } catch (err) {
    console.error(err.message);
    error = "An error occurred while inviting user.";
  }
  if (error) handleError(error, billingEntityTypes.INVITE_CUSTOMER_USER_FAILURE);
}

export function* removeBillingEntityUser(action) {
  store.dispatch(authenticate());
  let error = null;
  try {
    setAuthToken();
    const { billingEntityId, userId } = action.payload;
    const res = yield retry(
      2,
      2000,
      axios.delete,
      `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/billing-entities/${billingEntityId}/users/${userId}`
    );
    const { success } = res.data;
    if (success) {
      yield put({
        type: billingEntityTypes.REMOVE_CUSTOMER_USER_SUCCESS,
        payload: { billingEntityId, userId },
      });
    } else {
      error = "An error occurred while removing user.";
    }
  } catch (err) {
    console.error(err.message);
    error = "An error occurred while removing user.";
  }
  if (error) handleError(error, billingEntityTypes.REMOVE_CUSTOMER_USER_FAILURE);
}

export function* removeBillingEntity(action) {
  yield callApi(
    "delete",
    `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/billing-entities/${action.payload}`,
    "REMOVE_CUSTOMER",
    action.payload,
    "An error occurred while deleting billingEntity.",
    null,
    data => {
      store.dispatch(setAlert("Billing information was removed", 'success', billingEntityTypes.REMOVE_CUSTOMER_SUCCESS));
    }
  );
}

export function* getCreditCard(action) {
  // Todo: change this to fetch by billingEntity id
  yield callApi(
    "get",
    `${process.env.REACT_APP_CASHIER_ENDPOINT}/api/v1/cards/${action.payload}`,
    "GET_CREDIT_CARD",
    "card",
    "An error occurred while loading a credit card."
  );
}

export function* getCreditCards() {
  if (!store.getState().billingEntities.creditCards)
    yield callApi(
      "get",
      `${process.env.REACT_APP_CASHIER_ENDPOINT}/api/v1/cards`,
      "GET_CREDIT_CARDS",
      "cards",
      "An error occurred while loading credit cards."
    );
  else
    yield put({
      type: billingEntityTypes.SET_LOADING,
      payload: false,
    });
}

export function* addCreditCard(action) {
  yield callApi(
    "post",
    `${process.env.REACT_APP_CASHIER_ENDPOINT}/api/v1/cards`,
    "ADD_CREDIT_CARD",
    "card",
    "An error occurred while adding a credit card.",
    action.payload,
    (card) => {
      history.push(`/billing-entities/${card.billing_entity_id}`);
    }
  );
}

export function* updateCreditCard(action) {
  const { id, ...info } = action.payload;
  yield callApi(
    "put",
    `${process.env.REACT_APP_CASHIER_ENDPOINT}/api/v1/cards/${id}`,
    "UPDATE_CREDIT_CARD",
    "card",
    "An error occurred while updating a credit card.",
    info,
    (card) => {
      history.push(`/billing-entities/${card.billing_entity_id}`);
    }
  );
}

export function* removeCreditCard(action) {
  store.dispatch(authenticate());
  let error = null;
  try {
    setAuthToken();
    const res = yield retry(
      2,
      2000,
      axios.delete,
      `${process.env.REACT_APP_CASHIER_ENDPOINT}/api/v1/cards/${action.payload}`
    );
    const { success } = res.data;
    if (success) {
      yield put({
        type: billingEntityTypes.REMOVE_CREDIT_CARD_SUCCESS,
        payload: action.payload,
      });
    } else {
      error = "An error occurred while removing a credit card.";
    }
  } catch (err) {
    console.error(err.message);
    error = "An error occurred while removing a credit card.";
  }
  if (error) handleError(error, billingEntityTypes.REMOVE_CREDIT_CARD_FAILURE);
}
