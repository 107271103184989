import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { authenticate } from "../stores/auth";
import PropTypes from "prop-types";

import { Frame } from '../components';

const PrivateRoute = ({
  component: Component,
  authenticate,
  authenticated,
  loading,
  ...rest
}) => {
  useEffect(() => {
    authenticate();
    //eslint-disable-next-line
  }, []);

  // Prevents page from flashing briefly
  if (loading) return null;
  else
    return (
      <Route
        {...rest}
        render={(props) =>
          !authenticated ? <Redirect to='/login' /> :
            <Frame>
              <Component {...props} />
            </Frame>
        }
      ></Route>
    );
};

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
  authenticate: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  authenticated: state.auth.authenticated,
});

const mapDispatchToProps = {
  authenticate,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
