import React, { useEffect, useState } from "react";
import "./siteUserList.css";
import { InviteUser } from "../";
import PropTypes from "prop-types";
import {
  getSiteUsers,
  removeSiteUser,
  inviteSiteUser,
} from "../../stores/sites";
import { connect } from "react-redux";
import { Table, Button, Modal } from "antd";

const { confirm } = Modal;

const SiteUserList = ({
  siteId,
  siteUsers,
  getSiteUsers,
  inviteSiteUser,
  removeSiteUser,
  loading
}) => {
  const [users, setUsers] = useState([])
  useEffect(() => {
    getSiteUsers(siteId);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (siteUsers && siteUsers[siteId]) {
      setUsers(Object.values(siteUsers[siteId]))
    }
  }, [siteUsers, siteId]);

  const showConfirm = (user) => {
    confirm({
      title: 'Are you sure?',
      content: `Are you sure you want to remove ${user.first_name} ${user.last_name} from this site?`,
      onOk() {
        removeSiteUser(siteId, user.id);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const onInvite = (email) => {
    inviteSiteUser(siteId, email);
  };
  
  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (user) => (
        <span>{user?.first_name} {user?.last_name}</span >
      ),
      width: '30%'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '30%'
    },
    {
      title: 'Role',
      key: 'role',
      render: (user) => (
        <span>{["Admin", "User"][parseInt(`0x${user.id[0]}`) % 2]}</span>
      ),
      width: '20%'
    },
    {
      title: 'Remove',
      key: 'remove',
      render: (user) => (
        <Button type='danger' onClick={() => showConfirm(user)}>Remove</Button>
      ),
      width: '10%'
    }
  ];

  return (
    <div className='userListContainer w-100'>
      <InviteUser onInvite={onInvite} />
      <Table
        loading={!siteUsers}
        dataSource={users}
        columns={columns}
        pagination={{ pageSize: 5 }}
        rowKey="id"
      />
    </div>
  );
};

SiteUserList.propTypes = {
  siteId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  siteUsers: state.sites.siteUsers,
  loading: state.sites.loading
});

const mapDispatchToProps = {
  getSiteUsers,
  removeSiteUser,
  inviteSiteUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteUserList);
