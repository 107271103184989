export default {
  CLEAR_AUTH_STORE: "CLEAR_AUTH_STORE",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  REFRESH_TOKEN_REQUEST: "REFRESH_TOKEN_REQUEST",
  AUTHENTICATE_REQUEST: "AUTHENTICATE_REQUEST",
  AUTHENTICATE_SUCCESS: "AUTHENTICATE_SUCCESS",
  AUTHENTICATE_FAILURE: "AUTHENTICATE_FAILURE",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",
  CONFIRM_REQUEST: "CONFIRM_REQUEST",
  CONFIRM_SUCCESS: "CONFIRM_SUCCESS",
  CONFIRM_FAILURE: "CONFIRM_FAILURE",
  LOGOUT: "LOGOUT",
  LOGOUT_ASYNC: "LOGOUT_ASYNC",
  CONFIRM_RESET_PASSWORD_REQUEST: "CONFIRM_RESET_PASSWORD_REQUEST",
  CONFIRM_RESET_PASSWORD_SUCCESS: "CONFIRM_RESET_PASSWORD_SUCCESS",
  CONFIRM_RESET_PASSWORD_FAILURE: "CONFIRM_RESET_PASSWORD_FAILURE",
  IS_ACCOUNT_CONFIRMED_REQUEST: "IS_ACCOUNT_CONFIRMED_REQUEST",
  IS_ACCOUNT_CONFIRMED_SUCCESS: "IS_ACCOUNT_CONFIRMED_SUCCESS",
  IS_ACCOUNT_CONFIRMED_FAILURE: "IS_ACCOUNT_CONFIRMED_FAILURE",
  RESEND_CODE_REQUEST: "RESEND_CODE_REQUEST",
  RESEND_CODE_SUCCESS: "RESEND_CODE_SUCCESS",
  RESEND_CODE_FAILURE: "RESEND_CODE_FAILURE"
};
