import authSagas from "./auth/authSagas";
import alertSagas from "./alerts/alertSagas";
import userSagas from "./users/userSagas";
import billingEntitySagas from "./billingEntities/billingEntitySagas";
import siteSagas from "./sites/siteSagas";
import clinicSagas from "./clinics/clinicSagas";
import subscriptionSagas from "./subscriptions/subscriptionSagas";
import { all } from "redux-saga/effects";

export default function* rootSaga() {
  yield all([
    authSagas(),
    alertSagas(),
    userSagas(),
    billingEntitySagas(),
    siteSagas(),
    subscriptionSagas(),
    clinicSagas()
  ]);
}
