export default {
  SET_LOADING: "CLINIC_SET_LOADING",
  CLEAR_CLINIC_STORE: "CLEAR_CLINIC_STORE",
  GET_CLINIC_REQUEST: "GET_CLINIC_REQUEST",
  GET_CLINIC_SUCCESS: "GET_CLINIC_SUCCESS",
  GET_CLINIC_FAILURE: "GET_CLINIC_FAILURE",
  GET_TEMP_CLINIC_REQUEST: "GET_TEMP_CLINIC_REQUEST",
  GET_TEMP_CLINIC_SUCCESS: "GET_TEMP_CLINIC_SUCCESS",
  GET_TEMP_CLINIC_FAILURE: "GET_TEMP_CLINIC_FAILURE",
  ADD_CLINIC_REQUEST: "ADD_CLINIC_REQUEST",
  ADD_CLINIC_SUCCESS: "ADD_CLINIC_SUCCESS",
  ADD_CLINIC_FAILURE: "ADD_CLINIC_FAILURE",
  UPDATE_CLINIC_REQUEST: "UPDATE_CLINIC_REQUEST",
  UPDATE_CLINIC_SUCCESS: "UPDATE_CLINIC_SUCCESS",
  UPDATE_CLINIC_FAILURE: "UPDATE_CLINIC_FAILURE",
  GET_CLINIC_USERS_REQUEST: "GET_CLINIC_USERS_REQUEST",
  GET_CLINIC_USERS_SUCCESS: "GET_CLINIC_USERS_SUCCESS",
  GET_CLINIC_USERS_FAILURE: "GET_CLINIC_USERS_FAILURE",
  GET_CLINICS_REQUEST: "GET_CLINICS_REQUEST",
  GET_CLINICS_SUCCESS: "GET_CLINICS_SUCCESS",
  GET_CLINICS_FAILURE: "GET_CLINICS_FAILURE",
  INVITE_CLINIC_USER_REQUEST: "INVITE_CLINIC_USER_REQUEST",
  INVITE_CLINIC_USER_SUCCESS: "INVITE_CLINIC_USER_SUCCESS",
  INVITE_CLINIC_USER_FAILURE: "INVITE_CLINIC_USER_FAILURE",
  REMOVE_CLINIC_USER_REQUEST: "REMOVE_CLINIC_USER_REQUEST",
  REMOVE_CLINIC_USER_SUCCESS: "REMOVE_CLINIC_USER_SUCCESS",
  REMOVE_CLINIC_USER_FAILURE: "REMOVE_CLINIC_USER_FAILURE",
  REMOVE_CLINIC_REQUEST: "REMOVE_CLINIC_REQUEST",
  REMOVE_CLINIC_SUCCESS: "REMOVE_CLINIC_SUCCESS",
  REMOVE_CLINIC_FAILURE: "REMOVE_CLINIC_FAILURE",
  GET_CLINIC_INVITES_REQUEST: "GET_CLINIC_INVITES_REQUEST",
  GET_CLINIC_INVITES_SUCCESS: "GET_CLINIC_INVITES_SUCCESS",
  GET_CLINIC_INVITES_FAILURE: "GET_CLINIC_INVITES_FAILURE",
  ACCEPT_CLINIC_INVITE_REQUEST: "ACCEPT_CLINIC_INVITE_REQUEST",
  ACCEPT_CLINIC_INVITE_SUCCESS: "ACCEPT_CLINIC_INVITE_SUCCESS",
  ACCEPT_CLINIC_INVITE_FAILURE: "ACCEPT_CLINIC_INVITE_FAILURE",
  DECLINE_CLINIC_INVITE_REQUEST: "DECLINE_CLINIC_INVITE_REQUEST",
  DECLINE_CLINIC_INVITE_SUCCESS: "DECLINE_CLINIC_INVITE_SUCCESS",
  DECLINE_CLINIC_INVITE_FAILURE: "DECLINE_CLINIC_INVITE_FAILURE",
  VALIDATE_DOMAIN_REQUEST: 'VALIDATE_DOMAIN_REQUEST',
  VALIDATE_DOMAIN_SUCCESS: 'VALIDATE_DOMAIN_SUCCESS',
  VALIDATE_DOMAIN_FAILURE: 'VALIDATE_DOMAIN_FAILURE',
  VALIDATE_DOMAIN_CLEAR: 'VALIDATE_DOMAIN_CLEAR'
};
