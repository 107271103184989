import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getBillingEntities } from "../../stores/billingEntities";
import { BillingEntityCard, LoadingCard, TitleBar } from "../../components";
import history from "../../utils/history";
import {  Empty } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BillingEntitiesScreen = ({
    getBillingEntities,
    billingEntities
}) => {
    const [once, setOnce] = useState(false);
    const [breadCrumb] = useState([
    {
   
    }
  ]);
    useEffect(() => {
      if (!once) {
        getBillingEntities(2000, true);
        setOnce(true);
      }
    }, [once, getBillingEntities]);

  
    return ( <>
        {billingEntities ? <>
            <TitleBar
            titleText="Billing Info"
            icon={<FontAwesomeIcon icon="file-invoice-dollar" />}
            classes={
              { title: '!text-green-700' }
            }
            buttons={[
                {
                    key: "add",
                    title: "Add Billing Info",
                    type: "primary",
                    onClick: () =>
                      history.push(`/billing-entities/new`),
                    icon: (
                      <>
                        <i className={`icon icon-add_outlined cursor-pointer`}>
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                      </>
                    ),
                  }
            ]}
            paths={[
              ...breadCrumb
            ]}
          />
                <div>
                    {
                        Object.entries(billingEntities).length>0?Object.entries(billingEntities).map(([i,entity]) => {
                            return (<BillingEntityCard key={entity.id} billingEntity={entity}></BillingEntityCard>);
                        }): 
                        <div className="text-center w-100">
                            <Empty 
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description='No billing information' 
                            />
                      </div>
                    }
                </div>
            </>
        :<LoadingCard></LoadingCard>}
        

    </>)
};
    


const mapStateToProps = (state) => ({
    billingEntities: state.billingEntities.billingEntities
});

const mapDispatchToProps = {
    getBillingEntities
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingEntitiesScreen);