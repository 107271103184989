import React, { useEffect, useState } from "react";
import "./accountAlert.css";
import history from "../../utils/history";
import { connect } from "react-redux";
import { loadUser } from "../../stores/users";
import { Alert, Space, Button } from "antd";


const AccountAlert = ({ loadUser, profile }) => {
  const [show, setShow] = useState(false);

  const profileComplete = () => {
    
    // CHECK FOR COMPLETE PROPS WHERE THESE ITEMS ARE CONSIDERED VALUABLE
    // WE DON'T HAVE TO WORRY ABOUT THINGS LIKE FAX OR SOCIAL OR WHATEVER - THEY DO NOT CONSTITUTE INCOMPLETE
    const importantProps = [
      "email",
      "first_name",
      "last_name",      
      "city",
      "country",
    ];

    // the important props map function needs to reference an external var to report if true or false
    var somethingIncomplete = false;
    
    // CHECK IF *ANY* PROFILE PROPS ARE BLANK OR MISSING AND SHOW THE ALERT IF THEY ARE
    // CONVERTED FROM FOR LOOP TO MAP (FR)
    importantProps.map(p => {
      if (!profile[p] || profile[p] === "")  somethingIncomplete = true;
      return null;
    }
    );

    //still learning jsx, 
    // couldn't figure out how to return a function using ternary operator syntax so by all means....
    if(somethingIncomplete === true) return false;
    else  return true;
  };

  useEffect(() => {
    if (!profile) loadUser();
    if (profile && !profileComplete()) setShow(true);
    //eslint-disable-next-line
  }, [profile]);

  if (!show || !profile) return null;
  return (
    <>
      {show &&
        <div className={show && `acc-alert`}>
          <Alert
            message="Your account is incomplete. Click the button on right to update your profile"
            type="warning"
            action={
              <Space>
                <Button
                  size="small"
                  type="ghost"
                  onClick={() => history.push(`/users/${profile.id}/profile`)}
                >
                  Update profile
              </Button>
              </Space>
            }
            closable
          />
        </div>
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.users.profile,
});

const mapDispatchToProps = {
  loadUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountAlert);
