import subscriptionTypes from "./subscriptionTypes";

export const setPlan = (plan) => ({
  type: subscriptionTypes.SET_PLAN,
  payload: plan,
});

export const getPlans = () => ({
  type: subscriptionTypes.GET_PLANS_REQUEST,
});

export const getPlan = (planId) => ({
  type: subscriptionTypes.GET_PLAN_REQUEST,
  payload: planId,
});

export const getSubscriptions = () => ({
  type: subscriptionTypes.GET_PLAN_REQUEST,
});

export const getSubscription = (subscriptionId) => ({
  type: subscriptionTypes.GET_SUBSCRIPTION_REQUEST,
  payload: subscriptionId,
});

export const getSubscriptionItem = (itemId) => ({
  type: subscriptionTypes.GET_SUBSCRIPTION_ITEM_REQUEST,
  payload: itemId,
});

export const getSubscriptionItems = () => ({
  type: subscriptionTypes.GET_SUBSCRIPTION_ITEMS_REQUEST,
});

export const addSubscriptionItem = (subscriptionInfo) => ({
  type: subscriptionTypes.ADD_SUBSCRIPTION_ITEM_REQUEST,
  payload: subscriptionInfo,
});

export const updateSubscriptionItem = (Id, subscriptionInfo) => ({
  type: subscriptionTypes.UPDATE_SUBSCRIPTION_ITEM_REQUEST,
  payload: { id: Id, ...subscriptionInfo },
});

export const removeSubscriptionItem = (Id) => ({
  type: subscriptionTypes.REMOVE_SUBSCRIPTION_ITEM_REQUEST,
  payload: Id,
});
