import React from "react";
import PropTypes from "prop-types";
import { Col, Button, Card, Divider } from "antd";

import "./pricing-plan.css";

const PlanCard = ({ plan, active, onClick, selectButton = true, ...props }) => {
  if (!plan) return null;

  if (plan) {
    if (active) {
      plan.extraClass = 'required-card'
    } else {
      plan.extraClass = ''
    }
  }

  return (
    <Col xs={24} md={8}>
      <Card className={`plan-card ${plan.extraClass}`}>
        <div className="card-body-h">
          <h2 className="card-title">{plan.name || "Test Monthly"}</h2>
          <p>{"Product Description" || plan.stripe_product_id}</p>
          <Divider />
          <h3 className="prize">{plan.currency === 'usd' ? '$' : 'C$'} {plan.amount / 100} </h3>
          <span className="subtitle">per {plan.interval_count>1?plan.interval_count:''} {plan.interval}{plan.interval_count>1?'s':''}</span>
          <div className="flex-container bottom-button">
          
           { selectButton && <Button
              onClick={() => onClick && onClick(plan)}
              type="primary"
              size="large"
              block
              className="w-full"
            >
              Select
            </Button> }
          </div>
        </div>
      </Card>
    </Col>
  );
};

PlanCard.propTypes = {
  plan: PropTypes.object,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  selectButton: PropTypes.bool
};

export default PlanCard;
