import authTypes from "./authTypes";
import { put, retry } from "redux-saga/effects";
import axios from "axios";
import qs from "querystring";
import { setAlert } from "../alerts";
import store from "../rootStore";
import jwt_decode from "jwt-decode";
import setAuthToken from "../../utils/setAuthToken";
import history from "../../utils/history";

const config = {
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
};

function* handleError(message, type) {
  store.dispatch(setAlert(message, "danger", type));
  yield put({ type, payload: message });
}

export function* register(action) {
  try {
    const res = yield axios.post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/sign-up`,
      qs.stringify(action.payload),
      config
    );
    const { state, message, error, ...auth } = res.data;
    if (state === "okay") {
      history.push(`/confirm/${auth.email}`);
      yield put({ type: authTypes.REGISTER_SUCCESS, payload: auth });
    } else {
      let errMessage = message || "User creation failed.";
      store.dispatch(
        setAlert(errMessage, "danger", authTypes.REGISTER_FAILURE)
      );
      yield put({
        type: authTypes.REGISTER_FAILURE,
        payload: errMessage,
      });
    }
  } catch (err) {
    let error = err.message;
    if (err.message === 'Request failed with status code 400') {
      error = 'User with this email already registered';
    }
    store.dispatch(setAlert(error, "danger", authTypes.REGISTER_FAILURE));
    yield put({ type: authTypes.REGISTER_FAILURE, payload: error });
  }
}

export function* confirm(action) {
  let error = null;
  try {
    const res = yield axios.post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/confirm-sign-up`,
      qs.stringify(action.payload),
      config
    );
    if (res && !res.error) {
      store.dispatch(
        setAlert('Email verified successfully', "success", authTypes.CONFIRM_SUCCESS)
      );
      history.push("/login");
      yield put({
        type: authTypes.CONFIRM_SUCCESS,
      });
    } else if (res && res.error) {
      error = res.error;
    } else {
      error = "Error while attempting to confirm user.";
    }
  } catch (err) {
    error = 'Incorrect confirmation code';
    // store.dispatch(
    //   setAlert('Incorrect confirmation code', "danger", authTypes.CONFIRM_FAILURE)
    // );
  }

  if (error) {
    console.error(error);
    store.dispatch(setAlert(error, "danger", authTypes.CONFIRM_FAILURE));
    yield put({
      type: authTypes.CONFIRM_FAILURE,
      payload: error,
    });
  }
}

export function* login(action) {
  try {
    const res = yield axios.post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/login`,
      qs.stringify(action.payload),
      config
    );
    const { state, error, ...auth } = res.data;
    if (state && state === "okay") {
      auth.user = jwt_decode(auth.id);
      localStorage.setItem("auth", JSON.stringify(auth));
      yield put({ type: authTypes.LOGIN_SUCCESS, payload: auth });
    } else {
      store.dispatch(
        setAlert("Login failed.", "danger", authTypes.LOGIN_FAILURE)
      );
      yield put({
        type: authTypes.LOGIN_FAILURE,
        payload: "Login failed.",
      });
    }
  } catch (err) {
    let error = err.response?.data?.error || err.message;

    if (error === 'User does not exist.') {
      error = 'Wrong email or password'
    } else if (error === 'User is not confirmed.') {
      error = 'Email is not confirmed'
    }

    store.dispatch(setAlert(error, "danger", authTypes.LOGIN_FAILURE));
    yield put({ type: authTypes.LOGIN_FAILURE, payload: err.message });
  }
}

export function* logout() {
  setAuthToken();
  const auth = JSON.parse(localStorage.getItem("auth"));
  localStorage.removeItem("auth");
  try {
    store.dispatch({ type: "CLEAR_ALL" });
    yield put({ type: authTypes.LOGOUT });
    yield axios.post(`${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/logout`, {
      token: auth.token
    }).then((r) => {
      // window.location
      console.log('logout response', r)
    });
  } catch (err) {
    console.error("err: " + err.message);
    yield put({ type: authTypes.LOGOUT });
  }
}

export function* refreshToken() {
  let error = null;
  let newAuth = null;
  try {
    setAuthToken();
    const currentAuth = JSON.parse(localStorage.getItem("auth"));
    const body = { token: currentAuth.refresh, user: currentAuth.uuid ? currentAuth.uuid : null };
    const res = yield retry(
      10,
      2000,
      axios.post,
      `${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/refresh`,
      qs.stringify(body),
      config
    );
    if (res && res.data && res.data.state === "okay") {
      // Store the new tokens
      newAuth = {
        ...currentAuth,
        id: res.data.id,
        token: res.data.token,
        user: jwt_decode(res.data.id),
      };
      localStorage.setItem("auth", JSON.stringify(newAuth));
      yield put({
        type: authTypes.REFRESH_TOKEN,
        payload: newAuth,
      });
    } else {
      error = "Could not authenticate with the server.";
    }
  } catch (err) {
    error = err.message;
  }

  if (error) {
    localStorage.removeItem("auth");
    console.error(error);
    store.dispatch(setAlert(error, "danger", authTypes.AUTHENTICATE_FAILURE));
    yield put({
      type: authTypes.AUTHENTICATE_FAILURE,
      payload: error,
    });
  }
}

export function* authenticate() {
  let error = null;

  if (localStorage.auth && JSON.parse(localStorage.getItem("auth")).refresh) {
    const auth = JSON.parse(localStorage.getItem("auth"));

    if (auth && auth.refresh) {
      // If token exists, assume authentication until a request fails
      yield put({
        type: authTypes.AUTHENTICATE_SUCCESS,
      });
      yield put({
        type: authTypes.REFRESH_TOKEN_REQUEST,
      });
    }

  } else {
    error = "No token currently set.";
  }

  if (error) {
    localStorage.removeItem("auth");
    // store.dispatch(setAlert(error, "danger", authTypes.AUTHENTICATE_FAILURE));
    yield put({
      type: authTypes.AUTHENTICATE_FAILURE,
      payload: error,
    });
  }
}

export function* resetPassword(action) {
  let error = null;
  try {
    const res = yield axios.post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/reset-password`,
      qs.stringify(action.payload),
      config
    );
    const { state } = res.data;
    if (state && state === "okay") {
      yield put({ type: authTypes.RESET_PASSWORD_SUCCESS });
    } else {
      error = "An error prevented resetting password.";
    }
  } catch (err) {
    console.error("err: " + err.message);
    error = "An error prevented resetting password.";
  }
  if (error) yield handleError(error, authTypes.RESET_PASSWORD_FAILURE);
}


export function* confirmResetPassword(action) {
  let error = null;
  try {
    const res = yield axios.post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/confirm-reset-password`,
      qs.stringify(action.payload),
      config
    );
    const { state } = res.data;
    if (state && state === "okay") {
      yield put({ type: authTypes.CONFIRM_RESET_PASSWORD_SUCCESS });
      store.dispatch(setAlert("Your password has been reset.", "success", authTypes.CONFIRM_RESET_PASSWORD_SUCCESS));
    } else {
      error = "An error prevented resetting password.";
    }
  } catch (err) {
    console.error("err: " + err.message);
    console.log(err.response);
    error = "An error prevented resetting password.";
  }
  if (error) yield handleError(error, authTypes.CONFIRM_RESET_PASSWORD_FAILURE);
}

export function* isAccountConfirmed(action) {
  let error = null;
  try {
    const res = yield axios.post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/is-confirmed`,
      qs.stringify(action.payload),
      config
    );
    const { success } = res.data;
    if (success) {
      yield put({ type: authTypes.IS_ACCOUNT_CONFIRMED_SUCCESS, payload: { ...action.payload, result: true } });
      //store.dispatch(setAlert("Your password has been reset.", "success", authTypes.IS_ACCOUNT_CONFIRMED_SUCCESS));
    } else {
      error = "An error prevented checking if account is confirmed.";
    }
  } catch (err) {
    console.error("err: " + err.message);
    console.log(err.response);
    error = "An error prevented checking if account is confirmed.";
  }
  if (error) {
    yield put({ type: authTypes.IS_ACCOUNT_CONFIRMED_FAILURE, payload: { ...action.payload, result: false } });
  }
}

export function* resendCode(action) {
  let error = null;
  let agnositcMessage = "If a record with this e-mail address exists, a code was sent to it."
  try {
    const res = yield axios.post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/resend-confirmation`,
      qs.stringify(action.payload),
      config
    );
    const { state } = res.data;
    if (state === "okay") {
      yield put({ type: authTypes.IS_ACCOUNT_CONFIRMED_SUCCESS });
      store.dispatch(setAlert(agnositcMessage, "error", authTypes.IS_ACCOUNT_CONFIRMED_SUCCESS));
    } else {
      error = "An error prevented checking if account is confirmed.";
    }
  } catch (err) {
    console.error("err: " + err.message);
    error = "An error prevented checking if account is confirmed.";
  }
  if (error) {
    yield put({ type: authTypes.IS_ACCOUNT_CONFIRMED_FAILURE });
    store.dispatch(setAlert(agnositcMessage, "error", authTypes.IS_ACCOUNT_CONFIRMED_SUCCESS));
  }

}