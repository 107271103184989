import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./passwordReset.css";
import { Logo, ToastAlert } from "../../components";
import { clearAlert, setAlert } from "../../stores/alerts";
import { confirm, resetPassword, confirmResetPassword, authenticate } from "../../stores/auth";
import { Input, Form, Button } from "antd";
import history from "../../utils/history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {confirmPasswordRules,passwordRules, emailRules} from './passwordRules';

const PasswordResetScreen = ({ clearAlert, resetPassword,setAlert,confirmResetPassword, authenticated, authenticate, ...props }) => {
  const [isSent] = useState(false);
  const [loading, setLoading] = useState(false); //demo only -------------------------------------------
  const [form] = Form.useForm();
  const [confirmReset, setConfirmReset] = useState(props.match.path === '/reset/confirm');
  const [confirmButtonDisabled, setConfrmButtonDisabled] = useState(true);
  const [email, setEmail] = useState();

  //simply a flag to surpress the toast alert if redirecting to login. It has a toast there. If this is not used 2 alerts will popup.
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const symbols = "!@#$%^&*";
  

  useEffect(() => {
    clearAlert();
    authenticate();
    if (authenticated) history.push("/");
    //eslint-disable-next-line

  }, [authenticated,clearAlert,authenticate]);


  const onSubmit = () => {
    clearAlert();
    let fieldValues = form.getFieldsValue();
    
    if (confirmReset) {
      let symbolsRegEx = new RegExp(`[${symbols}]`);
      // N4xime69

      if (fieldValues.password !== fieldValues.confirm_password) {
        setAlert('Passwords do not match sorry.');
      } else if (!fieldValues.password.match(/[a-z]/)) {
        setAlert('Password does not contain a lowercase letter.');
      } else if (!fieldValues.password.match(/[A-Z]/)) {
        setAlert('Password does not contain a uppercase letter.');
      }  else if (!fieldValues.password.match(/[1-9]/)) {
        setAlert('Password does not contain a number.');
      }  else if (!fieldValues.password.match(symbolsRegEx)) {
        setAlert(`Password does not contain a symbol (${symbols}).`);
      } else { //everything passed so lets continue
        //setAlert('Worked!','success');
        confirmResetPassword(fieldValues.email, fieldValues.password, fieldValues.code);
        setLoading(true);
        // resetPassword( form.getFieldValue('email'));
        setRedirectToLogin(true);
        setTimeout(() => {
          
          history.push("/login");
        }, 2000);
      }
    } else {
      setLoading(true);
      resetPassword( fieldValues.email );
      setEmail(fieldValues.email);
      setTimeout(() => {
        setConfirmReset(true);
        setLoading(false);
        history.push("/reset/confirm");
      }, 1000);
    }

  };

  const valuesChange = () => {
    let valArray = Object.values(form.getFieldsValue()).filter(v=>typeof v !== 'undefined' && v.trim().length> 0);
    let valObj = form.getFieldsValue();
    setConfrmButtonDisabled(valArray.length !== Object.values(valObj).length);
    // if (valObj.password !== valObj.confirm_password) {
    //   addRule({name: 'notmatch', required: true, message: 'Passwords do not match'}, passwordFieldRules);
    // } else {
    //   removeRule('notmatch', passwordFieldRules);
    // }
    form.validateFields(['password','confirm_password']);

  };


  const handleGoToSignIn = (e) => {
    e.preventDefault();
    history.push('/login');
  }

  return (
    <div className='registerContainer'>
      {!redirectToLogin?<ToastAlert />:''}
      <div className='registerMainCard'>
        <div className='registerFormCard pb-20'>
          <Logo className='logoContainer' />
          <h1 className="text-2xl">{isSent?'Please check your e-mail':confirmReset?'Confirm password reset':'Password Reset'}</h1>
              <div className="search-holder">
              <Form
                  form={form}
                  onFinish={onSubmit}
                  onValuesChange={valuesChange}
                  layout='vertical'
                  style={{ width: "400px" }}
                  className="sign-in-form"
                >
                { confirmReset ? (
                <>
                  <p>An e-mail has been sent to the address provided with a verification code you will need to continue.</p>
                  <p>Password must be at least 8 characters, have at least 1 symbol ({symbols}), 1 uppercase letter, 1 lowercase letter and one number.</p>
                  
                  <Form.Item
                    name="email"
                    label="E-mail"
                    rules={emailRules}
                    required
                    >
                      <Input id="email" value={email} />
                    </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={passwordRules}
                    required
                    >
                      <Input.Password id="password" />
                    </Form.Item>
                  <Form.Item
                    name="confirm_password"
                    label="Confirm Password"
                    rules={confirmPasswordRules}
                    required
                    >
                      <Input.Password id="confirm_password" />
                    </Form.Item>
                  <Form.Item name="code" label="Verification Code"
                   
                    required
                  >
                    <Input id="verify_code" />
                  </Form.Item>

                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    size="large"
                    className="resetSubmitButton"
                    loading={loading}
                    disabled={confirmButtonDisabled}
                  >
                   <FontAwesomeIcon className={`${loading?'hidden':''}`} icon="fa-solid fa-circle-check" /> &nbsp; Confirm new password
                  </Button>
                </>
                
                ):(
                <>
                  { !isSent ? (<>
                  <div className="resetPasswordInputContainer text-center">
                  <p>
                    Enter the e-mail address of the account, if found reset instructions will be sent.
                  </p>

                    <Form.Item
                      name="email"
                      label='Email address'
                      rules={[
                        {
                          required: true,
                          message: `Please input your email address!`
                        }, {
                          type: 'email',
                          message: 'The input is not valid E-mail'
                        }
                      ]}
                      required
                    >
                      <Input
                        id="email"
                        size="large"
                        placeholder='your.email@example.com'
                      />
                    </Form.Item>
                  </div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    size="large"
                    className="resetSubmitButton"
                    loading={loading}
                  >
                   <FontAwesomeIcon className={`${loading?'hidden':''}`} icon="fa-solid fa-arrow-rotate-right" /> &nbsp; Reset Password
                  </Button>
                 </> ):(
                   <>
                      <p>
                        Follow the instructions sent to the e-mail provided to continue.
                      </p>
                   </>
                 ) }
                  </>
                 
               )}
                <div className="resetPasswordAccount mt-5 text-center">
                    <span>
                        {confirmReset?( <> Don't have a verification code? <a href="/reset">Request One</a> <br /></>):''}
                        <a href="/login" onClick={handleGoToSignIn}>
                          Go back to login page
                        </a>
                    </span>
                  </div>
             </Form>
              </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated
});

const mapDispatchToProps = {
  clearAlert,
  confirm,
  resetPassword,
  setAlert,
  confirmResetPassword,
  authenticate
  
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetScreen);
