import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import "./profile.css";
import { LoadingCard, TitleBar, ProfileForm } from "../../components";
import { loadUser, updateUser, updatePassword, clearPasswordUpdated } from "../../stores/users";
import { Form, Button, message } from "antd";
import history from "../../utils/history";

const DashboardScreen = ({ profile, loading, loadUser, updateUser, updatePassword, passwordUpdated, clearPasswordUpdated }) => {
    const [isValidForm, setValidForm] = useState(false);
    const [form] = Form.useForm();


    const onFormValuesChange = useCallback(() => {
        const formHasErrors = !!form.getFieldsError().filter(({ errors }) => errors.length).length;
        if (!formHasErrors) {
            return setValidForm(true);
        }
        setValidForm(false);
    },[form, setValidForm]);

    useEffect(() => {
        if (profile === null) loadUser();

        if (profile) {
            form.setFieldsValue({
                first_name: profile.first_name,
                last_name: profile.last_name,
                email: profile.email,
                country: profile.country,
                region: profile.region,
                city: profile.city
            });
        }
        onFormValuesChange();
    }, [profile, form, loadUser, onFormValuesChange])

    useEffect(() => {
        if (passwordUpdated !== null) {
            if (passwordUpdated) {
                message.success("Account password updated.");
            } else {
                message.error("Password was not updated.");
            }
            clearPasswordUpdated();
        }

    }, [passwordUpdated, clearPasswordUpdated]);

 

    const onSubmit = (values) => {
        let password = values['password'];
        let password_confirm = values['password_confirm'];

        delete values['password'];
        delete values['password_confirm'];   

        updateUser(values, () => {
            if (typeof password !== 'undefined' && password.length > 0 && typeof password_confirm !== 'undefined' && password_confirm.length > 0 && password === password_confirm){
                updatePassword(password, () => {
                    message.success('Your password was updated.');
                });
            }
            message.success('Your profile was updated.'); 
            history.push('/'); 
        
        });
      
        
        
    };



    if (profile === null) {
        return <LoadingCard />
    }
    return (
        <div className='profileContainer flex-column d-flex p-30' >
            <TitleBar
                titleText="Update Profile"
                paths={[
                    {
                        title: "Profile",
                        path: "/"
                    },
                    {
                        title: "Update Profile",
                    }
                ]}
            />
            <Form
                onFinish={onSubmit}
                onFieldsChange={onFormValuesChange}
                layout='vertical'
                form={form}
                className='profileForm'
            >
                <ProfileForm form={form} setValidForm={setValidForm} profile={profile} />
                <Button
                    className='col-sm'
                    type='primary'
                    loading={loading}
                    htmlType="submit"
                    disabled={!isValidForm}

                >
                    Update Account
                </Button>

            </Form>
           
        </div>
    );
};

const mapStateToProps = (state) => ({
    profile: state.users.profile,
    loading: state.users.loading,
    passwordUpdated: state.users.passwordUpdated
});

const mapDispatchToProps = {
    loadUser,
    updateUser,
    clearPasswordUpdated,
    updatePassword
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen);
