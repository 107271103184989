import billingEntityTypes from "./billingEntityTypes";

const initialState = {
  billingEntities: null,
  billingEntityUsers: null,
  creditCards: null,
  loading: false,
  newEntityID: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case billingEntityTypes.GET_CREDIT_CARDS_REQUEST:
    case billingEntityTypes.ADD_CREDIT_CARD_REQUEST:
    case billingEntityTypes.UPDATE_CREDIT_CARD_REQUEST:
    case billingEntityTypes.REMOVE_CREDIT_CARD_REQUEST:
    case billingEntityTypes.REMOVE_CUSTOMER_REQUEST:
    case billingEntityTypes.ADD_CUSTOMER_REQUEST:
    case billingEntityTypes.GET_CUSTOMER_USERS_REQUEST:
    case billingEntityTypes.GET_CUSTOMERS_REQUEST:
    case billingEntityTypes.INVITE_CUSTOMER_USER_REQUEST:
    case billingEntityTypes.REMOVE_CUSTOMER_USER_REQUEST:
    case billingEntityTypes.UPDATE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
        newEntityID: null
      };

    case billingEntityTypes.ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        billingEntities: { ...state.billingEntities, [action.payload.id]: action.payload },
        newEntityID: action.payload.id,
        loading: false,
      };
    case billingEntityTypes.GET_CUSTOMER_SUCCESS:
    case billingEntityTypes.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        billingEntities: { ...state.billingEntities, [action.payload.id]: action.payload },
        loading: false,
      };

    case billingEntityTypes.GET_CUSTOMER_USERS_SUCCESS:
      return {
        ...state,
        billingEntityUsers: action.payload.reduce((obj, user) => {
          obj[user.id] = user;
          return obj;
        }, {}),
        loading: false,
      };

    case billingEntityTypes.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        billingEntities: action.payload.reduce((obj, billingEntity) => {
          obj[billingEntity.id] = billingEntity;
          return obj;
        },{}),
        loading: false,
      };

    case billingEntityTypes.GET_CUSTOMER_USERS_FAILURE:
      return {
        ...state,
        billingEntityUsers: null,
        loading: false,
      };

    case billingEntityTypes.GET_CUSTOMERS_FAILURE:
      return {
        ...state,
        billingEntities: null,
        loading: false,
      };

    case billingEntityTypes.INVITE_CUSTOMER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        billingEntityUsers: {
          ...state.billingEntityUsers,
          [action.payload.id]: action.payload,
        },
      };

    case billingEntityTypes.REMOVE_CUSTOMER_USER_SUCCESS:
      if (state.billingEntityUsers[action.payload.userId]) {
        const {
          [action.payload.userId]: _,
          ...userRemoved
        } = state.billingEntityUsers;
        return {
          ...state,
          billingEntityUsers: userRemoved,
          loading: false,
        };
      } else return { ...state, loading: false };

    case billingEntityTypes.GET_CREDIT_CARDS_SUCCESS:
      return {
        ...state,
        creditCards: action.payload.reduce((obj, card) => {
          obj[card.billing_entity_id] = card;
          return obj;
        }, {}),
        loading: false,
      };

    case billingEntityTypes.GET_CREDIT_CARD_SUCCESS:
    case billingEntityTypes.UPDATE_CREDIT_CARD_SUCCESS:
    case billingEntityTypes.ADD_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        creditCards: {
          ...state.creditCards,
          [action.payload.billing_entity_id]: action.payload,
        },
        loading: false,
      };

    case billingEntityTypes.REMOVE_CREDIT_CARD_SUCCESS:
      const card = Object.values(state.creditCards).find(
        (x) => x.id === action.payload
      );
      if (card) {
        const {
          [card.billing_entity_id]: _,
          ...cardRemoved
        } = state.creditCards;
        return {
          ...state,
          creditCards: cardRemoved,
          loading: false,
        };
      } else return { ...state, loading: false };

    case billingEntityTypes.REMOVE_CUSTOMER_SUCCESS:
      if (state.billingEntities[action.payload]) {
        const { [action.payload]: _, ...billingEntityRemoved } = state.billingEntities;
        delete state.billingEntities[action.payload];
        return {
          ...state,
          creditCards: billingEntityRemoved,
          loading: false,
        };
      } else return { ...state, loading: false };

    case billingEntityTypes.GET_CREDIT_CARD_FAILURE:
    case billingEntityTypes.GET_CUSTOMER_FAILURE:
    case billingEntityTypes.GET_CREDIT_CARDS_FAILURE:
    case billingEntityTypes.ADD_CREDIT_CARD_FAILURE:
    case billingEntityTypes.UPDATE_CREDIT_CARD_FAILURE:
    case billingEntityTypes.REMOVE_CREDIT_CARD_FAILURE:
    case billingEntityTypes.REMOVE_CUSTOMER_FAILURE:
    case billingEntityTypes.UPDATE_CUSTOMER_FAILURE:
    case billingEntityTypes.INVITE_CUSTOMER_USER_FAILURE:
    case billingEntityTypes.REMOVE_CUSTOMER_USER_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case "CLEAR_ALL":
    case billingEntityTypes.CLEAR_CUSTOMER_STORE:
      return initialState;

    case billingEntityTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};
