import { Button, Form, Input } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Section, TitleBar } from "../../components";
const TestingScreen = () => {
  return (
    <>
      <TitleBar titleText="H1" buttons={
        [
          {title: "Test", key: "Test"}
        ]
      } />
      <Form layout="vertical">
      <Section title="H2" className="">
        <h3>H3</h3>
        <h4>H4</h4>
        <h5>H5</h5>
        <Form.Item name='Test' label='Label'>
          <Input id="test" name='test' type='text' />
        </Form.Item>
        <Button
                  className='md:float-right'
                  type='cancel'
                  size='large'
                >
                  Test
                </Button>
      </Section>
      </Form>
    </>
  );
};
export default connect(state=>({}), {})(TestingScreen);