import store from "../rootStore";
import callApi from "../../utils/callApi";

export function* loadUser() {
  const uuid = store.getState().auth.uuid;
  yield callApi(
    "get",
    `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/users/${uuid}`,
    "LOAD_USER",
    "user",
    "An error occurred while loading user profile.",
    null,
    null,
    false
  );
}

export function* updateUser(action) {
  const uuid = store.getState().auth.uuid;
  yield callApi(
    "put",
    `${process.env.REACT_APP_CRM_ENDPOINT}/api/v1/users/${uuid}`,
    "UPDATE_USER",
    "user",
    "An error occurred while updating user profile.",
    action.payload.user,
    action.payload.successCallback,
    false
  );
}
export function* updatePassword(action) {
  yield callApi(
    "POST",
    `${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/update-password`,
    "UPDATE_PASSWORD",
    "user",
    "Account password was not updated.",
    {password: action.payload.password},
    action.payload.onSuccess
  );
}
