import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./confirmRegistration.css";
import { Logo, Input, ToastAlert, LoadingCard } from "../../components";
import { setAlert, clearAlert } from "../../stores/alerts";
import { confirm, isAccountConfirmed, resendCode } from "../../stores/auth";

import NotFoundScreen from "../NotFound/notFoundScreen";

import { Form, Button } from "antd";

const ConfirmRegistrationScreen = ({
  match,
  location,
  confirm,
  alert,
  loading,
  clearAlert,
  isAccountConfirmed,
  confirmed,
  resendCode
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    clearAlert();
    isAccountConfirmed(match.params.email);
  }, [clearAlert, isAccountConfirmed, match.params.email]);

  const onSubmit = (values) => {
    const { code } = values;

    clearAlert();
    confirm(match.params.email, code);
  };

  const resendCodeClick = () => {
    resendCode(match.params.email);
  };

  const run = () => {

    if (loading) {
      return <LoadingCard />;
    }

    if (confirmed && confirmed.result) {
      return NotFoundScreen();
    } else {
      return (
        <div className='registerContainer'>
          <div className='registerMainCard'>
          {/* {alert && alert.type === "CONFIRM_SUCCESS" && <ToastAlert>{alert}</ToastAlert>}
          {alert && alert.type === "CONFIRM_FAILURE" && <ToastAlert>{alert}</ToastAlert>} */}
            <div className='registerFormCard pb-20'>
              <Logo
                className='logoContainer'
              />
              <h1 className="text-2xl">Confirm E-mail</h1>
          
            
              <Form
                style={{ width: "400px" }}
                onFinish={onSubmit}
                layout='vertical'
                form={form}
              >  <p className="text-center">We've sent a confirmation code to your email.</p>
                <Form.Item
                  name='code'
                  label='Confirmation Code'
                  rules={[
                    { required: true, message: 'Please input your Confirmation Code!' },
                    { len: 6, message: 'Should be 6 digits' }
                  ]}
                >
                  <Input
                    id='code'
                    type='text'
                    name='code'
                    placeholder='Enter Your Confirmation Code'
                    autoFocus={true}
                    maxLength={6}
                    autoComplete='off'
                  />
                </Form.Item>
                <div className='confirmInfo'>
                  <Button type="primary" htmlType="submit" block size="large">
                    Confirm
                </Button>
                </div>
                <div className="resetPasswordAccount mt-5 text-center">
                        <span>
                            Didn't receive one? Please check your spam folder. <br /> Still nothing? &nbsp;
                            <span className="link" onClick={resendCodeClick}>
                              Re-send confirmation code.
                            </span>
                        </span>
                      </div>
              </Form>
            </div>
          </div>
        </div>
      );

    }

  };

  return (<>
  <ToastAlert /> 
    {run()}
  </>);
};

const mapStateToProps = (state) => ({
  alert: state.alerts.alert,
  loading: state.auth.loading,
  confirmed: state.auth.confirmed
});

const mapDispatchToProps = {
  setAlert,
  clearAlert,
  confirm,
  isAccountConfirmed,
  resendCode
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmRegistrationScreen);
