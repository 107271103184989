import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Button, Select } from "antd";
import { Input } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { Option } = Select;


const InviteUser = ({ onInvite }) => {
  const [form] = Form.useForm();
  const [isValidForm, setValidForm] = useState(false);

  const onFormValuesChange = () => {
    const formHasErrors = !!form.getFieldsError().filter(({ errors }) => errors.length).length;

    if (form.isFieldsTouched(true) && !formHasErrors) {
      return setValidForm(true)
    }

    setValidForm(false)
  }

  const onSubmit = (values) => {
    form.resetFields();
    onInvite(values.email);
  }

  return (
    <div className='mr-b-15'>
      <Form
        onFieldsChange={onFormValuesChange}
        onFinish={onSubmit}
        layout='inline'
        form={form}
      >
        <Form.Item
          label='Email'
          name='email'
          style={{ maxWidth: '40%', width: '100%' }}
          rules={[
            { required: true, message: 'Please input your email address!' },
            { pattern: /^\S+@\S+\.\S+$/, message: 'Please enter a valid email' }
          ]}
          required
        >
          <Input
            name='email'
            type='text'
            placeholder='Enter Email'
          />
        </Form.Item>
        <Form.Item
          label='Role'
          name='role'
          shouldUpdate
        >
          <Select
            placeholder='Role'
            style={{ width: 120 }}
          >
            <Option value="User">User</Option>
            <Option value="Admin">Admin</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            type='primary'
            size="medium"
            htmlType="submit"
            disabled={!isValidForm}
            className="!text-white"
          ><FontAwesomeIcon icon="fa-solid fa-paper-plane-top" />&nbsp;
            Send Invite
        </Button>
        </Form.Item>
      </Form>
    </div >
  );
};

InviteUser.propTypes = {
  onInvite: PropTypes.func.isRequired,
};

export default InviteUser;

