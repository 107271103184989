import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./billingEntity.css";
import {
  LoadingCard,
  BillingEntityUserList,
  CreditCardDisplay,
  TitleBar,
  BillingEntityCard,
  PlanCard
} from "../../components";
import { Row, Col, Button, Modal, Empty, Tabs } from "antd";
import { getBillingEntity, removeBillingEntity, getBillingEntities } from "../../stores/billingEntities";
import { getSites } from "../../stores/sites";
import { clearAlert } from "../../stores/alerts/";
import history from "../../utils/history";
import { getSubscriptionItems, getPlans } from "../../stores/subscriptions";
import ClinicCard from "../../components/ClinicCard";
import { getClinics } from "../../stores/clinics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { TabPane } = Tabs;

const { confirm } = Modal;

const BillingEntityScreen = (
  {
    match,
    billingEntities,
    billingEntityUsers,
    sites,
    clinics,
    plans,
    subscriptionItems,
    getBillingEntity,
    getBillingEntities,
    getPlans,
    getSubscriptionItems,
    getSites,
    getClinics,
    removeBillingEntity,
    clearAlert,
  }
) => {

  const [breadCrumb] = useState([
    {
      title: "Billing Info",
      path: `/billing-entities`
    }
  ]);


  useEffect(() => {
    clearAlert();
    getSubscriptionItems();
    getPlans();
    getBillingEntity(match.params.billingEntityId);
    getSites();
    getClinics();
    window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, [match.params.billingEntityId]);

  const billingEntity = billingEntities && billingEntities[match.params.billingEntityId];

  const showConfirmDelete = () => {
    confirm({
      title: 'Are you sure?',
      content: `Are you sure you want to delete this billing information: ${billingEntity.name}`,
      onOk() {
        removeBillingEntity(billingEntity.id);
        history.push("/");
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  let totalSubscriptionAmount = {
    month: 0,
    year: 0
  };

  

  let clinicsTabContent = () => {
    return <div>
      <Row gutter={[16,16]} className="clear-both mb-5">
        <Col span={24}>
            <Button
              variant='light'
              className="float-right"
              onClick={() =>
                history.push(
                  `/clinic/new`
                )
              }
            >
              <i className={`icon icon-add_outlined`}>
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
              New Clinic
            </Button>
            </Col>
          </Row>
          <Row gutter={[16,16]} className="clear-both">
            {clinics ? 
              ((Object.values(clinics)?.some(x => x.billing_entity_id === match.params.billingEntityId) ? (
                Object.values(clinics)
                  .filter((clinic) => clinic.billing_entity_id === billingEntity.id)
                  .map((clinic) => {
                    return (
                      <Col key={clinic.id} span={12}>
                        <ClinicCard key={clinic.id} clinic={clinic} />
                      </Col>
                    )
                  })
              ) : <div className="text-center w-100">
                    <Empty 
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description='No Clinics' 
                    />
                  </div>
              )) : (
                <LoadingCard />
            )}
          </Row>
        </div>;
  }

  let usersTabContent = () => {
    return <Row gutter={16}>
            <Col md={24}>
            <BillingEntityUserList billingEntityId={match.params.billingEntityId} />
            </Col>
          </Row>;
  }

  let plansTabContent = () => {
    return <div>
                  <div className='h-100 w-100 d-flex flex-row justify-content-center flex-nowrap'>
                    {subscriptionItems && plans && (sites || clinics) ? 
                      (subscriptionItems.some(x => x.billing_entity_id === match.params.billingEntityId) ? (
                      subscriptionItems
                        .filter(
                          (x) => x.billing_entity_id === match.params.billingEntityId
                        )
                        .map((item) => {
                          const plan = plans.find((x) => x.id === item.plan_id);
                          
                          sites = sites===null?{}:sites;
                          clinics = clinics===null?{}:clinics;
                          
                          const sitesUsed = Object.values(sites).filter(
                            (x) => x.plan_id === plan.id
                          );
                          const clinicsUsed = Object.values(clinics).filter(
                            (x) => x.plan_id === plan.id
                          );
                      
                          let clinicsList = [];
                          Object.values(clinicsUsed).forEach(v=>clinicsList.push(v.name));
                          let sitesList = [];
                          Object.values(sitesUsed).forEach(v=>sitesList.push(v.name));

                          if (clinicsUsed.length > 0 || sitesUsed.length > 0) {
                            let totalPerPlan = (sitesUsed.length+clinicsUsed.length)*(plan.amount/100);
                            totalSubscriptionAmount[plan.interval] += totalPerPlan;
                            return <PlanCard key={plan.id} plan={plan} selectButton={false} />;
                          }
                          return null;
                        })
                        
                    ) : (
                      <div className="text-center w-100">
                        <Empty 
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description='No Subcriptions' 
                        />
                      </div> 
                    )) : (
                      <LoadingCard />
                    )}
                    <h2>{Object.keys(totalSubscriptionAmount).map((interval)=>{
                      if (totalSubscriptionAmount[interval] > 0)
                        return `Subscription fees totaling $${totalSubscriptionAmount[interval]} per ${interval}`;
                        return null;
                    })}</h2>
                  </div>
                </div>
  }

  return (
    <>
      {billingEntity ? (
        <div className='billingEntityContainer'>
          <TitleBar
            titleText={billingEntity.name}
            classes={
              {title: '!text-green-700'}
            }
            icon={<FontAwesomeIcon icon="file-invoice-dollar" />}
            buttons={[
              {
                key: "edit",
                title: "Edit Billing Info",
                type: "primary",
                onClick: () =>
                  history.push(`/billing-entities/${match.params.billingEntityId}/edit`),
                icon: (
                  <>
                    <i className={`icon icon-edit cursor-pointer`}>
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </>
                ),
              },
              {
                key: "delete",
                title: "Delete Billing Info",
                type: "danger",
                onClick: () => showConfirmDelete(),
                icon: (
                  <>
                    <i className={`icon icon-delete cursor-pointer`}>
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </>
                ),
              },
            ]}
            paths={[
              ...breadCrumb,
              {
                title: billingEntity.name,
                path: `/billing-entities/${match.params.billingEntityId}`
              }
            ]}
          />
          <Row gutter={30}>
            <Col xs={24} md={14}>
              <div className='w-100'>
                <BillingEntityCard billingEntity={billingEntity} />
              </div>
            </Col>
            <Col xs={24} md={10}>
              <div className='filler m-auto'>
                <CreditCardDisplay billingEntityId={billingEntity.id} />
              </div>
            </Col>
          </Row >
          <Tabs defaultActiveKey="1" onChange={()=>{}}>
            {/* <TabPane tab="Operation Sites" key="1">{sitesTabContent()}</TabPane> */}
            <TabPane tab="Veterinary Clinics" key="2">{clinicsTabContent()}</TabPane>
            <TabPane key="3" tab="Users">{usersTabContent()}</TabPane>
            <TabPane key="4" tab="Plans">{plansTabContent()}</TabPane>
          </Tabs>
          
          
      
          

          
        </div>
      ) : (
        <LoadingCard />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  sites: state.sites.sites,
  clinics: state.clinics.clinics,
  billingEntities: state.billingEntities.billingEntities,
  subscriptionItems: state.subscriptions.subscriptionItems,
  plans: state.subscriptions.plans,
});

const mapDispatchToProps = {
  getBillingEntity,
  getBillingEntities,
  getSites,
  clearAlert,
  removeBillingEntity,
  getSubscriptionItems,
  getPlans,
  getClinics,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingEntityScreen);
