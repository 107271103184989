import React from "react";
import "./notFound.css";
import history from "../../utils/history";
import Logo from '../../components/Logo';
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NotFoundScreen = () => {
  return (
    <div className='registerContainer'>
      <div className='registerMainCard'>

        <div className='registerFormCard pb-20 w-96'>
        <Logo className="m-auto w-48 mb-5" />
          <h1 className="text-2xl">That page cannot be found</h1>
          <span className="text-center">You have tried to access a page that does not exist. Please try again from the beginning. </span>
          <Button 
              type="primary" 
              htmlType="submit" 
              block size="large" 
              onClick={() => {history.push('/');}}
              className="mt-5"
            >
              <FontAwesomeIcon icon="fa-solid fa-home" /> &nbsp; Take me home
            </Button>
        </div>
        
      </div>
    </div>
  );
};

export default NotFoundScreen;
