import { useEffect } from "react";
import "./toastAlert.css";
import { message } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { clearAlert } from "../../stores/alerts";

const ToastAlert = ({ alert, clearAlert }) => {

  useEffect(() => {
    if (alert && alert.variant) {
      switch (alert.variant) {
        case 'success': 
          message.success(alert.message, 5);
          break;
        case 'danger': 
          message.error(alert.message, 5);
          break;
        case 'warning': 
          message.warning(alert.message, 5);
          break;
        case 'info': 
          message.info(alert.message, 5);
          break;
        default: 
          message.info(alert.message, 5);
          break;
      }
    }
  }, [alert]);

  return null;
};

ToastAlert.propTypes = {
  variant: PropTypes.string,
};

const mapStateToProps = (state) => ({
  alert: state.alerts.alert,
});

const mapDispatchToProps = {
  clearAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastAlert);
