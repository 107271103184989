import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import history from "../../utils/history";
import { removeCreditCard, getCreditCards } from "../../stores/billingEntities";
import { Card, Modal } from "antd";
import brands from "./ccBrands";
import "./creditCardDisplay.css";

const { confirm } = Modal;

// const creditCard = {
//   brand: 'hipercard',
//   id: '53453',
//   billing_entity_id: 'ddd9187a-e6e5-4043-adf8-cf5f234597d1',
//   last_four: '5715'
// };

// const creditCard = '';

const CreditCardDisplay = ({
  billingEntityId,
  creditCards,
  getCreditCards,
  removeCreditCard,
  ...props
}) => {

  useEffect(() => {
    getCreditCards();
    //eslint-disable-next-line
  }, []);

  const showConfirmDelete = () => {
    confirm({
      title: 'Are you sure?',
      content: `Are you sure you want to delete this credit card?`,
      okText: 'Delete',
      okType: 'danger',
      onOk() {
        removeCreditCard(creditCard && creditCard.id);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const creditCard = creditCards && creditCards[billingEntityId];

  return (
    <div className='credit-card-container'>
      <Card
        hoverable
        className={`card bg-${creditCard ? creditCard?.brand : 'blank'}`}
        title={<img alt="chip" className="card-chip-image" src={brands.chip} />}
        extra={creditCard ? <img alt="chip" className="card-brand-image" src={brands[creditCard?.brand]} /> : false}
      >
        {creditCard &&
          <div className="credit-card-data">
            <div className="credit-card-number">
              ●●●●&nbsp;●●●●&nbsp;●●●●&nbsp;{creditCard.last_four}
            </div>
          </div>
        }
      </Card>
        {creditCard ? (
        <div className="credit-card-mask">
          <span>
            <i
              className="icon-edit_outlined icon"
              onClick={() =>
                history.push(
                  `/billing-entities/${creditCard && creditCard.billing_entity_id
                  }/card/edit`
                )}
            />
          </span>
          <span>
            <i
              className="icon-delete_outlined icon"
              onClick={showConfirmDelete}
            />
          </span>
        </div>
      ) : (
      <div 
        className="credit-card-mask"
        onClick={() =>
          history.push(`/billing-entities/${billingEntityId
            }/card/new`
        )}
      >
        <span className="label">
          {/* <FontAwesomeIcon icon="fa-regular fa-plus" className="" />  */}
          <span>Add Card</span>
        </span>
      </div>
      )}
    </div>
  );
};

CreditCardDisplay.propTypes = {
  billingEntityId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  creditCards: state.billingEntities.creditCards,
});

const mapDispatchToProps = {
  getCreditCards,
  removeCreditCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardDisplay);
