import authTypes from "./authTypes";
import { takeLatest, all, throttle } from "redux-saga/effects";
import {
  login,
  logout,
  register,
  authenticate,
  confirm,
  refreshToken,
  resetPassword,
  confirmResetPassword,
  isAccountConfirmed,
  resendCode

} from "./authActions";

function* registerWatcher() {
  yield takeLatest(authTypes.REGISTER_REQUEST, register);
}

function* confirmWatcher() {
  yield takeLatest(authTypes.CONFIRM_REQUEST, confirm);
}

function* loginWatcher() {
  yield takeLatest(authTypes.LOGIN_REQUEST, login);
}

function* logoutWatcher() {
  yield takeLatest(authTypes.LOGOUT_ASYNC, logout);
}

function* authenticateWatcher() {
  yield takeLatest(authTypes.AUTHENTICATE_REQUEST, authenticate);
}

function* resetPasswordWatcher() {
  yield takeLatest(authTypes.RESET_PASSWORD_REQUEST, resetPassword);
}
function* confirmResetPasswordWatcher() {
  yield takeLatest(authTypes.CONFIRM_RESET_PASSWORD_REQUEST, confirmResetPassword);
}

function* refreshTokenWatcher() {
  yield throttle(5000, authTypes.REFRESH_TOKEN_REQUEST, refreshToken);
}

export default function* authSagas() {
  yield all([
    registerWatcher(),
    confirmWatcher(),
    loginWatcher(),
    logoutWatcher(),
    authenticateWatcher(),
    refreshTokenWatcher(),
    resetPasswordWatcher(),
    confirmResetPasswordWatcher(),
    yield takeLatest(authTypes.IS_ACCOUNT_CONFIRMED_REQUEST, isAccountConfirmed),
    yield takeLatest(authTypes.RESEND_CODE_REQUEST, resendCode)
  ]);
}
