import React from "react";
import "./sidebarSubmenu.css";
import PropTypes from "prop-types";

const SidebarSubmenu = ({ active, children }) => {
  return (
    <div className={`submenuContainer ${active ? "submenuActive" : ""}`}>
      {children}
    </div>
  );
};

SidebarSubmenu.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default SidebarSubmenu;
