import setAuthToken from "./setAuthToken";
import { retry, put } from "redux-saga/effects";
import axios from "axios";
import qs from "querystring";
import store from "../stores/rootStore";
import { setAlert } from "../stores/alerts";
import { authenticate } from "../stores/auth";

export default function* callApi(
  method,
  url,
  typePrefix,
  dataProp,
  errorMessage,
  body = null,
  onSuccess = null,
  callAuthenticate = true
) {
  const config = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  if (callAuthenticate) store.dispatch(authenticate());
  let error = null;
  try {
    setAuthToken();
    let tries = 5;
    if (method === "post") tries = 1;
    if (method === "put") tries = 2;
    const res = yield retry(tries, 1000, axios, {
      method,
      url,
      data: body && qs.stringify(body),
      ...config,
    });
    const { success, data } = res.data;
    if (success) {
      if (method === 'delete') {
        yield put({
          type: `${typePrefix}_SUCCESS`,
          payload: dataProp,
        });
        if (onSuccess) onSuccess(data);
      } else {
        yield put({
          type: `${typePrefix}_SUCCESS`,
          payload: dataProp && data[dataProp],
        });
        if (onSuccess) onSuccess(data && data[dataProp]);
      }

    } else {
      error = true;
    }
  } catch (err) {
    console.error(err.message);
    error = true;
  }
  if (error) {
    store.dispatch(setAlert(errorMessage, "danger", `${typePrefix}_FAILURE`));
    yield put({ type: `${typePrefix}_FAILURE`, payload: errorMessage });
  }
}
