import subscriptionTypes from "./subscriptionTypes";

const initialState = {
  loading: true,
  plan: null,
  plans: null,
  subscription: null,
  subscriptions: null,
  subscriptionItem: null,
  subscriptionItems: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case subscriptionTypes.GET_SUBSCRIPTION_REQUEST:
    case subscriptionTypes.GET_SUBSCRIPTIONS_REQUEST:
    case subscriptionTypes.GET_SUBSCRIPTION_ITEM_REQUEST:
    case subscriptionTypes.GET_SUBSCRIPTION_ITEMS_REQUEST:
    case subscriptionTypes.GET_PLAN_REQUEST:
    case subscriptionTypes.GET_PLANS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case subscriptionTypes.GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: action.payload,
        loading: false,
      };

    case subscriptionTypes.SET_PLAN:
    case subscriptionTypes.GET_PLAN_SUCCESS:
      return {
        ...state,
        plan: action.payload,
        loading: false,
      };

    case subscriptionTypes.GET_PLANS_SUCCESS:
      return {
        ...state,
        plans: action.payload,
        loading: false,
      };

    case subscriptionTypes.GET_SUBSCRIPTION_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionItem: action.payload,
      };

    case subscriptionTypes.GET_SUBSCRIPTION_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        subscriptionItem: null,
      };

    case subscriptionTypes.GET_SUBSCRIPTION_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionItems: action.payload,
      };

    case subscriptionTypes.GET_SUBSCRIPTION_ITEMS_FAILURE:
      return {
        ...state,
        loading: false,
        subscriptionItems: null,
      };

    case subscriptionTypes.GET_SUBSCRIPTIONS_FAILURE:
    case subscriptionTypes.GET_PLAN_FAILURE:
    case subscriptionTypes.GET_PLANS_FAILURE:
    case subscriptionTypes.GET_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case "CLEAR_ALL":
    case subscriptionTypes.CLEAR_SUBSCRIPTION_STORE:
      return initialState;

    default:
      return state;
  }
};
