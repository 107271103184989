import Joi from "@hapi/joi";

const CreditCardSchema = Joi.object({
  id: Joi.any()
    .when("$edit", {
      is: Joi.boolean().valid(true).required(),
      then: Joi.string().required(),
      otherwise: Joi.forbidden(),
    })
    .messages({
      "string.base": `Card Id should be a text string.`,
      "string.empty": `Card Id cannot be an empty field.`,
      "boolean.required": "$edit flag must be set in validation.",
      "any.required": `Card Id is a required field.`,
      "any.unknown": "There was a problem parsing the Card Id.",
    }),
  billing_entity_id: Joi.any()
    .when("$edit", {
      is: Joi.boolean().valid(true).required(),
      then: Joi.string(),
      otherwise: Joi.string().required(),
    })
    .messages({
      "string.base": `Billing Entity Id should be a text string.`,
      "string.empty": `Billing Entity Id cannot be an empty field.`,
      "boolean.required": "$edit flag must be set in validation.",
      "any.required": `Billing Entity Id is a required field.`,
      "any.unknown": "Billing Entity Id cannot be editted.",
    }),
  number: Joi.string()
    .replace(/\D/g, "")
    .length(16)
    .when("$edit", {
      is: Joi.boolean().valid(true).required(),
      then: Joi.string(),
      otherwise: Joi.string().required(),
    })
    .messages({
      "string.base": `Card Number should be a text string.`,
      "string.empty": `Card Number cannot be an empty field.`,
      "boolean.required": "$edit flag must be set in validation.",
      "any.required": `Card Number is a required field.`,
      "string.length": "Please enter a valid 16-digit card number.",
    }),
  exp_month: Joi.string()
    .replace(/\D/g, "")
    .min(1)
    .max(2)
    .when("$edit", {
      is: Joi.boolean().valid(true).required(),
      then: Joi.string(),
      otherwise: Joi.string().required(),
    })
    .messages({
      "string.base": `Expiry Month should be a text string.`,
      "string.empty": `Expiry Month cannot be an empty field.`,
      "boolean.required": "$edit flag must be set in validation.",
      "any.required": `Expiry Month is a required field.`,
      "string.min": "Please enter a valid month.",
      "string.max": "Please enter a valid month.",
    }),
  exp_year: Joi.string()
    .replace(/\D/g, "")
    .length(4)
    .when("$edit", {
      is: Joi.boolean().valid(true).required(),
      then: Joi.string(),
      otherwise: Joi.string().required(),
    })
    .messages({
      "string.base": `Expiry Year should be a text string.`,
      "string.empty": `Expiry Year cannot be an empty field.`,
      "boolean.required": "$edit flag must be set in validation.",
      "any.required": `Expiry Year is a required field.`,
      "string.length": "Please enter a valid 2 or 4 digit Year.",
    }),
  cvc: Joi.string()
    .replace(/\D/g, "")
    .length(3)
    .when("$edit", {
      is: Joi.boolean().valid(true).required(),
      then: Joi.string(),
      otherwise: Joi.string().required(),
    })
    .messages({
      "string.base": `CVC should be a text string.`,
      "string.empty": `CVC cannot be an empty field.`,
      "boolean.required": "$edit flag must be set in validation.",
      "any.required": `CVC is a required field.`,
      "string.length": "Please enter a valid 3-digit CVC.",
    }),
});

export default CreditCardSchema;
