import {
  Select
} from "antd";
import axios from "axios";
import React from "react";

const getSpecies = async () => {
   const response = await axios.get(`${process.env.REACT_APP_LIBRARY_ENDPOINT}/api/v1/species?per_page=9999&sort=name`);
   return (response.data.data.species || []).map(({id, name}) => ({
    text: name,
    value: id
   }))
}

export const SpeciesDropdown = ({form, name="install_preference.operation_type"}) => {

  const [options, setOptions] = React.useState([]);

  React.useEffect( () => {
    getSpecies().then(setOptions).then(console.log);
  }, []);

  return (<>
    <Select name={name} onChange={(val, e) => {
      form.setFieldValue(name, val)
    }}>
      {options.map(({text, value}) => <Select.Option key={value}>
        {text}
      </Select.Option>)}
    </Select>
  </>)
};