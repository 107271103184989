import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import history from "../../utils/history";
import { loadUser } from "../../stores/users";
import { logout } from "../../stores/auth";

// Components
import Drawer from "../SideBarDrawer";
import LogoutButton from "../LogoutButton"

import {
  Layout,
  Menu,
  Avatar,
  Popover
} from 'antd';

import "./my-header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { Header } = Layout;

const MyHeader = ({ logout, profile, loadUser, collapsed, toggle }) => {
  const [visiblePopover, setVisiblePopover] = useState(false);

  useEffect(() => {
    if (profile === null) loadUser();
    //eslint-disable-next-line
  }, [profile]);

  const handlePopoverId = (selector) => {
    setTimeout(() => {
      const p = document.getElementById(selector);
      if (p) {
        p.classList.add("header-popover")
        setVisiblePopover(true)
      }
    }, 0);
  }

  const handleGoToAccount = () => {
    history.push(`/users/${profile.id}/profile`)
    setVisiblePopover(false)
  }

  const handleGoToBillingEntities = () => {
    history.push(`/billing-entities`);
    setVisiblePopover(false);
  }

  const onLogout = React.useCallback((e) => {
    e.preventDefault();
    logout()
  }, [logout])

  return (
    <Header id="MyHeader" className='main-header'>
      <div className="p-relative">
        {collapsed &&
          <i className="icon-menu icon drawer-icon" onClick={() => toggle()}></i>
        }

        <Drawer history={history} />

        <Menu mode="horizontal">
          <Menu.Item key="firstName" className="person-detail">
            <div className="person-detail">
              <span className="user-text">
                {profile?.first_name ? profile?.first_name : profile?.email ? profile?.email : '...'}
              </span>
            </div>
          </Menu.Item>
          <Menu.Item key="profile" className="person-title-holder" >
            <Popover
              trigger="click"
              placement="bottomRight"
              onVisibleChange={(visible) => setVisiblePopover(visible)}
              content={<>
                <div className="popover-content setting-popover">
                  <div className="cover">
                    <span className="cover-title font-bold">
                      {profile?.first_name && profile?.last_name ? `${profile?.first_name} ${profile?.last_name}` : profile?.email ? profile?.email : '...'}
                    </span>
                  </div>
                  <ul className="popover-content-list scroller">
                    <li onClick={handleGoToAccount}>
                      <div className="popover-content-info">
                        <span className="font-bold title mr-b-0"><i className="icon icon-person_outline_outlined primary"></i> Account</span>
                      </div>
                      <i className="icon icon-keyboard_arrow_right_outlined mr-t-5"></i>
                    </li>
                    <li onClick={handleGoToBillingEntities}>
                      <div className="popover-content-info">
                        <span className="font-bold title mr-b-0"><FontAwesomeIcon className="!text-primary" icon="file-invoice-dollar" /> &nbsp; Billing Info</span>
                      </div>
                      <i className="icon icon-keyboard_arrow_right_outlined mr-t-5"></i>
                    </li>
                    <li onClick={onLogout}>
                      <div className="popover-content-info">
                        <LogoutButton />
                      </div>
                      <i className="icon icon-keyboard_arrow_right_outlined mr-t-5"></i>
                    </li>
                  </ul>
                </div>
              </>}
              id="UserPopover"
              visible={visiblePopover}
              onClick={() => handlePopoverId("UserPopover")}
            >
              {(profile && profile?.first_name) || (profile && profile.email) ? (
                <Avatar className="person-title">
                  {profile?.first_name ? profile?.first_name.slice(0, 1).toUpperCase() : profile?.email ? profile?.email.slice(0, 1).toUpperCase() : '?'}
                </Avatar>
              ) : (
                <Avatar className="person-title">
                  {profile?.first_name ? profile?.first_name.slice(0, 1).toUpperCase() : profile?.email ? profile?.email.slice(0, 1).toUpperCase() : '?'}
                </Avatar>
              )}
            </Popover>
          </Menu.Item>
        </Menu>
      </div>
    </Header >
  );
};


const mapStateToProps = (state) => ({
  profile: state.users.profile,
});

const mapDispatchToProps = {
  loadUser,
  logout,

};

export default connect(mapStateToProps, mapDispatchToProps)(MyHeader);
