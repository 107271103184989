import React, { useState, useEffect } from "react";
import history from "../../utils/history";
import { connect } from "react-redux";
import { getSites, getSiteInvites } from "../../stores/sites";
import { getClinics, getClinicInvites } from "../../stores/clinics";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isClinic, isSite } from '../../config/app'

import "./aside.css";
import { Badge, Menu } from "antd";

const { SubMenu } = Menu;

const Sidebar = ({
  sites,
  clinics,
  getSites,
  getClinics,
  toggle = () => { },
  collapsed = false,
  siteInvites,
  clinicInvites,
  getClinicInvites,
  getSiteInvites,
  profile

}) => {
  const [selectedKey, setSelectedKey] = useState('/');
  const [openKeys, setOpenKeys] = useState('');
  const [receivedClinicInvites, setReceivedClinicInvites] = useState([]);
  const [filteredSiteInvites, setFilteredSiteInvites] = useState([]);
  // console.log('xxxxxx', receivedClinicInvites, filteredSiteInvites)

  const showSite = React.useMemo(() => isSite())
  const showClinic = React.useMemo(() => isClinic())
  const inviteCount = React.useMemo(() => {
    if (isSite()) {
      return (siteInvites || []).filter(({ status }) => status === 'sent').length
    }

    return (filteredSiteInvites || []).filter(({ status }) => status === 'sent').length
  });

  useEffect(() => {
    getSites();
    getClinics();
    getSiteInvites();
    getClinicInvites();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (clinicInvites && profile) {
      setReceivedClinicInvites(clinicInvites.filter((invite) => {
        return invite.invited_by !== profile.id;
      }));
    }
  }, [clinicInvites, profile]);
  useEffect(() => {
    if (siteInvites && profile) {
      setFilteredSiteInvites(siteInvites.filter((invite) => {
        return invite.invited_by !== profile.id;
      }));
    }
  }, [siteInvites, profile]);

  useEffect(() => {
    const { location } = history;

    let uri = location.pathname.split('/').filter(item => item !== 'new' || item !== 'edit');
    const uuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    if (uri?.length === 0) setSelectedKey('/');
    else {
      for (let i = uri.length - 1; i >= 0; i--) {
        if (uri[i].match(uuid)?.length) {
          if (uri[i - 1] !== 'users') {
            setSelectedKey(uri[i]);
            setOpenKeys(uri[i - 1]);
            break;
          } else {
            setSelectedKey('/');
            setOpenKeys('');
            break;
          }
        } else if (uri[i] === 'billing-entities' || uri[i] === 'sites') {
          setSelectedKey(uri[i]);
          setOpenKeys(uri[i]);
          break;
        } else if (uri[i] === 'profile') {
          setSelectedKey('/');
          setOpenKeys('');
          break;
        } else if (uri[i] === 'card') {
          setSelectedKey(uri[i - 1]);
          setOpenKeys(uri[i - 2]);
        }
      }
    }
  }, [sites]);

  useEffect(() => {
    const { location } = history;

    let path = location.pathname.split('/')
    if (path.length <= 3) {
      setOpenKeys(path[1])
      setSelectedKey(path[2])
    }

  }, []);

  useEffect(() => {
    if (collapsed) {
      setOpenKeys('')
      setSelectedKey('/')
    }
  }, [collapsed]);

  const handleOpenSubMenu = (e) => {
    if (openKeys === e.key) {
      setOpenKeys('')
    } else {
      setOpenKeys(e.key)
    }
  }

  const handleClick = e => {
    setSelectedKey(e.key)
    if (collapsed) {
      setOpenKeys('')
      if (e.key === selectedKey) {
        setOpenKeys('')
      }
    }
    if (e.keyPath.length === 1) {
      setOpenKeys([])
    }
    toggle()
  };

  return (
    <div id="SideBarInnerMenu" className="side-bar-inner-menu scroller">
      <Menu mode="inline"
        className='menu-w-round-circle'
        defaultSelectedKeys={[selectedKey]}
        defaultOpenKeys={[openKeys]}
        selectedKeys={[selectedKey]}
        openKeys={[openKeys]}
        onClick={handleClick}
      >
        <Menu.Item
          key="/"
          onClick={() => history.push("/")}
        >
          <MenuItemTitle
            icon={'icon-list_alt'}
            title={'Dashboard'}
          />
        </Menu.Item>



        {showClinic ? <>
          {/* CHUTESIDE SITES IN SIDE BAR */}
          {clinics ?
            <SubMenu
              key="clinics"
              title={<span><MenuItemTitle icon={<FontAwesomeIcon icon="fa-solid fa-clinic-medical" className="icon" />} title={'HerdRX Clinics'} /></span>}
              onTitleClick={handleOpenSubMenu}
              className="clinic-menu"
            >
              <Menu.Item
                key="new_clinic"
                onClick={() => history.push("/clinic/new")}

              >
                <MenuItemTitle icon={'icon-add_outlined'} title={'New Clinic'} />
              </Menu.Item>
              {Object.values(clinics).map((clinic) => {
                return (
                  <Menu.Item key={clinic.id} onClick={() => history.push(`/clinic/${clinic.id}`)}>
                    <MenuItemTitle title={clinic.name} icon={<FontAwesomeIcon icon="fa-solid fa-clinic-medical" className="icon" />} />
                  </Menu.Item>
                )
              })
              }
            </SubMenu> :
            <SubMenu
              key="clinic"
              title={<MenuItemTitle icon={<FontAwesomeIcon icon="fa-solid fa-clinic-medical" className="icon" />} title={'HerdRx Clinics'} />}
              onTitleClick={handleOpenSubMenu}
            >
            </SubMenu>
          }
        </> : <></>}




        {showSite ? <>
          {sites ?
            <SubMenu
              key="sites"
              title={<MenuItemTitle icon={'icon-compass_calibration'} title={'Chuteside Sites'} />}
              onTitleClick={handleOpenSubMenu}
              className="site-menu"
            >
              <Menu.Item
                key="new_site"
                onClick={() => history.push("/site/new")}
              >
                <MenuItemTitle icon={'icon-add_outlined'} title={'New Site'} />
              </Menu.Item>

              {Object.values(sites).map((site) => {
                return (
                  <Menu.Item key={site.id} onClick={() => history.push(`/site/${site.id}`)}>
                    <MenuItemTitle title={site.name} icon={'icon-compass_calibration'} />
                  </Menu.Item>
                )
              })
              }
            </SubMenu> :
            <SubMenu
              key="site"
              title={<MenuItemTitle icon={'icon-compass_calibration'} title={'Chuteside Sites'} />}
              onTitleClick={handleOpenSubMenu}
            >
            </SubMenu>
          }
        </> : <></>}


        {/* HERDRX CLINICS IN SIDE BAR */}

        <Menu.Item
          key="/invites"
          onClick={() => history.push("/invites")}
        >
          {/* <MenuItemTitle
                        icon={<FontAwesomeIcon icon="fa-solid fa-inbox" className="icon" />}
                        title={'Invites <br />'}
                    /> */}

          <FontAwesomeIcon icon="fa-solid fa-inbox" className="icon" /><span>Invites &nbsp;
            <Badge count={inviteCount} style={{ backgroundColor: '#DBA44F' }} />
          </span>
        </Menu.Item>

        {(clinicInvites ? clinicInvites.length : 2) + (siteInvites ? siteInvites.length : 1)}
      </Menu>
    </div>
  );
};

const MenuItemTitle = ({ icon, title }) => {
  return <>
    {typeof icon == 'string' ? <i className={`icon ${icon}`}><span className="path1"></span><span className="path2"></span></i> : icon}
    <span dangerouslySetInnerHTML={{ __html: title }}></span>
  </>
};

const mapStateToProps = (state) => ({
  clinics: state.clinics.clinics,
  profile: state.users.profile,
  sites: state.sites.sites,
  siteInvites: state.sites.siteInvites,
  clinicInvites: state.clinics.clinicInvites
});

const mapDispatchToProps = {
  getSites,
  getClinics,
  getSiteInvites,
  getClinicInvites
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
